import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    lectureTimetable: [],
    examTimetable: [],
    mondayTimetable: [],
    tuesdayTimetable: [],
    wednesdayTimetable: [],
    thursdayTimetable: [],
    fridayTimetable: [],
    nextActivities: [],
};

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_LECTURE_TIMETABLE: (state, action) => {
            state.lectureTimetable = action.payload;
        },
        GET_EXAM_TIMETABLE: (state, action) => {
            state.examTimetable = action.payload;
        },
        SAVE_MONDAY_TIMETABLE: (state, action) => {
            state.mondayTimetable = action.payload;
        },
        SAVE_TUESDAY_TIMETABLE: (state, action) => {
            state.tuesdayTimetable = action.payload;
        },
        SAVE_WEDNESDAY_TIMETABLE: (state, action) => {
            state.wednesdayTimetable = action.payload;
        },
        SAVE_THURSDAY_TIMETABLE: (state, action) => {
            state.thursdayTimetable = action.payload;
        },
        SAVE_FRIDAY_TIMETABLE: (state, action) => {
            state.fridayTimetable = action.payload;
        },
        SAVE_NEXT_ACTIVITY: (state, action) => {
            state.nextActivities = action.payload;
        },
    },
});

export const {
    GET_EXAM_TIMETABLE,
    GET_LECTURE_TIMETABLE,
    LOADING,
    STOP_LOADING,
    SAVE_FRIDAY_TIMETABLE,
    SAVE_THURSDAY_TIMETABLE,
    SAVE_WEDNESDAY_TIMETABLE,
    SAVE_TUESDAY_TIMETABLE,
    SAVE_MONDAY_TIMETABLE,
    SAVE_NEXT_ACTIVITY,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
