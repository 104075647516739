import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg';
import { ActionsPopup, ExportSheetDialogue, ChooseColumnsToExport } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow } from '../../../containers/ScreenContainers';

import BasicInfo from './tabs/BasicInfo';
import Personal from './tabs/Personal';
import Results from './tabs/Results';
import Uploads from './tabs/Uploads';

const RelativeContainer = tw.div`
  relative
  mr-12
`;

const StudentDetails = () => {
    const navigate = useNavigate();
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Introduction to Engineering Drawing
                    </PageTitle>
                    <Text size="1.6rem" left="1.4rem">
                        / Olunkunle Emmanuel
                    </Text>
                </FlexCentredRow>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Actions
                        <Dropdown className="ml-[13.15px]" />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        items={[
                            {
                                icon: <EditIcon />,
                                name: 'Edit',
                                // click: ,
                            },

                            {
                                icon: <ExportIcon />,
                                name: 'Export Sheet',
                                click: () => {
                                    setShowExportSheetDialogue(true);
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>Basic Info</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Personal</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Results</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Uploads</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <BasicInfo />
                    </CTabPane>
                    <CTabPane>
                        <Personal />
                    </CTabPane>
                    <CTabPane>
                        <Results />
                    </CTabPane>
                    <CTabPane>
                        <Uploads />
                    </CTabPane>
                </CTabContent>
            </CTabs>
            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
        </div>
    );
};

export default StudentDetails;
