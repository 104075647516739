import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    session: Yup.string().nullable().required('Required'),
    attendanceScore: Yup.string().nullable().required('Required'),
    assignmentScore: Yup.string().nullable().required('Required'),
    testScore: Yup.string().nullable().required('Required'),
    examScore: Yup.string().nullable().required('Required'),
    assignmentScoreUsedAsTest: Yup.string().nullable().required('Required'),
});
