import React, { useEffect, useMemo, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/circular-plus-black.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as PromoteIcon } from '../../../../assets/icons/promote.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/send-green.svg';
import { ReactComponent as VersionIcon } from '../../../../assets/icons/version.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
// import { AllFilter } from '../../../../components/filter-select';
import { GoBack } from '../../../../components/go-back';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import CreateVersion from '../../../../components/popups/create-version';
import PromoteVersion from '../../../../components/popups/promote-version';
import SendToCAPopup from '../../../../components/popups/send-to-ca';
import { Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { createNewExamVersion, getExamScoresheet, getExamVersions, promoteExamVersion, sendExamToBroadsheet } from '../../../../redux/exams/actions';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const overviewItems = [
    {
        title: 'Version',
        key: 'version',
    },
    {
        title: 'Description',
        key: '',
    },
    {
        title: 'Date Created',
        key: 'versionDate',
    },
];

const ExamScoresheetDetails = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { session, course } = state || {};
    const { courseCode, courseTitle, _id: courseId } = course || {};

    const { isLoading } = useSelector((store) => store.exams);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [scoreSheet, setSpreadSheet] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [refetchVersions, setRefetchVersions] = useState(false);
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState({ version: 0 });
    const [versionToPromote, setVersionToPromote] = useState(null);
    const [openModal, setOpenModal] = useState('');
    const totalPages = Math.ceil(scoreSheet?.length / itemsPerPage);

    const examData = scoreSheet?.[0]?.scoreData;

    const transformedScoresheet = useMemo(() => {
        const newData = [...scoreSheet];

        newData.forEach((item) => {
            item.scoreData.forEach((scoreEntry) => {
                item[scoreEntry.examId] = scoreEntry.score;
            });
        });

        return newData;
    }, [scoreSheet]);

    const fields = [
        {
            label: <Checkbox />,
            key: 'action',
        },
        {
            label: 'Full Name',
            key: 'studentName',
        },
        {
            label: 'Matric Number',
            key: 'matricNumber',
        },
        ...(examData?.length > 0
            ? examData.map((item) => ({
                  label: `${item.title} | (${item.totalMarks} Marks)`,
                  key: item.examId,
              }))
            : []),

        {
            label: 'Total',
            key: 'totalScore',
        },
    ];

    const formatDate = (date = '') => {
        if (!date) return '';
        return date?.split('T')[0].split('-').reverse().join('-');
    };

    const versionActionItems = useMemo(
        () =>
            versions.map((item) => ({
                name: `Version ${item.version}`,
                click: () => {
                    setSelectedVersion(item);
                    setOpenModal(null);
                    setDropdownOpen(false);
                },
            })),
        [versions],
    );

    const handleVersionCreation = async () => {
        const res = await dispatch(createNewExamVersion(courseId, session, selectedVersion?.version));
        if (res) {
            setRefetchVersions((prev) => !prev);
            setOpenModal('');
        }
    };

    const handleVersionPromotion = async () => {
        const payload = { targetVersion: versionToPromote.version };
        const latestVersion = versions[versions.length - 1].version;

        const res = await dispatch(promoteExamVersion(courseId, session, latestVersion, payload));
        if (res) {
            setRefetchVersions((prev) => !prev);
            setOpenModal('');
        }
    };

    const handleSendToCA = async () => {
        const res = await dispatch(sendExamToBroadsheet(courseId, session, selectedVersion?.version));
        if (res) {
            setOpenModal('');
        }
    };

    useEffect(() => {
        if (!session) return;
        const fetchSpreadsheet = async () => {
            const res = await dispatch(getExamScoresheet(session, courseId, selectedVersion?.version));
            setSpreadSheet(res || []);
        };

        fetchSpreadsheet();
    }, [courseId, dispatch, session, selectedVersion?.version]);

    useEffect(() => {
        if (!session) return;
        const fetchVersions = async () => {
            const versionData = await dispatch(getExamVersions(session, courseId));
            setVersions(versionData || []);
        };

        fetchVersions();
    }, [courseId, dispatch, session, refetchVersions]);

    useEffect(() => {
        if (!versions?.length) return;
        const latestVersion = versions[versions.length - 1];
        setSelectedVersion(latestVersion);
    }, [versions]);

    return (
        <>
            <FlexRowSpaceBetween className="gap-[1rem">
                <GoBack title="Exam Scoresheet" subTitle={`/ ${courseCode} (${courseTitle})`} />
                <FlexDiv className="gap-4">
                    <Button onClick={() => setOpenModal('send-to-ca')} disabled={selectedVersion?.isSentToBroadsheet}>
                        <SendIcon className="mr-[1.15rem]" />
                        Send to CA Broadsheet
                    </Button>
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            Actions
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <PlusIcon />,
                                    name: 'Create Version',
                                    // disabled: !selectedItem,
                                    click: () => {
                                        setOpenModal('create-version');

                                        setDropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <VersionIcon />,
                                    name: 'Version History',
                                    click: () => {
                                        setOpenModal('versions');
                                    },
                                },
                                {
                                    icon: <PromoteIcon />,
                                    name: 'Promote Version to Latest',
                                    click: () => {
                                        setOpenModal('promote-version');
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                        <div className="absolute right-[300px] top-[20px]">
                            <ActionsPopup
                                open={openModal === 'versions'}
                                // open={true}
                                close={() => setOpenModal('')}
                                items={versionActionItems}
                            />
                        </div>
                    </RelativeContainer>
                </FlexDiv>
            </FlexRowSpaceBetween>

            <div className="bg-white w-full p-[24px] mt-12 rounded-lg">
                <Text weight="600" size="1.9rem" align="left">
                    Overview
                </Text>

                <div className="flex justify-between mt-8">
                    {overviewItems.map((item) => (
                        <FlexColumn className="gap-1">
                            <Text weight="500" align="left" size="1.2rem" color="#9CA3AF">
                                {item.title}
                            </Text>
                            <Text weight="500" align="left" size="1.4rem" color="#4B5563">
                                {item.key === 'versionDate'
                                    ? formatDate(selectedVersion?.versionDate)
                                    : selectedVersion[item.key]}
                            </Text>
                        </FlexColumn>
                    ))}
                </div>
            </div>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>

                    {/* <RelativeContainer> */}
                    {/* <AllFilter
                        // showClear={currentFilterCategory}
                        // items={FILTER_OPTIONS.map((item) => ({
                        //     name: item,
                        //     click: () => {
                        //         handleSelectCategories(item);
                        //         setCurrentFilterCategory(item);
                        //         setOpenModal('options-popup-main');
                        //     },
                        // }))}
                        // handleReset={handleReset}
                        ///> */}
                    {/* <FilterPopup
                            open={openModal === 'options-popup-main'}
                            isLoading={facultyLoading || collegeLoading}
                            close={() => setOpenModal(null)}
                            items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                        /> */}
                    {/* </RelativeContainer> */}
                </FilterContainer>

                <CDataTable
                    items={transformedScoresheet || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        studentName: (item) => <td style={{ color: '#2563EB' }}>{item.studentName}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <SendToCAPopup
                type="Exam"
                show={openModal === 'send-to-ca'}
                confirmAction={() => handleSendToCA()}
                isLoading={isLoading}
                close={() => setOpenModal('')}
                version={selectedVersion?.version}
            />

            <PromoteVersion
                show={openModal === 'promote-version'}
                close={() => setOpenModal('')}
                confirmAction={() => handleVersionPromotion()}
                isLoading={isLoading}
                versions={versions}
                setValue={setVersionToPromote}
            />

            <CreateVersion
                show={openModal === 'create-version'}
                close={() => setOpenModal('')}
                confirmAction={() => handleVersionCreation()}
                isLoading={isLoading}
            />
        </>
    );
};

export default ExamScoresheetDetails;
