import React, { useEffect, useMemo, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup, UploadFile } from '../../../../components';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { getRegisteredStudents } from '../../../../redux/alloted-courses/actions';
import { getDegreeInfo } from '../../../../utils';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const RegisteredStudents = () => {
    const dispatch = useDispatch();
    const {
        state: { course, session: stateSession },
    } = useLocation();
    const { _id: courseId } = course || {};

    const { students, isLoading, allottedSessions } = useSelector((store) => store.allotedCourses);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [session, setSession] = useState(stateSession || allottedSessions[0]?.session || '');
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(students?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = allottedSessions.map((item) => ({
        name: item.session,
        click: () => {
            setSession(item.session);
            setOpenDropdown(null);
        },
    }));

    const modifiedStudents = useMemo(() => {
        return students?.map((stud) => {
            const studStructures = getDegreeInfo(stud);
            const { firstDegree, secondDegree, thirdDegree } = studStructures || {};

            return {
                name: `${stud?.lastName} ${stud?.firstName} ${stud?.middleName}`,
                firstDegree,
                secondDegree,
                thirdDegree,
                ...stud,
            };
        });
    }, [students]);

    const fields = [
        {
            label: <Checkbox />,
            key: 'action',
        },
        {
            label: 'Name',
            key: 'name',
        },
        {
            label: 'Matric No.',
            key: 'matricNumber',
        },
        {
            label: firstDegreeOptionsStr,
            key: 'firstDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: secondDegreeOptionsStr,
            key: 'secondDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: 'Level',
            key: 'studentLevel',
        },
    ];

    useEffect(() => {
        if (!courseId) return;
        dispatch(getRegisteredStudents(session, courseId));
    }, [dispatch, courseId, session]);

    return (
        <>
            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow className="gap-4">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'sessions'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSessions}
                            />
                        </RelativeContainer>

                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>

                <CDataTable
                    items={modifiedStudents || []}
                    fields={fields}
                    // // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                    }}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Student(s) yet"></ListEmptyContent>
                        </CenteredContainer>
                    }
                />

                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default RegisteredStudents;
