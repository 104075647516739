import React from 'react';

import { useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { Button, LoadingButton } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexDiv } from '../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../utils';

const MainContainer = tw.div`
    grid
    mt-8
    mb-[6.4rem]
`;
const SubContainer = tw.div`
flex
flex-col
px-[2rem]
py-[2rem]
gap-[1rem]
rounded-b-lg
bg-white
`;
const Details = tw.div`
grid
grid-cols-2
gap-[1rem]
bg-white
`;
const Header = tw.div`
lg:p-[2.4rem]
md:p-[5.5rem]
p-[2rem]
bg-white
flex
items-center
my-[auto]
rounded-t-lg
border-[#E5E7EB]
border-b
h-[7.6rem]
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
`;

const ExperiencePreview = ({ item, onBtnClick }) => {
    const { isLoading } = useSelector((store) => store.staff);

    return (
        <div>
            <MainContainer>
                <Header className="">
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        About
                    </PageTitle>
                </Header>
                <SubContainer>
                    <FlexCol>
                        <GreyText>Background Profile</GreyText>
                        <DarkText>{item?.aboutInfo?.backgroundProfile}</DarkText>
                    </FlexCol>
                    <FlexCol className="mt-[2rem]">
                        <GreyText>Research Interests</GreyText>
                        <DarkText>{item?.aboutInfo?.researchInterest}</DarkText>
                    </FlexCol>
                </SubContainer>
                <Header className="">
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Work Experience
                    </PageTitle>
                </Header>
                {item?.WorkExperienceInfo?.map((exp, index) => (
                    <div className="p-[2rem] bg-white" key={index}>
                        <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" bottom="2rem">
                            Experience {index + 1}
                        </Text>
                        <Details>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>Starting Year</GreyText>
                                    <DarkText>
                                        {exp?.startingYear ? new Date(exp?.startingYear).getFullYear() : ''}
                                    </DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Role</GreyText>
                                    <DarkText>{exp?.role}</DarkText>
                                </FlexCol>
                            </RowContainer>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>End Year</GreyText>
                                    <DarkText>{exp?.endYear ? new Date(exp?.endYear).getFullYear() : ''}</DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Organization</GreyText>
                                    <DarkText>{exp?.organization}</DarkText>
                                </FlexCol>
                            </RowContainer>
                        </Details>
                        <FlexCol>
                            <GreyText>Description</GreyText>
                            <DarkText>{exp?.description}</DarkText>
                        </FlexCol>
                    </div>
                ))}

                <Header className="">
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Education History
                    </PageTitle>
                </Header>
                {item?.secondarySchoolInfo?.map((secondary, _id) => (
                    <div className="p-[2rem] bg-white" key={_id}>
                        <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" bottom="2rem">
                            Secondary School
                        </Text>

                        <Details>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>Starting Year</GreyText>
                                    <DarkText>
                                        {secondary?.startingYear ? new Date(secondary?.startingYear).getFullYear() : ''}
                                    </DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Certificate</GreyText>
                                    <DarkText>{secondary?.certificate}</DarkText>
                                </FlexCol>
                            </RowContainer>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>End Year</GreyText>
                                    <DarkText>
                                        {secondary?.endYear ? new Date(secondary?.endYear).getFullYear() : ''}
                                    </DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Institution</GreyText>
                                    <DarkText>{capitalizeFirstLetter(secondary?.institutionName)}</DarkText>
                                </FlexCol>
                            </RowContainer>
                        </Details>
                    </div>
                ))}
                {item?.tertiarySchoolInfo?.map((tertiary, _id) => (
                    <div className="p-[2rem] bg-white" key={_id}>
                        <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" bottom="2rem">
                            Tertiary Education
                        </Text>

                        <Details>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>Starting Year</GreyText>
                                    <DarkText>
                                        {tertiary?.startingYear ? new Date(tertiary?.startingYear).getFullYear() : ''}
                                    </DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Certificate</GreyText>
                                    <DarkText>{tertiary?.certificate}</DarkText>
                                </FlexCol>
                            </RowContainer>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>End Year</GreyText>
                                    <DarkText>
                                        {tertiary?.endYear ? new Date(tertiary?.endYear).getFullYear() : ''}
                                    </DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Institution</GreyText>
                                    <DarkText>{capitalizeFirstLetter(tertiary?.institutionName)}</DarkText>
                                </FlexCol>
                            </RowContainer>
                        </Details>
                    </div>
                ))}
                {item?.postGraduateInfo?.map((post, _id) => (
                    <div className="p-[2rem] bg-white" key={_id}>
                        <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" bottom="2rem">
                            Post Graduate
                        </Text>
                        <Details>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>Starting Year</GreyText>
                                    <DarkText>
                                        {post?.startingYear ? new Date(post?.startingYear).getFullYear() : ''}
                                    </DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Certificate</GreyText>
                                    <DarkText>{post?.certificate}</DarkText>
                                </FlexCol>
                            </RowContainer>
                            <RowContainer>
                                <FlexCol>
                                    <GreyText>End Year</GreyText>
                                    <DarkText>{post?.endYear ? new Date(post?.endYear).getFullYear() : ''}</DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Institution</GreyText>
                                    <DarkText>{capitalizeFirstLetter(post?.institutionName)}</DarkText>
                                </FlexCol>
                            </RowContainer>
                        </Details>
                    </div>
                ))}
                {item?.awardsInfo?.map((awards, index) => (
                    <div key={index} className="p-[2rem] bg-white">
                        <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" bottom="2rem">
                            Award {index + 1}
                        </Text>
                        <Details>
                            <FlexCol>
                                <GreyText> Year</GreyText>
                                <DarkText>{awards?.date ? new Date(awards?.date).getFullYear() : ''}</DarkText>
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Award</GreyText>
                                <DarkText>{awards?.name}</DarkText>
                            </FlexCol>
                        </Details>
                    </div>
                ))}
            </MainContainer>
            <div className="flow-root my-[3rem]">
                <FlexDiv className="float-right gap-[1.45rem]">
                    <Button type="button" onClick={onBtnClick}>
                        Back
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        Submit
                    </LoadingButton>
                </FlexDiv>
            </div>
        </div>
    );
};

export default ExperiencePreview;
