import React from 'react';

// import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external-Link.svg';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { handlePdfPreview } from '../../../utils';

const RowContainer = tw.div`
    mt-8
    grid
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const TitleText = styled(Text)`
    color: #9ca3af;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
    font-weight: 500;
`;

const SubText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
    font-weight: 500;
    line-height: 2.4rem;
`;
const SubText1 = styled(Text)`
    color: #6366f1;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
    font-weight: 500;
    line-height: 2.4rem;
`;

const Publication = ({ pub }) => {
    const navigate = useNavigate();

    return (
        <div>
            <Details className="mt-[3rem] mb-[1.5rem]">
                <FlexRowSpaceBetween>
                    <PageTitle size="1.9rem" lineheight="2.8rem">
                        Publication
                    </PageTitle>
                    <Button
                        border="1px solid #E5E7EB"
                        bgColor="#FFFFFF"
                        color="#6366F1"
                        onClick={() => navigate('/dashboard/bio-data', { state: { tab: 2 } })}
                    >
                        <Edit className="mr-[13.15px]" />
                        Edit
                    </Button>
                </FlexRowSpaceBetween>
            </Details>
            <Details>
                {pub?.map((publication, _id) => (
                    <RowContainer key={_id} className="lg:text-left text-center">
                        <FlexCol>
                            <TitleText>Publisher</TitleText>
                            <FlexCentredRow className="gap-[1rem]">
                                <SubText>{publication?.publisher}</SubText>
                                {publication?.publicationLink && (
                                    <a href={publication?.publicationLink} target="_blank" rel="noopener noreferrer">
                                        <SubText1>{`(${publication?.publicationLink})`}</SubText1>
                                    </a>
                                )}
                            </FlexCentredRow>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Type</TitleText>
                            <SubText>{publication?.publicationType}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Title</TitleText>
                            <SubText>{publication?.publicationTitle}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Authors</TitleText>
                            <SubText>{publication?.author}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Year Published</TitleText>
                            <SubText>{publication?.year?.slice(0, 4)}</SubText>
                        </FlexCol>
                        {publication?.publication && (
                            <div className="flex justify-start">
                                <Button
                                    border="1px solid #e5e7eb"
                                    onClick={() => handlePdfPreview(publication?.publication?.url)}
                                >
                                    View Publication
                                    <ExternalLink />
                                </Button>
                            </div>
                        )}

                        <hr />
                    </RowContainer>
                ))}
            </Details>
        </div>
    );
};

export default Publication;
