export const personnelData = [
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
    {
        name: 'Ned Stark',
        department: 'MEE',
        yearOfEmployment: '21 Sept 2005',
        roles: 'Dean',
        emailAddress: 'Nebuchad2000@gmail.com',
    },
];
