import React, { useState, useEffect, useMemo } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-blue.svg';
import { AllFilter } from '../../components/filter-select';
import FilterPopup from '../../components/filter-select/filter-popup';
import Checkbox from '../../components/inputs/checkbox';
import SearchInput from '../../components/inputs/search-input';
import ItemsPerPage from '../../components/items-per-page';
import { PageTitle } from '../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../containers/ScreenContainers';
import { LEVELS } from '../../data/constants';
import useDebounce from '../../hooks/useDebounce';
import { getStudents } from '../../redux/students/actions';
import { capitalizeFirstLetter } from '../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'name',
    },
    {
        label: 'Matric No.',
        key: 'matricNumber',
    },
    {
        label: 'Level',
        key: 'level',
    },
];
const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const Students = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { students, isLoading } = useSelector((state) => state.students);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [studentLevelFilter, setStudentLevelFilter] = useState('');
    const [openModal, setOpenModal] = useState(null);

    const activeStudents = useMemo(
        () =>
            students
                ?.filter((item) => item.academicStatus === 'Active')
                ?.map((item) => ({
                    ...item,
                    name: `${capitalizeFirstLetter(item?.lastName)} ${capitalizeFirstLetter(
                        item?.firstName,
                    )} ${capitalizeFirstLetter(item?.middleName)}`,
                })),
        [students],
    );

    const totalPages = Math.ceil(activeStudents?.length / itemsPerPage);

    const generateFilterOptions = (data = [], setStateAction, closeAction, objaccessorKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objaccessorKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    closeAction(null);
                },
            };
        });

        return filterOptions;
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setSearchValue('');
        setStudentLevelFilter('');
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    const FILTER_OPTIONS = ['Level'];

    const FILTER_POPUP_OPTIONS = {
        Level: generateFilterOptions(LEVELS, setStudentLevelFilter, setOpenModal),
    };

    const FILTER_VALUES_LOOKUP = {
        Level: {
            value: studentLevelFilter,
            stateAction: setStudentLevelFilter,
        },
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        if (studentLevelFilter) {
            queryParams.level = studentLevelFilter;
        }

        return queryParams;
    }, [debouncedSearchValue, studentLevelFilter]);

    useEffect(() => {
        dispatch(getStudents(query));
    }, [dispatch, query]);

    return (
        <div>
            <FlexCentredRow>
                <PageTitle>Students</PageTitle>
            </FlexCentredRow>

            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />

                    <RelativeContainer>
                        <AllFilter
                            showClear={currentFilterCategory}
                            items={FILTER_OPTIONS.map((item) => ({
                                name: item,
                                click: () => {
                                    handleSelectCategories(item);
                                    setCurrentFilterCategory(item);
                                    setOpenModal('options-popup-main');
                                },
                            }))}
                            handleReset={handleReset}
                        />
                        <FilterPopup
                            open={openModal === 'options-popup-main'}
                            close={() => setOpenModal(null)}
                            items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                        />
                    </RelativeContainer>
                </FilterContainer>
                <div>
                    {filterCategories?.length > 0 && (
                        <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                            {filterCategories.map((category) => (
                                <div key={category} className="flex gap-[1px]">
                                    <StyledTag withLeftRadius>{category}</StyledTag>

                                    {FILTER_VALUES_LOOKUP[category].value && (
                                        <StyledTag withRightRadius>
                                            {FILTER_VALUES_LOOKUP[category].value}
                                            <CloseIcon
                                                className="ml-[4px] hover:cursor-pointer"
                                                onClick={() =>
                                                    handleRemoveFilterCategory(
                                                        category,
                                                        FILTER_VALUES_LOOKUP[category].stateAction,
                                                    )
                                                }
                                            />
                                        </StyledTag>
                                    )}
                                </div>
                            ))}
                            <RelativeContainer>
                                <AllFilter
                                    useSecondaryBtn
                                    absolutePosition="left-[0px]"
                                    showClear={currentFilterCategory}
                                    items={FILTER_OPTIONS.map((item) => ({
                                        name: item,
                                        click: () => {
                                            handleSelectCategories(item);
                                            setCurrentFilterCategory(item);
                                            setOpenModal('options-popup-sub');
                                        },
                                    }))}
                                    handleReset={handleReset}
                                />
                                <FilterPopup
                                    absolutePosition="left-[0px]"
                                    open={openModal === 'options-popup-sub'}
                                    close={() => setOpenModal(null)}
                                    items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                />
                            </RelativeContainer>
                        </div>
                    )}
                </div>
                <CDataTable
                    items={activeStudents || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    // onRowClick={(item) => navigate(`/student/${item._id}`)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => (
                            <td
                                style={{ color: '#2563EB' }}
                                onClick={() => navigate(`/student/${item._id}`, { state: { student: item } })}
                            >
                                {item.name}
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />
                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </div>
    );
};
export default Students;
