import React, { useState } from 'react';

import { Navigate } from 'react-router-dom';

import { parseJSON } from '../utils/useJSON';

import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = () => {
    const [open, setOpen] = useState(true);

    const token = parseJSON('token', '');
    const isAuthenticated = token ? true : false;

    return isAuthenticated ? (
        <div className="w-full h-screen">
            <Header open={open} setOpen={setOpen} />
            <div className="flex w-full h-full">
                <Sidebar open={open} setOpen={setOpen} />
                <main className="w-full px-[5rem] pb-[5rem] mt-[5rem] overflow-y-auto">
                    <Content />
                </main>
            </div>
        </div>
    ) : (
        <Navigate to="/sign-in" replace />
    );
};

export default Layout;
