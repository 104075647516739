import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { Button, SecondaryButton } from '../../../components/buttons';
import { AllFilter, SingleFilter } from '../../../components/filter-select';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import ListEmptyContent from '../../../components/list-empty';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../containers/ScreenContainers';
import { personnelData } from '../../../data/personnelData';
import { capitalizeFirstLetter } from '../../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'name',
    },
    {
        label: 'Department',
        key: 'department',
    },
    {
        label: 'Year of Employment',
        key: 'yearOfEmployment',
    },
    {
        label: 'Roles',
        key: 'roles',
    },
    {
        label: 'Email Address',
        key: 'emailAddress',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Lecturers = () => {
    const navigate = useNavigate();

    const [isLoading /*setIsLoading*/] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(personnelData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <>
            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No Department yet" subtitle="Create new Department(s)">
                        <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                            <Button bgColor="#10B981" color="#fff">
                                <Circularplus className="mr-[1.15rem]" />
                                Create New
                            </Button>
                            <Button bgColor="#6366F1" color="#fff">
                                <UploadIcon className="mr-[1.15rem]" />
                                Upload Sheet
                            </Button>
                        </CenteredContainer>
                    </ListEmptyContent>
                </CenteredContainer>
            ) : (
                <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <FlexCentredRow>
                            <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                            <SingleFilter name="Department" />
                            <SingleFilter name="Roles" withRadius />
                        </FlexCentredRow>

                        <FlexCentredRow>
                            <SecondaryButton>Reset</SecondaryButton>
                            <AllFilter />
                        </FlexCentredRow>
                    </FilterContainer>
                    <CDataTable
                        items={personnelData || []}
                        fields={fields}
                        selectable
                        responsive
                        itemsPerPage={itemsPerPage}
                        activePage={currentPage || 1}
                        clickableRows
                        onRowClick={(item) => navigate(`/personnel/lecturers/${item.id}`)}
                        loading={isLoading}
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox />
                                </td>
                            ),
                            name: (item) => <td style={{ color: '#2563EB' }}>{capitalizeFirstLetter(item.name)}</td>,
                        }}
                    />
                    <ItemsPerPageCont>
                        <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                        <CPagination
                            activePage={currentPage || 1}
                            onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                            pages={totalPages || 1}
                            align="end"
                        />
                    </ItemsPerPageCont>
                </div>
            )}
        </>
    );
};

export default Lecturers;
