import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Download } from '../../../assets/icons/download.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external-Link.svg';
import { ReactComponent as ThreeDots } from '../../../assets/icons/three-dots.svg';
import { ReactComponent as UploadedFile } from '../../../assets/icons/uploaded-file.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { handleDownload, handleImagePreview } from '../../../utils';

const GridContainer = tw.div`
    grid
    grid-cols-2
    mt-[2.8rem]
    gap-x-[2.8rem]
`;

const UploadContainer = tw.div`
    mb-[2.8rem]
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const cardItems = [
    {
        title: 'Recent Passport',
        objKey: 'passport',
    },
    {
        title: 'Letter of Appointment',
        objKey: 'appointment',
    },
    {
        title: 'Certificate of Origin*',
        objKey: 'origin',
    },
    {
        title: 'First Degree Certificate',
        objKey: 'firstDegree',
    },
    {
        title: 'Attestation Letter*',
        objKey: 'attestation',
    },
    {
        title: 'Second Degree Certificate',
        objKey: 'secondDegree',
    },
    {
        title: "O'Level Result*",
        objKey: 'olevel',
    },
    {
        title: 'Medical Certificate*',
        objKey: 'medical',
    },
];

const UploadsDetails = (uploads) => {
    const { upload } = uploads || {};
    const navigate = useNavigate();

    const [selectedUrl, setSelectedUrl] = useState('');

    return (
        <>
            <Details className="mt-[3rem] mb-[1.5rem]">
                <FlexRowSpaceBetween>
                    <PageTitle size="1.9rem" lineheight="2.8rem">
                        Uploads
                    </PageTitle>
                    <Button
                        border="1px solid #E5E7EB"
                        bgColor="#FFFFFF"
                        color="#6366F1"
                        onClick={() => navigate('/dashboard/bio-data', { state: { isEdit: true, tab: 3 } })}
                    >
                        <Edit className="mr-[13.15px]" />
                        Edit
                    </Button>
                </FlexRowSpaceBetween>
            </Details>
            <GridContainer>
                {cardItems.map((item) => (
                    <UploadContainer>
                        <FlexCentredRow className="bg-white py-10 pl-10 text-center border-b-2">
                            <Text weight="600" size="1.6rem" lineHeight="2.8rem">
                                {item.title}
                            </Text>
                        </FlexCentredRow>

                        {upload?.[item.objKey]?.map((itemObj, index) => (
                            <FlexRowSpaceBetween key={index} className="bg-white p-10 items-center">
                                <FlexCentredRow className="cursor-pointer w-full">
                                    <UploadedFile className="mr-[2.308rem]" />
                                    <FlexCentredCol>
                                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                            {itemObj?.fileName?.length > 20
                                                ? itemObj?.fileName.substring(0, 20) + '...'
                                                : itemObj?.fileName}
                                        </Text>
                                        <Text
                                            weight="500"
                                            align="left"
                                            color="#6B7280"
                                            size="1.4rem"
                                            lineHeight="2.4rem"
                                        >
                                            Size - {itemObj?.size}kb
                                        </Text>
                                    </FlexCentredCol>
                                </FlexCentredRow>
                                <RelativeContainer>
                                    <ThreeDots
                                        className="cursor-pointer"
                                        onClick={() => setSelectedUrl(itemObj?.url)}
                                    />
                                    <ActionsPopup
                                        open={selectedUrl === itemObj?.url}
                                        close={() => setSelectedUrl('')}
                                        items={[
                                            {
                                                icon: <Download fill="#1F2937" />,
                                                name: 'Download',
                                                click: () => {
                                                    const downloadUrl = itemObj?.url;
                                                    const fileName = itemObj?.fileName;

                                                    if (downloadUrl && fileName) {
                                                        handleDownload(downloadUrl, fileName);
                                                    }
                                                },
                                            },

                                            {
                                                icon: <ExternalLink fill="#1F2937" />,
                                                name: 'Preview',
                                                click: () => handleImagePreview(itemObj?.url),
                                            },
                                        ]}
                                    />
                                </RelativeContainer>
                            </FlexRowSpaceBetween>
                        ))}
                    </UploadContainer>
                ))}
            </GridContainer>
        </>
    );
};

export default UploadsDetails;
