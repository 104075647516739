import React from 'react';

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import { Button } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    w-[32rem]
    md:w-[50rem]
    lg:w-[60.8rem]
    h-auto
    bg-white
    mx-auto
    p-[3rem]
    lg:p-[4.8rem]
    md:p-[7.5rem]
    rounded-lg
`;

const OnboardingForm = () => {
    const navigate = useNavigate();
    return (
        <>
            <Formik
                initialValues={{
                    utme: 'red',
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                    console.log('first');
                }}
            >
                {({
                    errors,
                    // handleChange,
                    // handleBlur,
                    isSubmitting,
                }) => (
                    <Form>
                        <MainContainer>
                            <div>
                                <div>
                                    <Text size="1.9rem" weight="600" align="left">
                                        Welcome!
                                    </Text>
                                    <Text color="#9CA3AF" bottom="3.2rem" align="left">
                                        Input your details to continue
                                    </Text>
                                </div>
                                <TextInput name="utme" type="text" placeholder="UTME/Mat No." errors={errors} />
                            </div>
                            <div className="flex">
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => navigate('/no-admission')}
                                    bgColor="#F3F4F6"
                                    color="#D1D5DB"
                                    className="py-2 px-4 mt-12 justify-end focus:text-white hover:bg-[#6366F1] mb-[1.9rem] cursor-pointer"
                                >
                                    Continue
                                </Button>
                            </div>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OnboardingForm;
