import tw from 'twin.macro';

import DatePicker from '../../../components/inputs/date-picker';
import TextInput from '../../../components/inputs/text-input';

const GridContainer = tw.div`
bg-white
grid 
md:grid-cols-2  
justify-between
items-center
gap-[2rem] 
md:gap-[2rem] 
w-[100%]
`;

const Awards = ({ year, award, awardErrors, handleChange, awardValue, yearValue, touched }) => {
    return (
        <div>
            <GridContainer>
                <DatePicker
                    useYear
                    name={year}
                    placeholder="Year"
                    label="Year"
                    touched={touched}
                    initialValue={yearValue ? new Date(yearValue) : null}
                />

                <TextInput
                    name={award}
                    value={awardValue}
                    errors={awardErrors}
                    placeholder="Award"
                    label="Award"
                    type="text"
                    onChange={handleChange}
                />
            </GridContainer>
        </div>
    );
};

export default Awards;
