import React, { useRef, useState } from 'react';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as AttachmentIcon } from '../../../../assets/icons/attachment.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as Gallery } from '../../../../assets/icons/gallery.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { ReactComponent as Smiley } from '../../../../assets/icons/smiley.svg';
import { PDFPreview } from '../../../../components';
import { Button } from '../../../../components/buttons';
import AddNewLink from '../../../../components/forms/add-new-link';
import ScheduleSend from '../../../../components/forms/schedule-send';
import Select from '../../../../components/inputs/new-select';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { sendDraftNotice, sendLecturerNotice } from '../../../../redux/communications/actions';

const StyledButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    px-[1em]
    py-[.4em]
    bg-[#6366F1]
    text-[#FFF]
    text-[1.4rem]
    leading-6
    focus:outline-none
    h-[4rem]
`;

const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    rounded-lg
`;

const Header = tw.div`
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    py-[2.4rem]
    px-[3.2rem]
    bg-white
    mx-auto

`;

const SubHeader2 = tw.div`
    border-[#E5E7EB]
    border-b
    bg-white
    mx-auto
    px-[3.2rem]
    py-[1.4rem]
`;

const NewInput = tw.input`
    pl-3
    rounded
    focus:outline-none
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-full
`;

const SelectInput = tw.select`
    pl-3
    rounded
    focus:outline-none
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
`;

const TextArea = tw.textarea`
    w-full
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    placeholder:font-semibold
    leading-6
    font-normal
    text-[1.4rem]
    mb-5
    p-[3.5rem]
`;

const Notice = ({ pageTitle }) => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [showScheduleSend, setShowScheduleSend] = useState(false);
    const [link, setLink] = useState('');
    const [linkName, setLinkName] = useState('');
    const [attachment, setAttachment] = useState([]);
    const [image, setImage] = useState([]);
    const hiddenFileInput = useRef(null);
    const hiddenImageInput = useRef(null);
    const [content, setContent] = useState('');
    const [showAddLink, setShowAddLink] = useState(false);
    const { courses } = useSelector((state) => state.allotedCourses);
    const { isLoading } = useSelector((store) => store.communications);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [isDraft, setIsDraft] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleImageClick = () => {
        hiddenImageInput.current.click();
    };

    const addAttachment = async (e) => {
        const files = Array.from(e.target.files);
        const allowedTypes = ['application/pdf'];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (allowedTypes.includes(file.type)) {
                setAttachment(files);
                toast.success(`${file.name} selected`);
            } else {
                toast.error(`Upload a PDF file`);
            }
        }
    };

    const addImage = (e) => {
        const files = Array.from(e.target.files);
        const allowedTypes = ['image/jpeg', 'image/png'];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (allowedTypes.includes(file.type)) {
                setImage(files);
                toast.success(`${file.name} selected`);
            } else {
                toast.error(`${file.name} is not an accepted file type`);
            }
        }
    };

    const handleAddLink = () => {
        setContent(`${content} ${linkName}`);
    };

    const onEmojiClick = (e) => {
        const sym = e.unified.split('_');
        const emojiArray = [];
        sym.forEach((el) => emojiArray.push('0x' + el));
        let emoji = String.fromCodePoint(...emojiArray);
        setContent(content + emoji);
    };

    const handleReset = () => {
        if (showPicker) {
            setShowPicker(false);
        } else if (showAddLink) {
            setShowAddLink(false);
        } else {
            return null;
        }
    };

    const recipient = selectedCourse?.map((item) => item.courseCode);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (let i = 0; i < recipient.length; i++) {
            formData.append(`distributionList[${i}]`, recipient[i]);
        }
        attachment.forEach((file) => {
            formData.append('attachment', file);
        });
        image.forEach((file) => {
            formData.append('image', file);
        });
        formData.append('content', content);
        formData.append('link', link);
        formData.append('title', title);
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };
        if (isDraft) {
            dispatch(sendDraftNotice({ formData, headers }));
            setIsDraft(false);
        } else {
            dispatch(sendLecturerNotice({ formData, headers }));
        }
    };

    if (isLoading) return <Loader message="Processing..." />;

    return (
        <div className="max-w-[1000px]" onClick={handleReset}>
            <FlexCentredRow className="mb-[3.2rem]">
                <BackArrow onClick={() => navigate(-1)} className="mr-[8px] hover:cursor-pointer" />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Communications
                </PageTitle>
            </FlexCentredRow>
            <Header>
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[1.015rem]">
                        <PageTitle>{pageTitle}</PageTitle>
                        <Dropdown />
                    </FlexCentredRow>
                    <FlexCentredRow className=" cursor-pointer gap-[6.315rem]">
                        <Close onClick={() => navigate(-1)} />
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
            </Header>
            <form onSubmit={handleSubmit}>
                {pageTitle !== 'Letter' && (
                    <div className="">
                        <SubHeader2 className="relative flex items-center gap-[20px]">
                            <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                                TO:
                            </Text>
                            <Select
                                name="courses"
                                objProp="courseCode"
                                data={courses}
                                containerClass="w-[100px] md:w-[200px] lg:w-[400px] xl:w-[600px]"
                                placeholder="Select Distribution List"
                                multiSelect
                                selectedItems={selectedCourse}
                                type="text"
                                onChange={(selected) => {
                                    setSelectedCourse(selected);
                                }}
                            />
                        </SubHeader2>
                    </div>
                )}
                {pageTitle === 'Letter' && (
                    <>
                        <SubHeader2 className="flex flex-row">
                            <Text weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                                FROM (OFFICE)
                            </Text>
                            <NewInput
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                name="subjects"
                                type="text"
                                placeholder=""
                            />
                        </SubHeader2>
                        <SubHeader2 className="relative flex justify-between">
                            <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                                THROUGH:
                            </Text>
                            <SelectInput></SelectInput>
                        </SubHeader2>

                        <SubHeader2 className="relative flex justify-between">
                            <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                                TO:
                            </Text>
                            <SelectInput></SelectInput>
                        </SubHeader2>
                    </>
                )}

                <SubHeader2 className="flex flex-row">
                    <Text weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                        TITLE
                    </Text>
                    <NewInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        name="subjects"
                        type="text"
                        placeholder=""
                    />
                </SubHeader2>
                <MainContainer>
                    <TextArea value={content} rows="30" onChange={(e) => setContent(e.target.value)} />
                    <div className="flex gap-[1rem]">
                        {image.length > 0 && (
                            <>
                                {image?.map((pic, i) => (
                                    <div key={i} className="w-[100px] h-[100px]">
                                        <img src={URL.createObjectURL(pic)} alt="" className="w-full h-[100%]" />
                                    </div>
                                ))}
                            </>
                        )}
                        {attachment.length > 0 && (
                            <>
                                {attachment?.map((file, i) => (
                                    <div key={i} className="max-h-[100px]">
                                        <PDFPreview pageNumber={1} url={URL.createObjectURL(file)} />
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <FlexRowSpaceBetween className="gap-[0.2rem] p-[3.5rem]">
                        <FlexCentredRow className="relative gap-[3.8rem]">
                            {showPicker && (
                                <div className=" absolute left-0  bottom-12 z-[1000] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]">
                                    <Picker data={data} onEmojiSelect={onEmojiClick} />
                                </div>
                            )}
                            <LinkIcon onClick={() => setShowAddLink((previousState) => !previousState)} />
                            {showAddLink ? (
                                <AddNewLink
                                    text={linkName}
                                    link={link}
                                    handleTextChange={(e) => setLinkName(e.target.value)}
                                    handleUrlChange={(e) => setLink(e.target.value)}
                                    handleAdd={handleAddLink}
                                    show={showAddLink}
                                    setShow={setShowAddLink}
                                />
                            ) : null}
                            <Smiley onClick={() => setShowPicker((val) => !val)} />

                            <input
                                type="file"
                                className="hidden"
                                ref={hiddenFileInput}
                                id="fileInput"
                                accept=".pdf"
                                onChange={addAttachment}
                                multiple
                            />
                            <AttachmentIcon onClick={handleClick} />
                            <input
                                type="file"
                                id="imageInput"
                                ref={hiddenImageInput}
                                className="hidden"
                                accept=".jpg,.jpeg,.png"
                                onChange={addImage}
                                multiple
                            />
                            <Gallery onClick={handleImageClick} />
                        </FlexCentredRow>
                        <FlexCentredRow className="gap-[2.4rem]">
                            <Button border="0.5px solid #D1D5DB" onClick={() => setIsDraft(true)}>
                                Save as draft
                            </Button>

                            <FlexCentredRow>
                                <StyledButton className="py-0 rounded-l-md">Send</StyledButton>
                                <StyledButton
                                    type="button"
                                    className="py-0 border-l rounded-r-md border-l-[#A5B4FC]"
                                    onClick={() => setShowScheduleSend((previousState) => !previousState)}
                                >
                                    <Dropdown />
                                </StyledButton>
                            </FlexCentredRow>
                        </FlexCentredRow>
                        {showScheduleSend && <ScheduleSend show={showScheduleSend} setShow={setShowScheduleSend} />}
                    </FlexRowSpaceBetween>
                </MainContainer>
            </form>
        </div>
    );
};

export default Notice;
