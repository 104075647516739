import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { Text } from '../../../containers/MesssageContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 10.9rem 11.2rem 10.3rem;
        width: 59.2rem;
    }
`;

const StyledCloseIcon = tw(CloseIcon)`
    absolute
    top-[3.5rem]
    right-[3.9rem]
`;

const InnerContainer = tw.div`
    flex
    flex-col
    items-center
    border
    border-dashed
    pt-[2.7rem]
    pb-[2.4rem]
    px-[12.1rem]
    rounded-[8px]
`;

const DownloadFile = ({ show, setShow }) => {
    const close = () => setShow(false);
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledCloseIcon onClick={close} />
            <InnerContainer className="border-[#6B7280]">
                <DownloadIcon />
                <Text size="1.6rem" weight="500" top="1.9rem" bottom="1.6rem">
                    Downloading.. 53%
                </Text>

                <Text color="#6B7280" top="0.8rem">
                    1.4MB/sec
                </Text>
            </InnerContainer>
        </StyledModal>
    );
};

export default DownloadFile;
