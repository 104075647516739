import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as CalenderIcon } from '../../assets/icons/calendar-icon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/document-icon2.svg';
import { Text } from '../../containers/MesssageContainers';

const ItemBox = tw.div`
    flex
    flex-row
    items-center
    gap-[1rem]
    hover:bg-[#F3F4F6]
    p-[1rem]
    rounded-[4px]
    cursor-pointer
`;

const CalenderToggle = ({ onCalenderClick, onDocumentClick, academicCalender }) => {
    return (
        <div className="absolute px-[1rem] py-[2rem] bg-white w-[233px] top-[8rem] right-[0rem] flex flex-col gap-[1rem] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)] z-[100]">
            <ItemBox onClick={onDocumentClick}>
                <DocumentIcon />
                <Text size="1.6rem" weight="400" align="left">
                    Document
                </Text>
            </ItemBox>
            {!academicCalender && (
                <ItemBox onClick={onCalenderClick}>
                    <CalenderIcon />
                    <Text size="1.6rem" weight="400">
                        Calender
                    </Text>
                </ItemBox>
            )}
        </div>
    );
};

export default CalenderToggle;
