import { createSlice } from '@reduxjs/toolkit';

import { capitalizeEachWord } from '../../utils';

import { getUniqueSecondDegrees, shortenToString } from './utils';

const initialState = {
    isLoading: false,
    savedAcademicStructure: [],
    firstDegreeOptionsStr: '', //e.g Fac/Col/Sch or Faculty if only one item
    secondDegreeOptionsStr: '', //e.g Dep/Fac/Sch or Department if only one item
    firstDegreeOptionsArray: [],
    secondDegreeOptionsArray: [],
};

export const academicStructureSlice = createSlice({
    name: 'academicStructure',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        SAVED_ACADEMIC_STRUCTURE: (state, action) => {
            const savedKeys = Object.keys(action.payload || {}).map((item) => capitalizeEachWord(item));

            const uniqueSecondDegrees = getUniqueSecondDegrees(action.payload);

            state.savedAcademicStructure = action.payload;
            state.firstDegreeOptionsStr = shortenToString(savedKeys);
            state.secondDegreeOptionsStr = shortenToString(uniqueSecondDegrees);
            state.firstDegreeOptionsArray = savedKeys;
            state.secondDegreeOptionsArray = uniqueSecondDegrees;
        },
    },
});
export const { SAVED_ACADEMIC_STRUCTURE, LOADING, STOP_LOADING } = academicStructureSlice.actions;

export default academicStructureSlice.reducer;
