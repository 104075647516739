import axios from 'axios';

import { parseJSON } from '../utils/useJSON';

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
};

// update axiosInstance
const axiosInstance = axios.create(defaultOptions);

//set the auth token if there's any for any request
axiosInstance.interceptors.request.use(
    (config) => {
        const token = parseJSON('token', '');
        if (config && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // const originalRequest = error.config;
        // if (error.response && error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     return axios(originalRequest);
        // }
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/sign-in');
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;

export const createAPIRequest = (config) => axiosInstance(config);
