import React from 'react';

import { styled } from 'twin.macro';

import { Text } from '../../containers/MesssageContainers';

const Container = styled.div`
appearance: none
width: full

::-webkit-progress-bar {
  height: 3px
  background-color: #E0E7FF
}
::-webkit-progress-value {
  height-3px
  background-color: #6366F1
}
`;

const ProgressBar = ({ value, max, color, width }) => {
    return (
        <>
            <Text size="1.6rem" weight="500" top="1.9rem" bottom="1.6rem">
                Uploading.. <span>{(value / max) * 100}%</span>
            </Text>
            <Container color={color} width={width}>
                <progess value={value} max={max} />;
            </Container>
        </>
    );
};

export default ProgressBar;
