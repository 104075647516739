import React from 'react';

import logo from '../../assets/icons/belrald-without-tech.svg';

export const PoweredBy = () => {
    return (
        <div className="flex items-center mt-8 text-[1.4rem] font-medium">
            <p className="text-[#9CA3AF] m-0">Powered by:</p>
            <img src={logo} width="100" alt="" />
        </div>
    );
};
