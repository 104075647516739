import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document-icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload5.svg';
import { UploadFile } from '../../../components';
import { Button } from '../../../components/buttons';
import SearchInput from '../../../components/inputs/search-input';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
  w-full
  border
  bg-white
`;
const Container = tw.div`
flex
flex-col
`;

const HeaderRight = tw.div`
rounded-t-lg 
bg-[white] 
p-[1.6rem] 
mb-[0.2rem]

`;

const Details = tw.div`
mb-[29rem]
mt-[1.7rem]
`;
const CardRight = tw.div`
pl-[2.4rem]
pr-[15rem]
pb-[0.1rem]
bg-white
mb-[0.2rem]


`;

const OpenPreview = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[2rem] ">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px] " />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Learning Resources
                    </PageTitle>
                    <Text size="1.6rem" left="1.4rem">
                        / MEE 405
                    </Text>
                </FlexCentredRow>
                <Button bgColor="#6366F1" color="#fff" onClick={() => setShowUploadFile(true)}>
                    <UploadIcon className="mr-[1.15rem]" />
                    Upload
                </Button>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] rounded-t-lg  pb-[1.8rem]">
                <FilterContainer>
                    <SearchInput
                        containerWidth="100%"
                        width={'100%'}
                        withRadius
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                </FilterContainer>

                <Container>
                    <HeaderRight>
                        <FlexRowSpaceBetween>
                            <FlexCentredRow>
                                <DocumentIcon className="mr-[1.033rem]" />
                                <PageTitle>Advanced Engineering Drawing.pptx</PageTitle>
                            </FlexCentredRow>
                            <CloseIcon
                                className="m-[2.23rem] cursor-pointer"
                                //  onClick={onClose}
                            />
                        </FlexRowSpaceBetween>
                    </HeaderRight>
                    <CardRight>
                        <Text align="left" weight="500" color="#9CA3AF">
                            Posted Friday, August 19
                        </Text>
                        <Details>
                            <Text weight="600" lineHeight="2.8rem" align="left" size="1.6rem">
                                Design Assignment
                            </Text>
                            <Text lineHeight="2.8rem" align="left" size="1.6rem">
                                The goal of this assignment is to exercise your user interface design abilities.
                                Creativity in balancing usability with the constraints of the interface will be
                                essential to successfully completing this assignment.
                                <Text lineHeight="2.8rem" align="left" size="1.6rem" className="mt-[4rem]">
                                    You should design your interface for a circular touchscreen that is 1.5" in
                                    diameter. The resolution of the screen is not defined, but you can assume that it is
                                    high. There are 4 physical buttons on the case at the diagonals. The screen itself
                                    is a button. To make things interesting, there is a physical dial knob around the
                                    screen with full range of motion (think of the wheel on the original iPod).
                                </Text>
                            </Text>
                            <Text weight="600" lineHeight="2.8rem" align="left" size="1.6rem">
                                Grading:
                            </Text>
                            <Text lineHeight="2.8rem" align="left" size="1.6rem">
                                This assignment is 2% of your overall grade, which has been broken down into 10 points
                                for grading. The breakdown of points is as follows: The screen itself is a button. To
                                make things interesting, there is a physical dial knob around the screen with full range
                                of motion (think of the wheel on the original iPod). This assignment is 2% of your
                                overall grade, which has been broken down into 10 points for grading. The breakdown of
                                points is as follows: The screen itself is a button.
                            </Text>
                        </Details>
                    </CardRight>
                </Container>
            </div>

            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default OpenPreview;
