import React, { useEffect, useMemo, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down2.svg';
// import { AllFilter } from '../../../components/filter-select';
import { ActionsPopup } from '../../../components';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import { Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
    // RelativeContainer,
} from '../../../containers/ScreenContainers';
import {
    getAttendanceSpreadsheet,
    getAttendanceVersions,
    markStudentAttendance,
} from '../../../redux/attendance/actions';
import { getDegreeInfo } from '../../../utils';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Select = tw.div`
    rounded-[4px]
    border
    border-[#E5E7EB]
    px-[16px]
    py-[8px]
    flex
    justify-between
    h-[51px]
    items-center
`;

const overviewItems = [
    {
        title: 'Attendance Type',
        key: 'attendanceType',
    },
    {
        title: 'Attendance Title',
        key: 'title',
    },
    {
        title: 'Continuous Assessment',
        key: 'continousAssessmentOption',
    },
    {
        title: 'Description',
        key: 'description',
    },
    {
        title: 'Lecture Start Time',
        key: 'startTime',
    },
    {
        title: 'Lecture Date & End Time',
        key: 'date',
        type: 'date',
    },
    {
        title: 'Date Created',
        key: 'createdAt',
        type: 'date',
    },
    {
        title: 'Source',
        key: 'source',
    },
];

const formatDate = (date) => date.slice(0, 10).split('-').reverse().join('-');

const tableRowClass = 'h-[51px] flex items-center';

const AttendanceSpreadsheet = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { session, course, attendance } = state || {};

    const { _id: attendanceId } = attendance || {};
    const { _id: courseId, courseCode, courseTitle } = course || {};

    const { isLoading } = useSelector((store) => store.attendance);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [spreadSheet, setSpreadSheet] = useState([]);
    const [openSelectId, setOpenSelectId] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [versions, setVersions] = useState([]);
    const latestVersion = versions[versions?.length - 1];

    const totalPages = Math.ceil(spreadSheet?.length / itemsPerPage);

    const handleRecord = async (student, isPresent) => {
        const { studentId } = student;

        if (String(student.isPresent) === isPresent) return;
        const params = {
            courseId: course._id,
            session,
            studentId,
            attendanceId,
            version: latestVersion?.version,
        };

        const res = await dispatch(markStudentAttendance(params));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const fields = [
        {
            label: <Checkbox />,
            key: 'action',
        },
        {
            label: 'Full Name',
            key: 'studentName',
        },
        {
            label: 'Matric No.',
            key: 'matricNumber',
        },
        {
            label: firstDegreeOptionsStr,
            key: 'firstDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: secondDegreeOptionsStr,
            key: 'secondDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: `Select Status`,
            key: 'status',
        },
    ];

    const modifiedSpreadSheet = useMemo(() => {
        return spreadSheet?.map((stud) => {
            const studStructures = getDegreeInfo(stud);
            const { firstDegree, secondDegree, thirdDegree } = studStructures || {};

            return {
                firstDegree,
                secondDegree,
                thirdDegree,
                ...stud,
            };
        });
    }, [spreadSheet]);

    useEffect(() => {
        if (!session) return;
        const fetchVersions = async () => {
            const versionData = await dispatch(getAttendanceVersions(session, courseId));
            setVersions(versionData || []);
        };

        fetchVersions();
    }, [courseId, dispatch, session]);

    useEffect(() => {
        if (!session) return;

        const fetchSpreadsheet = async () => {
            const res = await dispatch(getAttendanceSpreadsheet(session, courseId, attendanceId));
            setSpreadSheet(res);
        };
        fetchSpreadsheet();
    }, [courseId, dispatch, session, attendanceId, toggleRefetch]);

    return (
        <>
            <FlexRowSpaceBetween className="gap-[1rem">
                <GoBack title={`Attendance / ${courseCode} (${courseTitle})`} subTitle="/ Spreadsheet" />
            </FlexRowSpaceBetween>

            <div className="bg-white w-full p-[24px] mt-12 rounded-lg">
                <Text weight="600" size="1.9rem" align="left">
                    Overview
                </Text>

                <div className="grid grid-cols-4 gap-y-8 mt-8">
                    {overviewItems.map((item) => (
                        <FlexColumn className="gap-1" key={item.title}>
                            <Text weight="500" align="left" size="1.2rem" color="#9CA3AF">
                                {item.title}
                            </Text>
                            <Text weight="500" align="left" size="1.4rem" color="#4B5563">
                                {item.key === 'continousAssessmentOption'
                                    ? attendance[item.key]
                                        ? 'Yes'
                                        : 'No'
                                    : item.key === 'date'
                                    ? `${formatDate(attendance?.date)}, ${attendance?.endTime}`
                                    : item.type === 'date'
                                    ? formatDate(attendance[item.key])
                                    : attendance[item.key]}
                            </Text>
                        </FlexColumn>
                    ))}
                </div>
            </div>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem] mb-[5rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>

                    {/* <RelativeContainer> */}
                    {/* <AllFilter
                        // showClear={currentFilterCategory}
                        // items={FILTER_OPTIONS.map((item) => ({
                        //     name: item,
                        //     click: () => {
                        //         handleSelectCategories(item);
                        //         setCurrentFilterCategory(item);
                        //         setOpenModal('options-popup-main');
                        //     },
                        // }))}
                        // handleReset={handleReset}
                        /> */}
                    {/* <FilterPopup
                            open={openModal === 'options-popup-main'}
                            isLoading={facultyLoading || collegeLoading}
                            close={() => setOpenModal(null)}
                            items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                        /> */}
                    {/* </RelativeContainer> */}
                </FilterContainer>

                <CDataTable
                    items={[...modifiedSpreadSheet, {}] || []}
                    fields={fields}
                    // // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    // clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <span className={tableRowClass}>
                                    <Checkbox />
                                </span>
                            </td>
                        ),
                        studentName: (item) => (
                            <td>
                                <span className={tableRowClass}>{item.studentName}</span>
                            </td>
                        ),
                        matricNumber: (item) => (
                            <td>
                                <span className={tableRowClass}>{item.matricNumber}</span>
                            </td>
                        ),
                        firstDegree: (item) => (
                            <td>
                                <span className={tableRowClass}>{item.firstDegree}</span>
                            </td>
                        ),
                        secondDegree: (item) => (
                            <td>
                                <span className={tableRowClass}>{item.secondDegree}</span>
                            </td>
                        ),

                        status: (item) => (
                            <td>
                                {item?.studentName ? (
                                    <RelativeContainer className="ml-auto mr-0">
                                        <Select onClick={() => setOpenSelectId(item.studentId)}>
                                            <p
                                                className={` mb-0 ${
                                                    item?.isPresent ? 'text-[#059669]' : 'text-[#EF4444]'
                                                }`}
                                            >
                                                {item.isPresent ? 'Present' : 'Absent'}
                                            </p>
                                            <ArrowDownIcon />
                                        </Select>

                                        <ActionsPopup
                                            open={openSelectId === item.studentId}
                                            close={() => setOpenSelectId('')}
                                            width="100%"
                                            items={[
                                                {
                                                    name: 'Present',
                                                    color: 'text-[#059669]',
                                                    click: () => {
                                                        handleRecord(item, 'true');
                                                        setOpenSelectId('');
                                                    },
                                                },
                                                {
                                                    name: 'Absent',
                                                    color: 'text-[#EF4444]',
                                                    click: () => {
                                                        handleRecord(item, 'false');
                                                        setOpenSelectId('');
                                                    },
                                                },
                                            ]}
                                        />
                                    </RelativeContainer>
                                ) : null}
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </>
    );
};

export default AttendanceSpreadsheet;
