import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
// import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
// import { AllFilter } from '../../../components/filter-select';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import {
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { deleteAttendance, getAttendance } from '../../../redux/attendance/actions';

const fields = [
    {
        label: <Checkbox />,
        key: 'action',
    },
    {
        label: 'Attendance Title',
        key: 'title',
    },
    {
        label: 'Registered Students',
        key: 'numberOfStudents',
    },
    {
        label: 'Total Attendees',
        key: 'numberOfPresent',
    },
    {
        label: 'Continous Assessment',
        key: 'continousAssessmentOption',
    },
    {
        label: 'Source',
        key: 'attendanceType',
    },
    {
        label: 'Description',
        key: 'description',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const CONFIRM_TEXT = 'delete';

const CourseAttendance = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { course, session } = state || {};
    const { courseCode, courseTitle, _id: courseId } = course || {};

    const { isLoading } = useSelector((store) => store.attendance);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [attendance, setAttendance] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const totalPages = Math.ceil(attendance?.length / itemsPerPage);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const handleInputChange = (e) => {
        setConfirmInputValue(e.target.value);
    };

    const handleDelete = async (e) => {
        const res = await dispatch(deleteAttendance(selectedItem?._id));

        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setSelectedItem(null);
            setOpenModal('');
            setConfirmInputValue('');
        }
    };

    useEffect(() => {
        if (!session) return;

        const fetchAttendance = async () => {
            const data = await dispatch(getAttendance(session, courseId));
            if (data) {
                setAttendance(data);
            }
        };

        fetchAttendance();
    }, [courseId, dispatch, session, toggleRefetch]);

    return (
        <>
            <FlexRowSpaceBetween className="gap-[1rem">
                <GoBack title="Attendance" subTitle={`/ ${courseCode} (${courseTitle})`} />
                <FlexDiv className="gap-4">
                    <Link to={`/attendance/create/${courseId}`} state={{ course, session }}>
                        <Button>
                            <PlusIcon className="mr-[1.15rem]" />
                            Create Attendance
                        </Button>
                    </Link>
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            Actions
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Attendance',
                                    disabled: !selectedItem,
                                    click: () => {
                                        navigate(`/attendance/update/${selectedItem._id}`, {
                                            state: { attendance: selectedItem, course, session },
                                        });
                                    },
                                },
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Attendance',
                                    disabled: !selectedItem,
                                    click: () => {
                                        setOpenModal('delete');
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexDiv>
            </FlexRowSpaceBetween>

            <div className="mt-[3.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>

                    {/* <RelativeContainer> */}
                    {/* <AllFilter
                        // showClear={currentFilterCategory}
                        // items={FILTER_OPTIONS.map((item) => ({
                        //     name: item,
                        //     click: () => {
                        //         handleSelectCategories(item);
                        //         setCurrentFilterCategory(item);
                        //         setOpenModal('options-popup-main');
                        //     },
                        // }))}
                        // handleReset={handleReset}
                        /> */}
                    {/* <FilterPopup
                            open={openModal === 'options-popup-main'}
                            isLoading={facultyLoading || collegeLoading}
                            close={() => setOpenModal(null)}
                            items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                        /> */}
                    {/* </RelativeContainer> */}
                </FilterContainer>

                <CDataTable
                    items={attendance || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => handleSelect(item)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox checked={item?._id === selectedItem?._id} />
                            </td>
                        ),
                        title: (item) => (
                            <td style={{ color: '#2563EB' }}>
                                <Link
                                    to={`/attendance/attendance-spreadsheet/${item?._id}`}
                                    state={{ attendance: item, course, session }}
                                >
                                    {item.title}
                                </Link>
                            </td>
                        ),
                        continousAssessmentOption: (item) => <td>{item?.continousAssessmentOption ? 'Yes' : 'No'}</td>,
                        numberOfPresent: (item) => <td>{item.numberOfPresent || 0}</td>,
                        description: (item) => <td>{item.description || ''}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title={`Delete ${selectedItem?.title || 'Attendance'}?`}
                subtitle="This attendance will be completely deleted."
                bodyText={`To confirm deletion, enter '${CONFIRM_TEXT}' in the text field.`}
                placeholder={`Input ${CONFIRM_TEXT}`}
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                input
                isLoading={isLoading}
                onChange={handleInputChange}
                value={confirmInputValue}
                btnDisabled={CONFIRM_TEXT !== confirmInputValue?.toLowerCase()}
            />
        </>
    );
};

export default CourseAttendance;
