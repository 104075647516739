import React, { useEffect, useState } from 'react';

// import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import { FlexDiv, FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import { getCourseAssignments, deleteAssignment /*getCourseDrafts*/ } from '../../../redux/assignment/actions';

import Assignment from './tabs/Assignment';
// import Drafts from './tabs/Drafts';

const CONFIRM_TEXT = 'delete';

const CourseAssignments = () => {
    const { id: courseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { session, course } = state || {};
    const { isLoading } = useSelector((state) => state.assignment);

    const [assignments, setAssignments] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [drafts, setDrafts] = useState([]);
    // const [, /*tab*/ setTab] = useState('');
    const { courseCode, courseTitle } = course || {};
    const [openModal, setOpenModal] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const handleInputChange = (e) => {
        setConfirmInputValue(e.target.value);
    };

    const handleCreate = () => navigate(`/assignments/create/${courseId}`, { state: { session, course } });

    const handleDelete = async () => {
        const res = await dispatch(deleteAssignment(selectedItem?._id));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setSelectedItem(null);
        }
    };

    // const getDrafts = async () => {
    //     const res = await dispatch(getCourseDrafts(id));
    //     if (res) {
    //         setDrafts(res);
    //     }
    // };

    useEffect(() => {
        const getAssignments = async () => {
            const res = await dispatch(getCourseAssignments(session, courseId));
            if (res) {
                setAssignments(res);
                return;
            }
            setAssignments([]);
        };

        getAssignments();
        // getDrafts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, courseId, toggleRefetch]);

    // if (isLoading) return <Loader />;

    return (
        <div className="h-[100vh]">
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <GoBack title="Assignments" subTitle={`/ ${courseCode} (${courseTitle})`} />
                <FlexDiv className="gap-4">
                    <Button onClick={handleCreate}>
                        <PlusIcon className="mr-[1.15rem]" />
                        Create Assignment
                    </Button>
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            Actions
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Assignment',
                                    disabled: !selectedItem,
                                    click: () => {
                                        navigate(`/assignments/update/${selectedItem?._id}`, {
                                            state: { assignment: selectedItem, session },
                                        });
                                        setDropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Assignment',
                                    disabled: !selectedItem,
                                    click: () => {
                                        setOpenModal('delete');
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexDiv>
            </FlexRowSpaceBetween>

            {/* <CTabs>
                <CNav variant="tabs" className="mb-4">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Assignments</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Drafts</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane> */}
            <Assignment
                course={course}
                assignments={assignments}
                handleCreate={handleCreate}
                // getAssignments={() => setToggleRefetch((prevState) => !prevState)}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                session={session}
            />
            {/* </CTabPane>
                    <CTabPane>
                        <Drafts drafts={drafts} handleCreatedex={handleCreate} getDrafts={getDrafts} />
                    </CTabPane>
                </CTabContent> */}
            {/* </CTabs> */}

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete Assignment?"
                subtitle="This Assignment and all attached information will be completely deleted."
                bodyText={`To confirm deletion, enter '${CONFIRM_TEXT}' in the text field.`}
                placeholder={`Input ${CONFIRM_TEXT}`}
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                input
                onChange={handleInputChange}
                value={confirmInputValue}
                isLoading={isLoading}
            />
        </div>
    );
};

export default CourseAssignments;
