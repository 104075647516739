import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const { LOADING, STOP_LOADING } = authSlice.actions;

export default authSlice.reducer;
