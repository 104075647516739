import React from 'react';

import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

// import UploadFiles from '../../../components/uploadfile';
import { Button, LoadingButton } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';

const MainContainer = tw.div`
    grid
    mt-8
    mb-[6.4rem]
    rounded-t-lg
`;

const Details = tw.div`
    grid
    grid-cols-2
    gap-[1rem]
    bg-white
`;

const Header = tw.div`
    p-[2.4rem]
    bg-white
    border-[#E5E7EB]
    border-b
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const PublicationsPreview = ({ values, onBtnClick }) => {
    const { isLoading } = useSelector((store) => store.staff);

    return (
        <div>
            <Header className="">
                <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                    Publication
                </PageTitle>
            </Header>
            <MainContainer>
                <Header className="">
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Publication
                    </PageTitle>
                </Header>

                {values?.publicationInfo?.map((publication, index) => (
                    <div className="p-[2rem] bg-white" key={index}>
                        <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" bottom="2rem">
                            Publication {index + 1}
                        </Text>
                        <Details>
                            <div>
                                <GreyText>Publisher</GreyText>
                                <DarkText>{publication?.publisher}</DarkText>
                            </div>
                            <div>
                                <GreyText>Publication Type</GreyText>
                                <DarkText>{publication?.publicationType}</DarkText>
                            </div>
                            <div>
                                <GreyText>Publication Title</GreyText>
                                <DarkText>{publication?.publicationTitle}</DarkText>
                            </div>

                            <div>
                                <GreyText>Author(s)</GreyText>
                                <DarkText>{publication?.author}</DarkText>
                            </div>
                            <div>
                                <GreyText>Publication</GreyText>
                                <DarkText>
                                    {publication?.publication.name || publication?.publication.fileName}
                                </DarkText>
                            </div>
                            <div>
                                <GreyText> Year</GreyText>
                                <DarkText>
                                    {publication?.year ? new Date(publication?.year).getFullYear() : ''}
                                </DarkText>
                            </div>
                            <div>
                                <GreyText>Publication Link</GreyText>
                                <DarkText>{publication?.publicationLink}</DarkText>
                            </div>
                        </Details>
                    </div>
                ))}
            </MainContainer>

            <FlexRowEnd className="my-[3rem] gap-[1.45rem]">
                <Button type="button" onClick={onBtnClick}>
                    Back
                </Button>
                <LoadingButton loading={isLoading} type="submit">
                    Submit
                </LoadingButton>
            </FlexRowEnd>
        </div>
    );
};

export default PublicationsPreview;
