import tw, { styled } from "twin.macro";

const TwButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    px-[1em]
    py-[.4em]
`;

const LoaderDiv = styled.div`
    border: 4px solid #e5e7eb;
    border-top: 4px solid #6366f1;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
    margin-left: 0.5rem;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Button = styled(TwButton)`
    display: flex;
    gap: ${({ gap }) => gap || '4px'};
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    color: ${({ color }) => color || '#1F2937'};
    border-radius: ${({ radius }) => radius || '4px'};
    border: ${({ border }) => border || 'none'};
    height: 4rem;
    outline: none !important;
    font-size: ${({ size }) => size || '1.4rem'};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
    margin-left: ${({ left }) => left || 0};
    margin-right: ${({ right }) => right || 0};
    font-weight: ${({ weight }) => weight || 500};
    line-height: ${({ lineHeight }) => lineHeight || 'inherit'};

    /* &:hover {
        background-color: #4f46e5;
    } */
    &:focus {
        border: 2px solid #a5b4fc !important;
    }
    /* &:active {
        background-color: #4338ca;
    } */
    &:disabled {
        opacity: 0.2;
    }
`;

export const LoadingButton = ({ loading, children, bgColor = '#6366F1', color = '#ffffff', ...props }) => (
    <Button bgColor={bgColor} color={color} {...props}>
        {children}
        {loading && <LoaderDiv />}
    </Button>
);

export const SecondaryButton = styled(Button)`
    &:hover {
        background-color: #F9FAFB;
    }
    &:focus {
        border: 2px solid #C7D2FE !important;
    }
    &:active {
        background-color: #E5E7EB;
    }
`

export const SuccessButton = styled(Button)`
    &:hover {
        background-color: #059669;
    }
    &:focus {
        border: 2px solid #C7D2FE !important;
    }
    &:active {
        background-color: #047857;
    }
`
