import React from 'react';

import { useNavigate } from 'react-router-dom';

import AssignmentAnswer from '../../../components/assignment-answer';
import { Button } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

const ViewSubmission = () => {
    const navigate = useNavigate();

    return (
        <>
            <FlexRowSpaceBetween className="gap-[1rem">
                <GoBack title="Assignments">
                    <Text size="1.6rem" color="#6366F1">
                        / Submissions /
                    </Text>
                    <Text size="1.6rem">name of student or matric number</Text>
                </GoBack>
                <Button onClick={() => navigate('/assignments/spreadsheet')} bgColor="#6366F1" color="#FFF">
                    View Spreadsheet
                </Button>
            </FlexRowSpaceBetween>

            <AssignmentAnswer />
        </>
    );
};

export default ViewSubmission;
