import React, { useState, useEffect, useRef } from 'react';

import { Country, State } from 'country-state-city';
import { Form, Formik, FieldArray } from 'formik';
import NaijaStates from 'naija-state-local-government';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { Button } from '../../../components/buttons';
import DatePicker from '../../../components/inputs/date-picker';
import Select from '../../../components/inputs/new-select';
import TextInput from '../../../components/inputs/text-input';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { getStaffDetails, createStaffBiodata } from '../../../redux/staff/actions';

import BiodataPreview from './Preview';

const titleData = ['Mr', 'Mrs', 'Miss', 'Prof.', 'Dr.', 'Engr.', 'Barr.'];

const genderData = ['Male', 'Female'];

const Marital = ['Single', 'Married'];

const Religion = ['Christianity', 'Islam', 'Traditional'];

const MainContainer = tw.div`
    flex
    flex-col
    gap-[.2rem]
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
`;

const SubContainer = tw.div`
    flex
    flex-col
    px-[2rem]
    py-[2rem]
    gap-[1rem]
    rounded-b-lg
    bg-white
`;

const SelectField = tw.select`
    border-[1px]
    border-[#E5E7EB]
    w-[100%]
    h-[4rem]
    bg-white
    pl-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
`;
const FlexContainer = tw.div`
    bg-white
    md:flex
    md:flex-row
    flex-col
    justify-between
    gap-[1.5rem]
    md:gap-[2rem]
    w-[100%]
`;
const InputDetails = tw.div`
    bg-white
    flex
    flex-col
    w-full
    gap-[2rem]
`;

const Header = tw.div`
    lg:p-[2.4rem]
    md:p-[5.5rem]
    p-[2rem]
    bg-white
    flex
    items-center
    my-[auto]
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    h-[7.6rem]
`;

const AddButton = tw.div`
    bg-white
    h-[4rem]
    flex
    justify-center
    items-center
    gap-[.5rem]
    border-[1px]
    border-[#3B82F6]
    rounded-[4px]
    bg-[#FFFFFF]
    shadow-[0px 1px 2px rgba(6, 25, 56, 0.05)]
    py-[.8rem]
    px-[1.6rem]
    cursor-pointer
    mb-[3.1rem]
    mt-[1.6rem]
`;

const BioData = ({ handleStep }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);

    const { staffBio, staff } = useSelector((store) => store.staff);

    const { firstDegree, secondDegree, firstDegreeStruct, secondDegreeStruct } = staff || {};

    const [country, setCountry] = useState(staffBio?.personalInfo?.nationality);
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [states, setStates] = useState([]);
    const [state, setState] = useState('Abia');
    const [viewPreview, setViewPreview] = useState(false);
    const [profilePictureUrl, setProfilePictureUrl] = useState(staffBio?.profilePicture?.url);

    useEffect(() => {
        dispatch(getStaffDetails());
    }, [dispatch]);

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {
        if (!country) return;
        const selectedCountry = countries.find((data) => data.name === country);
        setCountryCode(selectedCountry?.isoCode);
    }, [country, countries, staffBio?.personalInfo?.nationality]);

    useEffect(() => {
        setStates(State.getStatesOfCountry(countryCode));
    }, [countryCode, staffBio?.personalInfo?.nationality]);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    personalInfo: staffBio?.personalInfo || {
                        title: '',
                        phoneNumber: staff?.phoneNumber || '',
                        dateOfBirth: '',
                        gender: '',
                        designation: '',
                        maritalStatus: '',
                        staffEmail: staff?.staffEmail || '',
                        staffId: staff?.staffId || '',
                        homeTown: '',
                        nationality: '',
                        nin: '',
                        religion: '',
                        stateOfOrigin: '',
                        localGovernment: '',
                        permanentAddress: '',
                        placeOfBirth: '',
                        alternativeHomeAddress: '',
                        nextOfKinName: '',
                        nextOfKinAddress: '',
                        nextOfKinPhoneNumber: '',
                        relationshipWithNextOfKin: '',
                    },
                    reference:
                        staffBio?.reference?.length > 0
                            ? staffBio?.reference
                            : [
                                  {
                                      referee: '',
                                      refereePhoneNumber: '',
                                      refereeAddress: '',
                                      occupation: '',
                                  },
                              ],
                    profilePicture: staffBio?.profilePicture || {
                        url: '',
                    },
                }}
                onSubmit={async (values) => {
                    const formData = new FormData();
                    const payload = values;

                    for (const key in payload.personalInfo) {
                        if (payload.personalInfo[key] !== undefined) {
                            formData.append(`personalInfo[${key}]`, payload.personalInfo[key] || '');
                        }
                    }

                    if (payload?.profilePicture?.url && typeof payload?.profilePicture?.url !== 'string') {
                        //only upload file object. uploading hostedurl clears previous image
                        formData.append('profilePicture', payload.profilePicture.url);
                    }

                    for (let i = 0; i < payload?.reference?.length; i++) {
                        const obj = payload.reference[i];
                        for (const objKey in obj) {
                            if (obj[objKey] !== undefined) {
                                formData.append(`reference[${i}][${objKey}]`, obj[objKey] || '');
                            }
                        }
                    }

                    const headers = {
                        'Content-Type': 'multi-part/form-data',
                    };
                    const res = await dispatch(createStaffBiodata(formData, headers));

                    if (res) {
                        handleStep();
                    }
                }}
            >
                {({ errors, handleChange, touched, values, setFieldValue }) => (
                    <Form>
                        {!viewPreview ? (
                            <>
                                <MainContainer>
                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Personal Information
                                        </PageTitle>
                                    </Header>
                                    <SubContainer>
                                        <FlexCentredRow className="gap-[1.6rem] border-b border-[#D1D5DB] pb-[3.9rem] mb-[3.9rem]">
                                            {values?.profilePicture?.url ? (
                                                <img
                                                    src={profilePictureUrl}
                                                    alt="profile"
                                                    className="rounded-full w-[12.1rem] h-[12.1rem]"
                                                />
                                            ) : (
                                                <Avatar
                                                    name={`${staff?.surname} ${staff?.firstName} ${staff?.otherName}`}
                                                    size="150"
                                                    textSizeRatio={1.75}
                                                    className="rounded-full"
                                                />
                                            )}
                                            <div className="flex items-center gap-[2rem]">
                                                <Button
                                                    type="button"
                                                    onClick={handleClick}
                                                    bgColor="#6366F1"
                                                    color="#fff"
                                                >
                                                    Upload Image
                                                </Button>
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={hiddenFileInput}
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={({ target: { files } }) => {
                                                        if (files && files[0]) {
                                                            setProfilePictureUrl(URL.createObjectURL(files[0]));
                                                            setFieldValue('profilePicture.url', files[0]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setFieldValue('profilePicture', null);
                                                }}
                                                disabled={!values.profilePicture}
                                                bgColor="#D1D5DB"
                                                color="#1F2937;
"
                                            >
                                                Delete
                                            </Button>
                                        </FlexCentredRow>

                                        <FlexContainer>
                                            <InputDetails>
                                                <Select
                                                    name="personalInfo.title"
                                                    objProp="title"
                                                    data={titleData.map((item) => ({ title: item }))}
                                                    placeholder="Title"
                                                    label="Title"
                                                    onChange={(selected) => {
                                                        setFieldValue('personalInfo.title', selected[0].title);
                                                    }}
                                                    errors={errors.personalInfo?.title}
                                                    passedSelectedItems={values.personalInfo?.title}
                                                />
                                                <TextInput
                                                    type="text"
                                                    placeholder="First Name"
                                                    label="First Name"
                                                    value={staff?.firstName}
                                                    disabled
                                                    errors={errors}
                                                />
                                                <TextInput
                                                    type="text"
                                                    placeholder="Surname"
                                                    label="Surname"
                                                    value={staff?.surname}
                                                    disabled
                                                    errors={errors}
                                                />
                                            </InputDetails>
                                            <InputDetails>
                                                <Select
                                                    name="personalInfo.gender"
                                                    objProp="gender"
                                                    data={genderData.map((item) => ({ gender: item }))}
                                                    placeholder="Gender"
                                                    label="Gender"
                                                    onChange={(selected) => {
                                                        setFieldValue('personalInfo.gender', selected[0].gender);
                                                    }}
                                                    errors={errors.personalInfo?.gender}
                                                    passedSelectedItems={values.personalInfo?.gender}
                                                />
                                                <TextInput
                                                    type="text"
                                                    placeholder="Designation"
                                                    label="Designation"
                                                    value={staff?.designation}
                                                    disabled
                                                    errors={errors}
                                                />

                                                <Select
                                                    name="personalInfo.maritalStatus"
                                                    objProp="maritalStatus"
                                                    data={Marital.map((item) => ({ maritalStatus: item }))}
                                                    placeholder="Marital Status"
                                                    label="Marital Status"
                                                    onChange={(selected) => {
                                                        setFieldValue(
                                                            'personalInfo.maritalStatus',
                                                            selected[0].maritalStatus,
                                                        );
                                                    }}
                                                    errors={errors.personalInfo?.maritalStatus}
                                                    passedSelectedItems={values.personalInfo?.maritalStatus}
                                                />
                                            </InputDetails>
                                        </FlexContainer>

                                        <FlexContainer>
                                            <InputDetails>
                                                <TextInput
                                                    type="text"
                                                    placeholder="Staff Email"
                                                    label="Staff Email"
                                                    value={staff?.staffEmail}
                                                    disabled
                                                    errors={errors}
                                                />

                                                <TextInput
                                                    type="text"
                                                    placeholder=" Staff Id"
                                                    label=" Staff Id"
                                                    value={staff?.staffNumber}
                                                    errors={errors}
                                                    disabled
                                                />

                                                <DatePicker
                                                    name="personalInfo.dateOfBirth"
                                                    placeholder="Date of Birth"
                                                    label="Date of Birth"
                                                    touched={touched}
                                                    initialValue={
                                                        values?.personalInfo?.dateOfBirth
                                                            ? new Date(values?.personalInfo?.dateOfBirth)
                                                            : null
                                                    }
                                                />
                                            </InputDetails>

                                            <InputDetails>
                                                <Select
                                                    name="personalInfo.nationality"
                                                    objProp="name"
                                                    data={[{ name: 'Nigeria' }]}
                                                    placeholder="Nationality"
                                                    label="Nationality"
                                                    onChange={(selected) => {
                                                        setFieldValue('personalInfo.nationality', selected[0].name);

                                                        setCountry(selected[0].name);
                                                    }}
                                                    error={errors.personalInfo?.nationality}
                                                    passedSelectedItems={values.personalInfo?.nationality}
                                                />

                                                <Select
                                                    name="personalInfo.religion"
                                                    objProp="religion"
                                                    data={Religion.map((item) => ({ religion: item }))}
                                                    placeholder="Religion"
                                                    label="Religion"
                                                    onChange={(selected) => {
                                                        setFieldValue('personalInfo.religion', selected[0].religion);
                                                    }}
                                                    errors={errors.personalInfo?.religion}
                                                    passedSelectedItems={values.personalInfo?.religion}
                                                />
                                                <Select
                                                    name="personalInfo.stateOfOrigin"
                                                    objProp="name"
                                                    data={states?.map((item) => ({ name: item.name }))}
                                                    placeholder="State of Origin"
                                                    label="State of Origin"
                                                    onChange={(selected) => {
                                                        setFieldValue('personalInfo.stateOfOrigin', selected[0].name);
                                                        if (selected[0].name === 'Abuja Federal Capital Territory') {
                                                            setState('Federal Capital Territory');
                                                        } else {
                                                            setState(selected[0].name);
                                                        }
                                                    }}
                                                    error={errors.personalInfo?.stateOfOrigin}
                                                    passedSelectedItems={values.personalInfo?.stateOfOrigin}
                                                />
                                            </InputDetails>
                                        </FlexContainer>

                                        <FlexContainer>
                                            <InputDetails>
                                                <TextInput
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    label=" Phone Number"
                                                    name="personalInfo.phoneNumber"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.phoneNumber}
                                                    errors={errors.personalInfo?.phoneNumber}
                                                />
                                                <TextInput
                                                    name="personalInfo.homeTown"
                                                    type="text"
                                                    placeholder="Home Town"
                                                    label="Home Town"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.homeTown}
                                                    errors={errors.personalInfo?.homeTown}
                                                />
                                                <TextInput
                                                    name="personalInfo.placeOfBirth"
                                                    type="text"
                                                    placeholder="Place of Birth"
                                                    label="Place of Birth"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.placeOfBirth}
                                                    errors={errors.personalInfo?.placeOfBirth}
                                                />
                                            </InputDetails>
                                            <InputDetails>
                                                {states?.find((state) => state.countryCode === 'NG') ? (
                                                    <Select
                                                        name="personalInfo.localGovernment"
                                                        objProp="name"
                                                        data={NaijaStates?.lgas(state)?.lgas?.map((item) => ({
                                                            name: item,
                                                        }))}
                                                        placeholder="Local Government Area"
                                                        label="Local Government Area"
                                                        onChange={(selected) => {
                                                            setFieldValue(
                                                                'personalInfo.localGovernment',
                                                                selected[0].name,
                                                            );
                                                        }}
                                                        error={errors.personalInfo?.localGovernment}
                                                        passedSelectedItems={values.personalInfo?.localGovernment}
                                                    />
                                                ) : (
                                                    <SelectField disabled>
                                                        <option>
                                                            {values.personalInfo?.localGovernment || 'Local Government'}
                                                        </option>
                                                    </SelectField>
                                                )}

                                                <TextInput
                                                    name="personalInfo.permanentAddress"
                                                    type="text"
                                                    placeholder="Permanent Home Address"
                                                    label="Permanent Home Address"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.permanentAddress}
                                                    errors={errors.personalInfo?.permanentAddress}
                                                />
                                                <TextInput
                                                    name="personalInfo.alternativeHomeAddress"
                                                    type="text"
                                                    placeholder="Alternative Home Address"
                                                    label="Alternative Home Address"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.alternativeHomeAddress}
                                                    errors={errors.personalInfo?.alternativeHomeAddress}
                                                />
                                            </InputDetails>
                                        </FlexContainer>
                                    </SubContainer>
                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Academic Information
                                        </PageTitle>
                                    </Header>
                                    <SubContainer>
                                        <FlexContainer>
                                            <TextInput
                                                type="text"
                                                placeholder={firstDegree}
                                                label={firstDegreeStruct}
                                                value={firstDegree}
                                                disabled
                                                errors={errors}
                                            />
                                            <TextInput
                                                type="text"
                                                placeholder={secondDegree}
                                                label={secondDegreeStruct}
                                                value={secondDegree}
                                                disabled
                                                errors={errors}
                                            />
                                        </FlexContainer>
                                    </SubContainer>

                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Next of Kin Information
                                        </PageTitle>
                                    </Header>
                                    <SubContainer>
                                        <FlexContainer>
                                            <InputDetails>
                                                <TextInput
                                                    name="personalInfo.nextOfKinName"
                                                    type="text"
                                                    placeholder="Name*"
                                                    label="Name*"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.nextOfKinName}
                                                    errors={errors.personalInfo?.nextOfKinName}
                                                />
                                                <TextInput
                                                    name="personalInfo.nextOfKinPhoneNumber"
                                                    type="number"
                                                    placeholder="Phone Number"
                                                    label="Phone Number"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.nextOfKinPhoneNumber}
                                                    errors={errors.personalInfo?.nextOfKinPhoneNumber}
                                                />
                                            </InputDetails>
                                            <InputDetails>
                                                <TextInput
                                                    name="personalInfo.nextOfKinAddress"
                                                    type="text"
                                                    placeholder="Address"
                                                    label="Address"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.nextOfKinAddress}
                                                    errors={errors.personalInfo?.nextOfKinAddress}
                                                />
                                                <TextInput
                                                    name="personalInfo.relationshipWithNextOfKin"
                                                    type="text"
                                                    placeholder="Relationship"
                                                    label="Relationship"
                                                    onChange={handleChange}
                                                    value={values.personalInfo?.relationshipWithNextOfKin}
                                                    errors={errors.personalInfo?.relationshipWithNextOfKin}
                                                />
                                            </InputDetails>
                                        </FlexContainer>
                                    </SubContainer>

                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Referee Information
                                        </PageTitle>
                                    </Header>

                                    <SubContainer className="pb-[5rem]">
                                        <FieldArray
                                            name="reference"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem]">
                                                    {values?.reference?.map((refer, index) => (
                                                        <div key={index}>
                                                            <FlexRowSpaceBetween className="bg-white md:flex md:flex-row flex-col gap-[1.5rem] md:gap-[2rem] w-[100%]">
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                >
                                                                    Referee {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="justify-center gap-[2rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                referee: '',
                                                                                refereeAddress: '',
                                                                                refereePhoneNumber: '',
                                                                                occupation: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="mb-[1rem] hover:cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexRowSpaceBetween>

                                                            <FlexContainer>
                                                                <InputDetails>
                                                                    <TextInput
                                                                        name={`reference.${index}.referee`}
                                                                        type="text"
                                                                        placeholder="Referee Name"
                                                                        label="Referee Name"
                                                                        onChange={handleChange}
                                                                        value={`${values.reference[index]?.referee}`}
                                                                        errors={errors.refer?.referee}
                                                                    />
                                                                    <TextInput
                                                                        name={`reference.${index}.refereeAddress`}
                                                                        type="text"
                                                                        placeholder="Address"
                                                                        label="Address"
                                                                        onChange={handleChange}
                                                                        value={`${values.reference[index]?.refereeAddress}`}
                                                                        errors={errors.refer?.refereeAddress}
                                                                    />
                                                                </InputDetails>
                                                                <InputDetails>
                                                                    <TextInput
                                                                        name={`reference.${index}.refereePhoneNumber`}
                                                                        type="number"
                                                                        placeholder="Phone Number"
                                                                        label="Phone Number"
                                                                        onChange={handleChange}
                                                                        value={`${values.reference[index]?.refereePhoneNumber}`}
                                                                        errors={errors.refer?.refereePhoneNumber}
                                                                    />
                                                                    <TextInput
                                                                        name={`reference.${index}.occupation`}
                                                                        type="text"
                                                                        placeholder="Occupation"
                                                                        label="Occupation"
                                                                        onChange={handleChange}
                                                                        value={values.reference[index]?.occupation}
                                                                        errors={errors.refer?.occupation}
                                                                    />
                                                                </InputDetails>
                                                            </FlexContainer>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>
                                    <div className="flow-root my-[3rem]">
                                        <FlexDiv className="float-right gap-[1.45rem]">
                                            <Button type="button" onClick={() => navigate(-1)}>
                                                Cancel
                                            </Button>
                                            <Button
                                                type="button"
                                                onClick={() => setViewPreview(true)}
                                                color="#fff"
                                                bgColor="#6366F1"
                                            >
                                                Next
                                            </Button>
                                        </FlexDiv>
                                    </div>
                                </MainContainer>
                            </>
                        ) : (
                            <BiodataPreview values={values} onBtnClick={() => setViewPreview(false)} />
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default BioData;
