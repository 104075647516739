import React from 'react';

import { Form, Formik } from 'formik';
// import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import Select from '../../../components/inputs/new-select';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { createExam } from '../../../redux/exams/actions';

import { ValidationSchema } from './ValidationSchema';

const FirstStepBox = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]

`;
const GridRows = tw.div`
    grid
    grid-cols-2
    gap-x-[32px]
    gap-y-[24px]
    mb-10
`;

const examTypes = [
    { name: 'Score only (Exams graded outside the platform)', value: 'Score Only' },
    // { name: 'Text and Upload', value: 'Text and upload' },
    // { name: 'Text Only', value: 'Text only' },
    // { name: 'Upload Only', value: 'Upload only' },
];

const CreateExam = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: courseId } = useParams();
    const { state } = useLocation();

    const { course, session } = state || {};
    const { courseCode, courseTitle } = course || {};

    const { isLoading } = useSelector((state) => state.exams);

    return (
        <>
            <GoBack title={`Exams / ${courseCode}(${courseTitle})`} subTitle="/ Create New Exam" />
            <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                Create New Exam
            </PageTitle>

            <Formik
                initialValues={{
                    title: '',
                    examCategory: '',
                    totalMarks: '',
                    description: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(createExam(courseId, session, values));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => (
                    <Form>
                        <FirstStepBox>
                            <FlexCentredRow className="gap-2 mb-8">
                                <PaperIcon />
                                <Text weight="600" size="1.6rem">
                                    New Exam
                                </Text>
                            </FlexCentredRow>

                            <GridRows>
                                <Select
                                    name="examCategory"
                                    objProp="name"
                                    label="Exam Type"
                                    error={errors.examCategory}
                                    placeholder="Type"
                                    data={examTypes}
                                    onChange={(selected) => {
                                        setFieldValue('examCategory', selected[0].value);
                                    }}
                                />
                                <TextInput
                                    name="title"
                                    label="Exam Title"
                                    errors={errors}
                                    placeholder="Enter Exam Title"
                                    value={values?.title}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    name="totalMarks"
                                    label="Total Marks"
                                    type="number"
                                    errors={errors}
                                    placeholder="Enter Total Marks"
                                    value={values?.totalMarks}
                                    onChange={handleChange}
                                />
                                <TextAreaInput
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    errorMessage={errors.description}
                                    placeholder="Description"
                                    height="8rem"
                                    onChange={handleChange}
                                />
                            </GridRows>
                            <FlexRowEnd className="gap-6">
                                <Button onClick={() => navigate(-1)} border="1px solid #D1D5DB" type="button">
                                    Cancel
                                </Button>
                                <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
                                    Create Exam
                                </LoadingButton>
                            </FlexRowEnd>
                        </FirstStepBox>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateExam;
