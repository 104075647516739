import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { loginUser } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';



const SignInReturningForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.auth.isLoading);

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    staffEmail: '',
                    password: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(loginUser({ ...values, staffEmail: values.staffEmail.toLowerCase() }));
                    if (res) {
                        navigate('/dashboard');
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <h3 className="font-semibold text-[#1F2937] text-[19px] mb-2">Welcome Back!</h3>
                            <p className="font- text-[#9CA3AF] text-[12px] mb-10">
                                Enter your Email and password to Sign In
                            </p>

                            <div className="flex flex-col gap-6">
                                <TextInput
                                    name="staffEmail"
                                    type="text"
                                    placeholder="Email Address"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter Password"
                                    errors={errors}
                                    inputType="password"
                                    onChange={handleChange}
                                    togglePassword={() => setShowPassword((prevState) => !prevState)}
                                />
                            </div>

                            <FlexRowSpaceBetween className="mt-12">
                           
                                <Text
                                    color="#3B82F6"
                                    lineHeight="2.4rem"
                                    className="cursor-pointer"
                                    onClick={() => navigate('/forgot-password')}
                                >
                                    Forgot password?
                                </Text>
                                <LoadingButton loading={isLoading} type="submit">
                                    Sign In
                                </LoadingButton>
                            </FlexRowSpaceBetween>
                  </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SignInReturningForm;
