export const allotedCoursesData = [
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
    {
        number: 1,
        id: 1,
        percentage: 10,
        title: 'Introduction to Engineering Drawing',
        code: 'MEE123',
        units: 3,
        students: 100,
        department: 'Mechanical Engineering',
    },
];
