import React, { useState } from 'react';

import InputContainer from '../../../components/Container/inputcontainer';
import ForgotPasswordForm from '../../../components/forms/forgot-password';
import { PoweredBy } from '../../../components/poweredby';
import SuccessPage from '../../../components/success-page'
import { Container } from '../sign-in-new';


const ForgotPassword = () => {
    const [openSuccess, setOpenSuccess] = useState(false);

    if (openSuccess) {
        return <SuccessPage title='Sent!' desc='Your password has been successfully changed. 
Kindly Sign In to proceed.'  btnText='Sign in' btnAction='/sign-in' />
    }

    return (
        <Container>

            <InputContainer>

                <ForgotPasswordForm setOpenSuccess={setOpenSuccess} />
                <PoweredBy />
            </InputContainer>
        </Container>
    );
};
export default ForgotPassword;
