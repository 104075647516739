/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import { getAllInternalMemo, getAllMails, getAllNotice } from '../../../redux/communications/actions';
//import { addLabelsToData, formatDate } from '../../../utils';

import All from './tabs/All';
import InternalMemo from './tabs/InternalMemo';
import Letters from './tabs/Letters';
import Mail from './tabs/Mail';
import Notice from './tabs/Notice';

const Received = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllInternalMemo());
        dispatch(getAllNotice());
        dispatch(getAllMails());
    }, [dispatch]);

    const handleActionClick = (route) => {
        navigate(route);
        setDropdownOpen(false);
    };

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Received</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown className="ml-[13.15px]" />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        items={[
                            {
                                name: 'Letters',
                                click: () => handleActionClick('letter'),
                            },
                            {
                                name: 'Notices',
                                click: () => handleActionClick('notice'),
                            },
                            {
                                name: 'Mail',
                                click: () => handleActionClick('mail'),
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>All</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Internal Memo</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Letter</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Notice</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Mail</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <All />
                    </CTabPane>
                    <CTabPane>
                        <InternalMemo />
                    </CTabPane>
                    <CTabPane>
                        <Letters />
                    </CTabPane>
                    <CTabPane>
                        <Notice />
                    </CTabPane>
                    <CTabPane>
                        <Mail />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Received;
