import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const resultSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const { LOADING, STOP_LOADING } = resultSlice.actions;

export default resultSlice.reducer;
