import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as SpreadIcon } from '../../../assets/icons/spread.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredRow } from '../../../containers/ScreenContainers';
import { setScoreDistribution } from '../../../redux/result/actions';
import { Button, LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormikTextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;

const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const SetScoreDistribution = ({ show, close, courseId, onFinish }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { sessions } = useSelector((store) => store.admission);

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledClose onClick={close} />

            <FlexCentredRow className="mb-4">
                <SpreadIcon />
                <Text left="1.4rem" size="1.6rem" lineHeight="2.4rem" weight="700">
                    Set Score Distribution
                </Text>
            </FlexCentredRow>
            <Formik
                initialValues={{
                    session: '',
                    attendanceScore: 10,
                    assignmentScore: 5,
                    testScore: 10,
                    examScore: 70,
                    assignmentScoreUsedAsTest: 0,
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const { session, ...otherValues } = values;
                    const { attendanceScore, assignmentScore, testScore, examScore, assignmentScoreUsedAsTest } =
                        otherValues;

                    const totalPercentage =
                        Number(attendanceScore) +
                        Number(assignmentScore) +
                        Number(testScore) +
                        Number(examScore) +
                        Number(assignmentScoreUsedAsTest);

                    if (totalPercentage < 100) return toast.error("Total score percentage can't be less than 100!");
                    if (totalPercentage > 100) return toast.error("Total score percentage can't be greater than 100!");

                    setIsLoading(true);
                    const res = await dispatch(setScoreDistribution(session, courseId, { ...otherValues }));
                    if (res) {
                        toast.success('Score distribution set successfully!');
                        close();
                    }
                    setIsLoading(false);
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <div className="grid gap-8">
                                <Select
                                    label="Session"
                                    name="session"
                                    objProp="admissionYear"
                                    error={errors.session}
                                    placeholder="Session"
                                    data={sessions}
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                    }}
                                />

                                <FormikTextInput
                                    label="Attendance Score (%)"
                                    name="attendanceScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Attendance Score (%)"
                                    onChange={handleChange}
                                />
                                <FormikTextInput
                                    label="Assignment Score (%)"
                                    name="assignmentScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Assignment Score (%)"
                                    onChange={handleChange}
                                />

                                <FormikTextInput
                                    label="Assignment Score Used  as Test(%)"
                                    name="assignmentScoreUsedAsTest"
                                    errors={errors}
                                    type="number"
                                    placeholder="Assignment Score Used as Test (%)"
                                    onChange={handleChange}
                                />
                                <FormikTextInput
                                    label="Test Score (%)"
                                    name="testScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Test Score (%)"
                                    onChange={handleChange}
                                />
                                <FormikTextInput
                                    label="Exam Score (%)"
                                    name="examScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Exam Score (%)"
                                    onChange={handleChange}
                                />
                            </div>
                            <FlexRowEnd className="gap-x-[16px] mt-8">
                                <Button onClick={close} type="button" border="0.5px solid #D1D5DB">
                                    Cancel
                                </Button>

                                <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
                                    Save
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    );
                }}
            </Formik>
        </StyledModal>
    );
};

export default SetScoreDistribution;
