import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import Editor from '../../../components/editor';
import { GoBack } from '../../../components/go-back';
import DateInput from '../../../components/inputs/date-input';
import Select from '../../../components/inputs/new-select';
// import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { createAssignment, createDraftAssignment } from '../../../redux/assignment/actions';
import { formatTime } from '../../../utils';

import { ValidationSchema } from './ValidationSchema';

const FirstStepBox = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]

`;
const GridRows = tw.div`
    grid
    grid-cols-2
    gap-x-[32px]
    gap-y-[24px]
    mb-10
`;

const TitleInput = tw(TextInput)`
    h-[3rem]
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    border-0
`;

// const marks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const assignmentTypes = [
    { name: 'Score only (Assignments graded outside the platform)', value: 'Score Only' },
    // { name: 'Text and Upload', value: 'Text and upload' },
    // { name: 'Text Only', value: 'Text only' },
    // { name: 'Upload Only', value: 'Upload only' },
];

const CreateAssignment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { state } = useLocation();
    const { session, course } = state || {};

    const { isLoading, currentAssignment } = useSelector((state) => state.assignment);

    const [draftId, setDraftId] = useState('');
    const [step, setStep] = useState(1);
    const [questionText, setQuestionText] = useState('');
    const [questionHtml, setQuestionHtml] = useState('');
    const [loading, setLoading] = useState(false);
    const [storeValues, setStoreValues] = useState({});

    const isDisabled = (values) => {
        const { assignmentCategory, deadlineTime, deadlineDate, testOption, totalMarks, title } = values;
        if (!assignmentCategory || !deadlineTime || !deadlineDate || !testOption || !totalMarks || !title) {
            return true;
        }
        setStoreValues(values);
        return false;
    };

    const handleTextChange = (content, delta, source, editor, setFieldValue) => {
        setQuestionText(editor.getText());
        setQuestionHtml(editor.getHTML());
    };

    const handleSaveDraft = async (values, setError) => {
        setLoading(true);
        if (!values.title) return toast.error('You need to enter a title!');
        if (!questionText) return toast.error('You need to enter a question!');

        delete values.type;
        const res = await dispatch(
            createDraftAssignment(id, {
                question: questionHtml,
                title: values.title,
                courseCode: currentAssignment?.courseCode,
            }),
        );
        setLoading(false);
        if (res) {
            navigate(-1);
        }
    };

    useEffect(() => {
        if (currentAssignment) {
            setDraftId(currentAssignment._id);
            setQuestionHtml(currentAssignment?.question);
        }
    }, [currentAssignment]);

    return (
        <>
            <GoBack title={`Assignments`} subTitle={`/ ${course?.courseCode}(${course?.courseTitle})`} />
            <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                Create New Assignment
            </PageTitle>

            <Formik
                initialValues={{
                    // change this to a select option later when needed
                    assignmentType: 'Text only',
                    assignmentCategory: storeValues?.assignmentCategory || '',
                    title: currentAssignment?.title || '',
                    deadlineDate: storeValues?.deadlineDate || '',
                    deadlineTime: storeValues.deadlineTime || '',
                    totalMarks: storeValues.totalMarks || '',
                    testOption: storeValues.testOption || '',
                    // optionalAttachment: [],
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    // if (!questionText && !questionHtml) return toast.error('You need to enter a question!');
                    const formatedDate = values.deadlineDate.slice(0, 10);
                    const formatedTime = formatTime(values.deadlineTime);

                    const res = await dispatch(
                        createAssignment(
                            id,
                            session,
                            {
                                ...values,
                                deadlineDate: formatedDate,
                                deadlineTime: formatedTime,
                                question: questionHtml,
                            },
                            draftId,
                        ),
                    );
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldError, setFieldValue }) => (
                    <Form>
                        {step === 1 && (
                            <FirstStepBox>
                                <FlexCentredRow className="gap-2 mb-8">
                                    <PaperIcon />
                                    <Text weight="600" size="1.6rem">
                                        New Assignment
                                    </Text>
                                </FlexCentredRow>

                                <GridRows>
                                    <Select
                                        name="assignmentCategory"
                                        objProp="name"
                                        label="Assignment Type"
                                        error={errors.assignmentCategory}
                                        placeholder="Type"
                                        data={assignmentTypes}
                                        passedSelectedItems={values.assignmentCategory}
                                        onChange={(selected) => {
                                            setFieldValue('assignmentCategory', selected[0].value);
                                        }}
                                    />
                                    <TextInput
                                        label="Title"
                                        name="title"
                                        type="text"
                                        placeholder="Title"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.title}
                                    />
                                    <DateInput
                                        label="Deadline Date"
                                        name="deadlineDate"
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                    <TextInput
                                        label="Deadline Time"
                                        name="deadlineTime"
                                        type="time"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.deadlineTime}
                                    />
                                    <Select
                                        name="testOption"
                                        // label="Test Option"
                                        label="Continous Assessment"
                                        objProp="name"
                                        passedSelectedItems={values.testOption}
                                        placeholder="Continous Assessment"
                                        data={['Yes', 'No'].map((item) => ({ name: item }))}
                                        onChange={(selected) => {
                                            setFieldValue('testOption', selected[0].name);
                                        }}
                                        error={errors.testOption}
                                    />
                                    {/* <TextAreaInput
                                        name="title"
                                        label="Description"
                                        value={values.title}
                                        errorMessage={errors.title}
                                        placeholder="Description"
                                        height="8rem"
                                        onChange={handleChange}
                                    /> */}
                                    <TextInput
                                        label="Total Marks"
                                        name="totalMarks"
                                        type="number"
                                        placeholder="Total Marks"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.totalMarks}
                                    />
                                </GridRows>
                                <FlexRowEnd className="gap-6">
                                    <Button
                                        onClick={() => navigate(-1)}
                                        border="1px solid #D1D5DB"
                                        className=" border-[#D1D5DB]"
                                        type="button"
                                    >
                                        Cancel
                                    </Button>
                                    {/* <Button */}
                                    <LoadingButton
                                        disabled={isDisabled(values)}
                                        loading={isLoading}
                                        type="submit"
                                        // type="button"
                                        // onClick={() => {
                                        //     // setStep(2);
                                        // }}
                                    >
                                        {/* Next */}
                                        Create Assignment
                                    </LoadingButton>
                                </FlexRowEnd>
                            </FirstStepBox>
                        )}

                        {step === 2 && (
                            <>
                                <FlexRowSpaceBetween className="bg-white rounded-t-2xl px-4 mb-2 py-[1.6rem]">
                                    <div className="w-[70%]">
                                        <TitleInput
                                            name="title"
                                            type="text"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.title}
                                            placeholder="Enter Assignment Title"
                                        />
                                    </div>
                                    <Text color="#4B5563" className="flex flex-wrap">
                                        Deadline: {format(new Date(values.deadlineDate), 'cccc, PP')} at{' '}
                                        {values.deadlineTime}
                                    </Text>
                                </FlexRowSpaceBetween>

                                <Field name="question">
                                    {({ field }) => (
                                        <>
                                            <Editor value={questionHtml} onChange={handleTextChange} />
                                        </>
                                    )}
                                </Field>
                                <FlexRowSpaceBetween className="gap-6 bg-white border-b-2xl p-4 mt-2">
                                    <LoadingButton
                                        onClick={() => handleSaveDraft(values, setFieldError)}
                                        border="1px solid #6366F1"
                                        type="button"
                                        loading={loading}
                                    >
                                        Save as Draft
                                    </LoadingButton>
                                    <FlexDiv className="gap-4">
                                        <Button onClick={() => setStep(1)} border="1px solid #D1D5DB" type="button">
                                            Previous
                                        </Button>
                                        <LoadingButton type="submit" onClick={() => setStep(2)} loading={isLoading}>
                                            Create Assignment
                                        </LoadingButton>
                                    </FlexDiv>
                                </FlexRowSpaceBetween>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateAssignment;
