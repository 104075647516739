import React from 'react';

import { ReactComponent as DropdownGrey } from '../../assets/icons/arrow-down2.svg';
import { RelativeContainer } from '../../containers/ScreenContainers';
import { Button } from '../buttons';
import ActionsPopup from '../popups/actions-popup';

const ModalSelect = ({ onClick, value, placeholder, open, close, items }) => {
    return (
        <RelativeContainer>
            <Button color="#6B7280" border="1px solid #9CA3AF" onClick={onClick}>
                <p className="max-w-[75%] truncate">{value || placeholder}</p>
                <DropdownGrey />
            </Button>
            <ActionsPopup open={open} close={close} items={items} />
        </RelativeContainer>
    );
};

export default ModalSelect;
