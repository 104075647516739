import React from 'react';

import tw from 'twin.macro';

import OnboardingForm from '../../../components/forms/onboarding';
import { PoweredBy } from '../../../components/poweredby';

const Container = tw.section`
  bg-[#EEF2FF]
  flex
  justify-center
  items-center
  min-h-screen
  bg-hero-bg
  bg-no-repeat
  overflow-y-auto
  bg-center
  w-full
  flex-col
`;

export default function Onboarding() {
    return (
        <Container>
            <div className="flex items-center mb-[10.6rem]"></div>
            <OnboardingForm />
            <PoweredBy />
        </Container>
    );
}
