import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    sessions: [],
    activity: [],
};

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ACADEMIC_CALENDAR: (state, action) => {
            state.sessions = action.payload;
        },
        GET_ACADEMIC_ACTIVITIES: (state, action) => {
            state.activity = action.payload;
        },
    },
});

export const { GET_ACADEMIC_CALENDAR, LOADING, STOP_LOADING, GET_ACADEMIC_ACTIVITIES } = calendarSlice.actions;

export default calendarSlice.reducer;
