import tw from 'twin.macro';

import DatePicker from '../../../components/inputs/date-picker';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input';

const GridContainer = tw.div`
bg-white
grid 
md:grid-cols-2  
justify-between
items-center
gap-[2rem] 
md:gap-[2rem] 
w-[100%]
`;

const WorkExperience = ({
    role,
    organization,
    desp,
    yearStart,
    yearEnd,
    orgErrors,
    despErrors,
    roleErrors,
    handleChange,
    roleValue,
    orgValue,
    despValue,
    touched,
    fromvValue,
    toValue,
}) => {
    return (
        <div className="grid gap-[2rem]">
            <GridContainer>
                <DatePicker
                    useYear
                    name={yearStart}
                    placeholder="Starting Year"
                    label="Starting Year"
                    touched={touched}
                    initialValue={fromvValue ? new Date(fromvValue) : null}
                />
                <DatePicker
                    useYear
                    name={yearEnd}
                    placeholder="End Year"
                    label="End Year"
                    touched={touched}
                    initialValue={toValue ? new Date(toValue) : null}
                />

                <TextInput
                    name={role}
                    type="text"
                    placeholder="Role"
                    label="Role"
                    onChange={handleChange}
                    errors={roleErrors}
                    value={roleValue}
                />
                <TextInput
                    name={organization}
                    type="text"
                    placeholder="Organization"
                    label="Organization"
                    onChange={handleChange}
                    errors={orgErrors}
                    value={orgValue}
                />
            </GridContainer>

            <TextAreaInput
                name={desp}
                value={despValue}
                errorMessage={despErrors}
                placeholder="Write a description"
                label="Write a description"
                height="8rem"
                onChange={handleChange}
            />
        </div>
    );
};

export default WorkExperience;
