import React from 'react';

import { CDataTable } from '@coreui/react';
import tw from 'twin.macro';

import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mt-[1.6rem]
`;

const fields = [
    {
        label: 'Course Code',
        key: 'courseCode',
    },

    {
        label: 'Unit',
        key: 'courseUnit',
    },
    {
        label: 'Cont.Assessment',
        key: 'test',
    },
    {
        label: 'Exams',
        key: 'exam',
    },
    {
        label: 'Total',
        key: 'totalScore',
    },
];

const Results = ({
    studentResult,
    session,
    level,
    semester,
    numberOfCourses,
    totalUnits,
    previousCgpa,
    currentGpa,
    cgpa,
}) => {
    return (
        <div>
            <Details>
                <FlexRowSpaceBetween className="px-[1rem]">
                    <Text color="#9CA3AF" size="1.6rem">
                        Session:
                        <Text as="span" weight="500" left="1rem">
                            {session}
                        </Text>
                    </Text>
                    <FlexCentredRow className="gap-[2.4rem]">
                        <Text color="#9CA3AF" size="1.6rem">
                            Level:
                            <Text as="span" weight="500" left="1rem">
                                {level}
                            </Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Semester:
                            <Text as="span" weight="500" left="1rem" transform="capitalize">
                                {semester}
                            </Text>
                        </Text>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
            </Details>
            <div className="shadow-lg rounded-lg bg-[white] px-6 mt-[0.2rem]">
                <>
                    <CDataTable items={studentResult || []} fields={fields} selectable responsive sorter />
                </>

                <FlexRowSpaceBetween className="px-[1rem] pb-[1.7rem]">
                    <Text color="#9CA3AF" size="1.6rem">
                        Total Courses :
                        <Text as="span" weight="500" left="2rem">
                            {numberOfCourses}
                        </Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Total Units:
                        <Text as="span" weight="500" left="2rem">
                            {totalUnits}
                        </Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Previous GPA:
                        <Text as="span" weight="500" left="2rem">
                            {previousCgpa}
                        </Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Current GPA:
                        <Text as="span" weight="500" left="2rem">
                            {currentGpa}
                        </Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        CGPA:
                        <Text as="span" weight="500" left="2rem">
                            {cgpa}
                        </Text>
                    </Text>
                </FlexRowSpaceBetween>
            </div>
        </div>
    );
};

export default Results;
