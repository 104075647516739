// import SuccessPopup from '../components/popups/success';
import { SignInNew, SignInReturning, ForgotPassword, ResetPassword, AcceptStaff } from '../views';


//routes the user can only visit directly when they are not authenticated

export const routes = [
    { path: '/set-password', element: <SignInNew /> },
    { path: '/sign-in', element: <SignInReturning /> },
    // { path: '/sign-up', element: <Onboarding /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset-password/:token', element: <ResetPassword /> },
    { path: '/accept-staff', element: <AcceptStaff /> },
    
];
