import React from 'react';

import { CSelect } from '@coreui/react';
import tw from 'twin.macro';

const Select = tw(CSelect)`
    border-[#E5E7EB]
    h-[3rem]
    lg:h-[4rem]
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    text-[1.6rem]
    text-[#6B7280]
    leading-6
`;

const SelectInput = ({ children, name, onChange, value }) => {
    return (
        <Select
            // id=
            name={name}
            value={value}
            onChange={onChange}
            // disabled={loading}
        >
            {children}
            {/* <option value="">Choose Role</option> */}
            {/* {roles &&
                roles.map((role, index) => (
                    <option key={index} value={role.id}>
                        {role.accountType}
                    </option>
                ))} */}
        </Select>
        // {(errors.accountTypeId && errors.accountTypeId.message.length > 0) && (
        //     <span className={styles.ErrorMessage}>{errors.accountTypeId.message}</span>
        // )}
    );
};

export default SelectInput;
