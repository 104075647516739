import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    courses: [],
    students: [],
    allCourses: [],
    allottedSessions: [],
    allData: {},
};

export const allotedCoursesSlice = createSlice({
    name: 'allotedCourses',
    initialState,
    reducers: {
        SAVE_ALLOTED_COURSES: (state, action) => {
            state.courses = action.payload;
        },
        SAVE_ALL_COURSES: (state, action) => {
            state.allCourses = action.payload;
        },
        SAVE_STUDENTS: (state, action) => {
            state.students = action.payload;
        },
        SAVE_TOTAL: (state, action) => {
            state.allData = action.payload;
        },
        SAVE_SESSIONS: (state, action) => {
            state.allottedSessions = action.payload;
        },
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const {
    SAVE_ALLOTED_COURSES,
    SAVE_ALL_COURSES,
    LOADING,
    STOP_LOADING,
    SAVE_STUDENTS,
    SAVE_TOTAL,
    SAVE_SESSIONS,
} = allotedCoursesSlice.actions;

export default allotedCoursesSlice.reducer;
