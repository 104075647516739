import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import pdfSvg from '../../../assets/icons/pdf.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/three-dots.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload5.svg';
import { ActionsPopup, UploadFile, Share, DownloadFile } from '../../../components';
import { Button } from '../../../components/buttons';
import SearchInput from '../../../components/inputs/search-input';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
  w-full
`;

const ListContainer = tw.div`
    grid
    grid-cols-2
    px-[2.4rem]
    gap-x-[10rem]
    gap-y-[2.8rem]
`;

const Card = tw.div`
    flex
    rounded-[8px]
    pl-4
    pr-3
    // py-1
    border
    items-center
    border-[#C7D2FE]
    border-[.5px]
`;

const CourseResources = () => {
    const navigate = useNavigate();
    // const [isLoading /*setIsLoading*/] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [showDownloadFile, setShowDownloadFile] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [openActions, setOpenActions] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[2rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Learning Resources
                    </PageTitle>
                    <Text size="1.6rem" left="1.4rem">
                        / MEE 405
                    </Text>
                </FlexCentredRow>
                <Button bgColor="#6366F1" color="#fff" onClick={() => setShowUploadFile(true)}>
                    <UploadIcon className="mr-[1.15rem]" />
                    Upload
                </Button>
            </FlexRowSpaceBetween>

            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No resource(s) yet" subtitle="Upload resource(s)">
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setShowUploadFile(true)}>
                            <UploadIcon className="mr-[1.15rem]" />
                            Upload File(s)
                        </Button>
                    </ListEmptyContent>
                </CenteredContainer>
            ) : (
                <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <SearchInput
                            containerWidth="100%"
                            width={'100%'}
                            withRadius
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                        />
                    </FilterContainer>

                    <ListContainer>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((_, idx) => (
                            <FlexColumn>
                                <Card>
                                    <img src={pdfSvg} alt="" />
                                    <FlexColumn className="ml-[2rem]">
                                        <Text align="left" weight="500" size="1.6rem">
                                            Mechanical Engineering.pdf{' '}
                                        </Text>
                                        <Text align="left" weight="500" lineHeight="2.4rem" color="#6B7280">
                                            Size - 156.56 MB
                                        </Text>
                                    </FlexColumn>
                                    <RelativeContainer className="ml-auto mr-0">
                                        <MenuIcon
                                            onClick={() => {
                                                setSelectedId(idx);
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions && selectedId === idx}
                                            items={[
                                                {
                                                    icon: <DownloadIcon />,
                                                    name: 'Download',
                                                    click: () => {
                                                        setShowDownloadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    icon: <SendIcon />,
                                                    name: 'Preview',
                                                    click: () => {
                                                        setOpenActions(false);
                                                        navigate('/resources/preview');
                                                    },
                                                },
                                                {
                                                    icon: <ShareIcon />,
                                                    name: 'Share',
                                                    click: () => {
                                                        setShowShare(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    icon: <DeleteIcon />,
                                                    name: 'Delete',
                                                    click: () => {
                                                        setShowDelete(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </RelativeContainer>
                                </Card>
                                <FlexRowSpaceBetween>
                                    <Text weight="500" color="#9CA3AF">
                                        Uploaded on 24 April 2022.
                                    </Text>
                                    <Text weight="500" color="#9CA3AF">
                                        13:00
                                    </Text>
                                </FlexRowSpaceBetween>
                            </FlexColumn>
                        ))}
                    </ListContainer>
                </div>
            )}
            <DownloadFile show={showDownloadFile} setShow={setShowDownloadFile} />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
            <Share show={showShare} setShow={setShowShare} />

            <ConfirmActionDialogue
                show={showDelete}
                title="Dele"
                subtitle="Are you sure you?"
                close={() => setShowDelete(false)}
                btn2Text="Confirm"
            />
        </div>
    );
};

export default CourseResources;
