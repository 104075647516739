import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-green.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const SendToCAPopup = ({ show, close, title = 'Send to CA', confirmAction, isLoading, btnDisabled, type, version }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowEnd>
                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowEnd>

            <FlexCentredRow className="gap-[1.215rem] mt-[10px]">
                <SendIcon />
                <Text as="h3" size="1.6rem" weight="600">
                    {title}
                </Text>
            </FlexCentredRow>

            <Text align="left" size="1.6rem" bottom="4rem" color="#374151" top="1.6rem">
                {type} results will be sent to CA broadsheet and automatically make this current version the latest
                version, Do you want to continue?
            </Text>

            {/* <Text align="left" size="1.6rem" bottom="2.4rem" color="#374151">
                To further modify the score entries after sending them to CA, a new version would need to be created.
            </Text> */}

            <Text align="left" size="1.4rem" color="#374151">
                Current Version: <span className="font-bold">Version {version}</span>
            </Text>

            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <CancelButton onClick={close} disabled={isLoading}>
                    Cancel
                </CancelButton>
                <LoadingButton loading={isLoading} disabled={btnDisabled || isLoading} onClick={confirmAction}>
                    Send
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default SendToCAPopup;
