import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';


import { createPassword } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';



const SignInNewForm = ({ setOpenSuccess }) => {
    const dispatch = useDispatch();
    const email = window.location.search?.split('&&')[2]?.split('=')[1];
    const isLoading = useSelector((state) => state.auth.isLoading);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    return (
        <>
            <Formik
                initialValues={{
                    staffEmail: email || '',
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const res = await dispatch(
                        createPassword({ ...values, staffEmail: values.staffEmail.toLowerCase() }),
                    );
                    if (res) {
                        setOpenSuccess(true);
                    }
                }}
            >
                {({ errors, handleChange, values }) => (
                    <Form>
                       <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <h3  className="font-semibold text-[#1F2937] text-[19px] mb-2">Welcome!</h3>
                            <p className="font- text-[#9CA3AF] text-[12px] mb-10">Set up your password to continue</p>

                            <TextInput
                                name="staffEmail"
                                type="text"
                                placeholder="Email Address"
                                errors={errors}
                                onChange={handleChange}
                                value={values.staffEmail}
                            />

                            <div className="mb-[1.5rem] mt-[1.5rem] gap-4 grid md:grid-cols-2">
                                <TextInput
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter Password"
                                    errors={errors}
                                    inputType="password"
                                    onChange={handleChange}
                                    togglePassword={() => setShowPassword((prevState) => !prevState)}
                                />
                                <TextInput
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    errors={errors}
                                    inputType="password"
                                    onChange={handleChange}
                                    togglePassword={() => setShowConfirmPassword((prevState) => !prevState)}
                                />
                            </div>

                            <div className="flex justify-between">
                                <div></div>
                                <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                    Create Password
                                </LoadingButton>
                            </div>
                            </div>
                       
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SignInNewForm;
