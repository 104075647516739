import React, { useEffect, useState } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as SpreadIcon } from '../../../assets/icons/spread.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredRow } from '../../../containers/ScreenContainers';
import { getScoreDistribution, editScoreDistribution } from '../../../redux/result/actions';
import { Button, LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormikTextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;

const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const EditScoreDistribution = ({ show, close, courseId }) => {
    const dispatch = useDispatch();
    const { activeSession, sessions } = useSelector((store) => store.admission);

    const [isLoading, setIsLoading] = useState(false);
    const [session, setSession] = useState(activeSession || '');
    const [distributionData, setDistributionData] = useState(null);

    const {
        _id: distributionId,
        attendanceScore,
        assignmentScore,
        testScore,
        examScore,
        assignmentScoreUsedAsTest,
    } = distributionData || {};

    const getDistribution = async () => {
        const res = await dispatch(getScoreDistribution(session, courseId));
        if (res) {
            setDistributionData(res);
        } else {
            setDistributionData({});
        }
    };

    useEffect(() => {
        if (!session) return;
        getDistribution();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    if (!show) return null;

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledClose onClick={close} />

            <FlexCentredRow className="mb-4">
                <SpreadIcon />
                <Text left="1.4rem" size="1.6rem" lineHeight="2.4rem" weight="700">
                    Edit Score Distribution
                </Text>
            </FlexCentredRow>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    session: session || '',
                    attendanceScore: attendanceScore || 0,
                    assignmentScore: assignmentScore || 0,
                    testScore: testScore || 0,
                    examScore: examScore || 0,
                    assignmentScoreUsedAsTest: assignmentScoreUsedAsTest || 0,
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const { session, ...otherValues } = values;
                    const { attendanceScore, assignmentScore, testScore, examScore, assignmentScoreUsedAsTest } =
                        otherValues;

                    const totalPercentage =
                        Number(attendanceScore) +
                        Number(assignmentScore) +
                        Number(testScore) +
                        Number(examScore) +
                        Number(assignmentScoreUsedAsTest);

                    if (totalPercentage < 100) return toast.error("Total score percentage can't be less than 100!");
                    if (totalPercentage > 100) return toast.error("Total score percentage can't be greater than 100!");
                    setIsLoading(true);
                    const res = await dispatch(editScoreDistribution(distributionId, { ...otherValues }));
                    if (res) {
                        toast.success('Score distribution updated successfully!');
                        close();
                    }
                    setIsLoading(false);
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <div className="grid gap-8">
                                <Select
                                    label="Session"
                                    name="session"
                                    objProp="admissionYear"
                                    error={errors.session}
                                    placeholder="Session"
                                    data={sessions}
                                    passedSelectedItems={{ admissionYear: session }}
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                        setSession(selected[0].admissionYear);
                                    }}
                                />

                                <FormikTextInput
                                    label="Attendance Score (%)"
                                    name="attendanceScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Attendance Score (%)"
                                    onChange={handleChange}
                                    value={values.attendanceScore}
                                />
                                <FormikTextInput
                                    label="Assignment Score (%)"
                                    name="assignmentScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Assignment Score (%)"
                                    onChange={handleChange}
                                    value={values.assignmentScore}
                                />
                                <FormikTextInput
                                    label="Assignment Score Used  as Test(%)"
                                    name="assignmentScoreUsedAsTest"
                                    errors={errors}
                                    type="number"
                                    placeholder="Assignment Score Used as Test (%)"
                                    onChange={handleChange}
                                    value={values.assignmentScoreUsedAsTest}
                                />
                                <FormikTextInput
                                    label="Test Score (%)"
                                    name="testScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Test Score (%)"
                                    onChange={handleChange}
                                    value={values.testScore}
                                />
                                <FormikTextInput
                                    label="Exam Score (%)"
                                    name="examScore"
                                    errors={errors}
                                    type="number"
                                    placeholder="Exam Score (%)"
                                    onChange={handleChange}
                                    value={values.examScore}
                                />
                            </div>
                            <FlexRowEnd className="gap-x-[16px] mt-8">
                                <Button onClick={close} type="button" border="0.5px solid #D1D5DB">
                                    Cancel
                                </Button>

                                <LoadingButton
                                    bgColor="#6366F1"
                                    disabled={isLoading}
                                    type="submit"
                                    color="white"
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    );
                }}
            </Formik>
        </StyledModal>
    );
};

export default EditScoreDistribution;
