import { toast } from 'react-hot-toast';

import { axiosInstance, commsAPI } from '../../config';
import { addLabelsToData, formatDate } from '../../utils';

import {
    GET_ALL_MAILS,
    GET_ALL_RECEIVED,
    // GET_NOTICE,
    GET_RECEIVED_INTERNAL_MEMO,
    GET_RECEIVED_NOTICE,
    GET_SENT_MAIL,
    GET_SENT_NOTICE,
    LOADING,
    STOP_LOADING,
} from './slice';

// NOTICE
export const sendLecturerNotice =
    ({ formData, headers }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(`${commsAPI}lecturer-notice`, formData, headers);
            const successMessage = response?.data?.message || 'Notice sent Successfully!';
            toast.success(successMessage);
        } catch (error) {
            const errorMessage = error?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const sendDraftNotice =
    ({ formData, headers }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(`${commsAPI}lecturer-notice/draft`, formData, headers);
            const successMessage = response?.data?.message || 'Notice saved Successfully!';
            toast.success(successMessage);
        } catch (error) {
            const errorMessage = error?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getAllNotice = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}notice/notices/lecturer`);
        if (response.status === 200) {
            dispatch(GET_RECEIVED_NOTICE(response.data.payload));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getLecturerNotice = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}lecturer-notice/${id}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNotice = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}notice/${id}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentNotice = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}lecturer-notice/all/sent`);
        dispatch(GET_SENT_NOTICE(response.data.payload));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// MAILS
export const sendDraftMail =
    ({ formData, headers }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(`${commsAPI}lecturer-mail/draft`, formData, headers);
            const successMessage = response?.data?.message || 'Mail saved Successfully!';
            toast.success(successMessage);
        } catch (error) {
            const errorMessage = error?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const sendMail =
    ({ formData, headers }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(`${commsAPI}lecturer-mail`, formData, headers);
            const successMessage = response?.data?.message || 'Mail sent Successfully!';
            toast.success(successMessage);
        } catch (error) {
            const errorMessage = error?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getAllMails = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}mail/mails/lecturer`);
        if (response.status === 200) {
            dispatch(GET_ALL_MAILS(response.data.payload));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getMail = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}mail/${id}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getLecturerMail = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}lecturer-mail/${id}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentMail = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}lecturer-mail/all/sent`);
        dispatch(GET_SENT_MAIL(response.data.payload));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// Memo
export const getAllInternalMemo = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}internal-memo/memos/lecturer`);
        if (response.status === 200) {
            dispatch(GET_RECEIVED_INTERNAL_MEMO(response.data.payload));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getInternalMemo = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}internal-memo/${id}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSent = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}communication/sent`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllReceived = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}communication/lecturer/received`);
        if (response.status === 200) {
            const combinedArray = addLabelsToData(response?.data?.payload);
            const sortedData = combinedArray?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const newData = sortedData?.map((comms) => {
                const formattedDate = formatDate(comms?.createdAt);
                return {
                    id: comms?._id,
                    subject: comms?.title,
                    message: comms?.content?.length > 50 ? comms?.content?.slice(0, 50) + '...' : comms?.content,
                    label: comms?.label,
                    time: formattedDate,
                    senderUnit: comms?.senderUnitName,
                    sender: comms?.sender,
                };
            });
            dispatch(GET_ALL_RECEIVED(newData));
            return newData;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDraft = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}communication/lecturer/draft`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
