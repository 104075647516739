import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING, SAVED_ACADEMIC_STRUCTURE } from './slice';

export const getSavedAcademicStructure = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}structure/valid_structure`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(SAVED_ACADEMIC_STRUCTURE(data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
