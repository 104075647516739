import React, { useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Upload } from '../../../assets/icons/upload6.svg';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexDiv } from '../../../containers/ScreenContainers';
import { createUploads } from '../../../redux/staff/actions';

import UploadsPreview from './Preview';

const MainContainer = tw.div`
    lg:p-[2.4rem]
    md:p-[5.5rem]
    grid
    grid-cols-2
    gap-8
`;

const InputDetails = tw.div`
    flex
    bg-white
    justify-center
    flex-col
    w-full
    rounded-[8px]
    px-[2rem]
    py-[2rem]
`;

const Header = tw.div`
    flex
    items-center
    my-[auto]
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    py-[1rem]
`;

const Details = tw.div`
    flex
    flex-row
    border
    border-dashed
    border-[#D1D5DB]
    rounded-[8px]
    items-center
    my-[1.5rem]
    px-[.5rem]
    py-[.5rem]
    gap-[.3rem]
`;

const cards = [
    {
        header: 'Recent Passport*',
        subtitle: 'recent passport',
        key: 'passport',
    },
    {
        header: 'Letter of Appointment*',
        subtitle: 'letter of appointment',
        key: 'appointment',
    },
    {
        header: 'Certificate of Origin*',
        subtitle: 'certificate of origin',
        key: 'origin',
    },
    {
        header: 'First Degree Certificate',
        subtitle: 'first degree certificate',
        key: 'firstDegree',
    },
    {
        header: 'Attestation Letter*',
        subtitle: 'attestation letter',
        key: 'attestation',
    },
    {
        header: 'Second Degree Certificate',
        subtitle: 'second degree certificate',
        key: 'secondDegree',
    },
    {
        header: "O'Level Result*",
        subtitle: 'olevel result',
        key: 'olevel',
    },
    {
        header: 'Medical Certificate',
        subtitle: 'medical certificate',
        key: 'medical',
    },
];

const UploadsForm = ({ handleStep }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { staffUploads } = useSelector((store) => store.staff);

    const { passport, appointment, origin, firstDegree, attestation, secondDegree, olevel, medical } = staffUploads;

    const prevUploads = {
        passport: passport[0] || {},
        appointment: appointment[0] || {},
        origin: origin[0] || {},
        firstDegree: firstDegree[0] || {},
        attestation: attestation[0] || {},
        secondDegree: secondDegree[0] || {},
        olevel: olevel[0] || {},
        medical: medical[0] || {},
    };

    const hiddenFileInput = useRef([]);
    const [viewDetails, setViewDetails] = useState(false);

    const handleClick = (key) => {
        hiddenFileInput.current[key].click();
    };

    const handleFileChange = (event, setValue, key) => {
        const file = event.target.files[0];
        if (file) {
            setValue(key, file);
        }
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstDegree: prevUploads?.firstDegree || '',
                    attestation: prevUploads?.attestation || '',
                    secondDegree: prevUploads?.secondDegree || '',
                    origin: prevUploads?.origin || '',
                    olevel: prevUploads?.olevel || '',
                    medical: prevUploads?.medical || '',
                    passport: prevUploads?.passport || '',
                    appointment: prevUploads?.appointment || '',
                }}
                onSubmit={async (values) => {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                        //a minimal attempt at differentiating btw a new file and previous upload
                        // previous uploads have a fileName key instead
                        // only upload new file
                        if (values[key].name) {
                            formData.append(key, values[key]);
                        }
                    });

                    const headers = {
                        'Content-Type': 'multi-part/form-data',
                    };
                    const res = await dispatch(createUploads(formData, headers));
                    if (res) {
                        handleStep();
                        navigate('/dashboard/view-details');
                    }
                }}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        {!viewDetails ? (
                            <>
                                <MainContainer>
                                    {cards.map((item) => (
                                        <InputDetails>
                                            <Header>
                                                <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                                    {item.header}
                                                </PageTitle>
                                            </Header>
                                            <Details>
                                                <Button
                                                    type="Button"
                                                    onClick={() => handleClick(item.key)}
                                                    className="px-[1.6rem] py-[0.8rem] border border-[#E5E7EB] mr-2
                                             shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                                >
                                                    <Upload className="pr-[1.016rem] h-[2.97rem] w-[2.77rem]" />
                                                    <Text color="#1F2937">Upload Files</Text>
                                                </Button>
                                                <Text color="#6B7280">
                                                    {values[item.key]
                                                        ? values[item.key].name || values[item.key].fileName
                                                        : 'Select file'}
                                                </Text>

                                                <input
                                                    type="file"
                                                    name="passport"
                                                    accept=".jpg,.jpeg,.png"
                                                    ref={(el) => (hiddenFileInput.current[item.key] = el)}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) =>
                                                        handleFileChange(e, setFieldValue, item.key, values)
                                                    }
                                                />
                                            </Details>
                                            <Text>Please upload a copy of your {item.subtitle}</Text>
                                            <Text>in PNG,JPEG format, no larger than 3mb in size</Text>
                                        </InputDetails>
                                    ))}
                                </MainContainer>

                                <div className="flow-root my-[3rem]">
                                    <FlexDiv className="float-right gap-[1.45rem]">
                                        <Button type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => setViewDetails(true)} color="#fff" bgColor="#6366F1">
                                            Next
                                        </Button>
                                    </FlexDiv>
                                </div>
                            </>
                        ) : (
                            <UploadsPreview items={values} onBtnClick={() => setViewDetails(false)} />
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UploadsForm;
