import { combineReducers } from '@reduxjs/toolkit';

import academicStructureReducer from './academic-structure/slice';
import admissionsReducer from './admissions/slice';
import allotedCoursesReducer from './alloted-courses/slice';
import assignmentReducer from './assignment/slice';
import attendanceReducer from './attendance/slice';
import authReducer from './auth/slice';
import communicationsReducer from './communications/slice';
import examsReducer from './exams/slice';
import handbookReducer from './handbook/slice';
import resultReducer from './result/slice';
import scheduleReducer from './schedule/slice';
import staffReducer from './staff/slice';
import studentsReducer from './students/slice';
import testsReducer from './tests/slice';
import userReducer from './user/slice';

const combinedReducer = combineReducers({
    academicStructure: academicStructureReducer,
    admission: admissionsReducer,
    allotedCourses: allotedCoursesReducer,
    assignment: assignmentReducer,
    attendance: attendanceReducer,
    auth: authReducer,
    communications: communicationsReducer,
    exams: examsReducer,
    handbook: handbookReducer,
    result: resultReducer,
    schedule: scheduleReducer,
    staff: staffReducer,
    students: studentsReducer,
    tests: testsReducer,
    user: userReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return combinedReducer(state, action);
};