import React from 'react';

import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { Button, LoadingButton } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexDiv } from '../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../utils';

const MainContainer = tw.div`
    grid
    mt-8
    mb-[6.4rem]
    bg-white
`;

const SubContainer = tw.div`
    flex
    flex-col
    px-[2rem]
    py-[2rem]
    gap-[1rem]
    rounded-b-lg
`;

const Header = tw.div`
    lg:p-[2.4rem]
    md:p-[5.5rem]
    p-[2rem]
    bg-white
    flex
    items-center
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    h-[7.6rem]
`;

const GridContainer = tw.div`
    grid
    grid-cols-2
    gap-8
    p-[2rem]
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
`;

const BiodataPreview = ({ values, onBtnClick }) => {
    const { isLoading, staff } = useSelector((store) => store.staff);

    const { firstDegreeStruct, secondDegreeStruct, firstDegree, secondDegree, staffNumber } = staff || {};

    const personalInfoItems = [
        {
            title: 'Title',
            subtitle: values?.personalInfo?.title,
        },
        {
            title: 'Gender',
            subtitle: values?.personalInfo?.gender,
        },
        {
            title: 'First Name',
            subtitle: capitalizeFirstLetter(staff?.firstName),
        },
        {
            title: 'Designation',
            subtitle: capitalizeFirstLetter(staff?.designation),
        },
        {
            title: 'Surname',
            subtitle: capitalizeFirstLetter(staff?.surname),
        },
        {
            title: 'Marital Status',
            subtitle: values?.personalInfo?.maritalStatus,
        },
        {
            title: 'Email',
            subtitle: staff?.staffEmail,
        },
        {
            title: 'Nationality',
            subtitle: values?.personalInfo?.nationality,
        },
        {
            title: 'Staff Id',
            subtitle: staffNumber || '',
        },
        {
            title: 'National Identification Number (NIN)',
            subtitle: values?.personalInfo?.nin || '',
        },
        {
            title: 'Religion',
            subtitle: values?.personalInfo?.religion,
        },
        {
            title: 'Date of Birth',
            subtitle: values?.personalInfo?.dateOfBirth
                ? new Date(values?.personalInfo?.dateOfBirth).toLocaleDateString()
                : '',
        },
        {
            title: 'State of Origin',
            subtitle: values?.personalInfo?.stateOfOrigin,
        },
        {
            title: 'Phone Number',
            subtitle: values?.personalInfo?.phoneNumber,
        },
        {
            title: 'Local Government',
            subtitle: values?.personalInfo?.localGovernment,
        },
        {
            title: 'Home Town',
            subtitle: capitalizeFirstLetter(values?.personalInfo?.homeTown),
        },
        {
            title: 'Permanent Home Address',
            subtitle: capitalizeFirstLetter(values?.personalInfo?.permanentAddress),
        },
        {
            title: 'Place of Birth',
            subtitle: capitalizeFirstLetter(values?.personalInfo?.placeOfBirth),
        },
    ];

    const degreeInfo = [
        {
            title: firstDegreeStruct,
            subtitle: firstDegree,
        },
        {
            title: secondDegreeStruct,
            subtitle: secondDegree,
        },
    ];

    const nextOfKinInfo = [
        {
            title: 'Name',
            subtitle: capitalizeFirstLetter(values?.personalInfo?.nextOfKinName),
        },

        {
            title: 'Address',
            subtitle: capitalizeFirstLetter(values?.personalInfo?.nextOfKinAddress),
        },
        {
            title: 'Phone Number',
            subtitle: values?.personalInfo?.nextOfKinPhoneNumber,
        },
        {
            title: 'Relationship',
            subtitle: values?.personalInfo?.relationshipWithNextOfKin,
        },
    ];

    const getImageUrl = (url) => {
        if (typeof url === 'string') {
            return url;
        }
        return URL.createObjectURL(url);
    };

    return (
        <div>
            <MainContainer>
                <Header>
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Personal Information
                    </PageTitle>
                </Header>
                <SubContainer>
                    {values?.profilePicture?.url ? (
                        <img
                            src={getImageUrl(values?.profilePicture?.url)}
                            alt="profile"
                            className="rounded-full w-[12.1rem] h-[12.1rem]"
                        />
                    ) : (
                        <Avatar
                            name={`${staff?.surname} ${staff?.firstName} ${staff?.otherName}`}
                            size="150"
                            textSizeRatio={1.75}
                            className="rounded-full"
                        />
                    )}

                    <GridContainer>
                        {personalInfoItems?.map((item, index) => (
                            <div key={index}>
                                <GreyText>{item.title}</GreyText>
                                <DarkText>{item.subtitle}</DarkText>
                            </div>
                        ))}
                    </GridContainer>
                </SubContainer>

                <Header>
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Academic Information
                    </PageTitle>
                </Header>

                <GridContainer>
                    {degreeInfo?.map((item, index) => (
                        <div key={index}>
                            <GreyText>{item.title}</GreyText>
                            <DarkText>{item.subtitle}</DarkText>
                        </div>
                    ))}
                </GridContainer>

                <Header>
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Next Of Kin Information
                    </PageTitle>
                </Header>

                <GridContainer>
                    {nextOfKinInfo?.map((item, index) => (
                        <div key={index}>
                            <GreyText>{item.title}</GreyText>
                            <DarkText>{item.subtitle}</DarkText>
                        </div>
                    ))}
                </GridContainer>

                <Header>
                    <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                        Referee's Information
                    </PageTitle>
                </Header>
                {values?.reference?.map((refer, _id) => (
                    <GridContainer>
                        <div>
                            <GreyText>Name</GreyText>
                            <DarkText>{refer.referee}</DarkText>
                        </div>
                        <div>
                            <GreyText>Phone Number</GreyText>
                            <DarkText>{refer.refereePhoneNumber}</DarkText>
                        </div>
                        <div>
                            <GreyText>Address</GreyText>
                            <DarkText>{refer.refereeAddress}</DarkText>
                        </div>
                        <div>
                            <GreyText>Occupation</GreyText>
                            <DarkText>{refer.occupation}</DarkText>
                        </div>
                    </GridContainer>
                ))}
            </MainContainer>

            <div className="flow-root my-[3rem]">
                <FlexDiv className="float-right gap-[1.45rem]">
                    <Button type="button" onClick={onBtnClick}>
                        Back
                    </Button>
                    <LoadingButton type="submit" loading={isLoading}>
                        Submit
                    </LoadingButton>
                </FlexDiv>
            </div>
        </div>
    );
};

export default BiodataPreview;
