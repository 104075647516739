import React, { useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { SecondaryButton } from '../../../../components/buttons';
import { AllFilter, SingleFilter } from '../../../../components/filter-select';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
import UploadFile from '../../../../components/upload-file';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../../containers/ScreenContainers';
import { getLabelBadge } from '../../../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Subject',
        key: 'subject',
    },
    {
        label: 'Message',
        key: 'message',
    },
    {
        label: 'Type',
        key: 'status',
    },
    {
        label: 'Time Stamp',
        key: 'time',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const All = () => {
    const navigate = useNavigate();
    const { isLoading, allReceived } = useSelector((store) => store.communications);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);

    const totalPages = Math.ceil(allReceived?.length / itemsPerPage);

    const handleClick = (item) => {
        if (item.label === 'Internal Memo') {
            navigate(`/communications/internal-memo/${item.id}`);
        } else if (item.label === 'Notice') {
            navigate(`/communications/notice/${item.id}`);
        } else if (item.label === 'Mail') {
            navigate(`/communications/mail/${item.id}`);
        }
    };

    return (
        <>
            <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                        <SingleFilter name="Time Stamp" />
                        <SingleFilter name="Sender" withRadius />
                    </FlexCentredRow>

                    <FlexCentredRow>
                        <SecondaryButton>Reset</SecondaryButton>
                        <AllFilter />
                    </FlexCentredRow>
                </FilterContainer>
                <CDataTable
                    items={allReceived || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent title="No Communications Received"></ListEmptyContent>
                        </CenteredContainer>
                    }
                    onRowClick={(item) => handleClick(item)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),

                        subject: (item) => <td style={{ fontWeight: '600' }}>{item.subject}</td>,
                        status: (item) => <td>{getLabelBadge(item.label)}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[5, 10, 15, 20]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default All;
