import React, { useEffect } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { GoBack } from '../../../components/go-back';
import { Loader } from '../../../components/loader';
import PDFPreview from '../../../components/pdf-preview';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { getHandbookById } from '../../../redux/handbook/action';

const MainContainer = tw.div`
    mt-8
    mb-[6.4rem]
    rounded-lg
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;
const PdfDetails = tw.div`
    bg-white
    rounded-lg
    mt-[1rem]
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const HandbookDetails = () => {
    const { id } = useParams();
    const {
        state: { session, details },
    } = useLocation();

    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.handbook);

    useEffect(() => {
        dispatch(getHandbookById(id, session));
    }, [dispatch, id, session]);

    const handleDownload = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(objectUrl);
        } catch (error) {
            toast.error('Error occurred during download:', error);
        }
    };

    if (isLoading) return <Loader />;

    return (
        <div>
            <div className="mb-[3.2rem]">
                <GoBack title="Handbook" subTitle={`/ ${details?.title}`}></GoBack>
            </div>
            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexCentredRow>
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                    </FlexCentredRow>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Title</GreyText>
                                <DarkText>{details?.title}</DarkText>
                            </FlexCol>
                        </RowContainer>

                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Description</GreyText>
                                <DarkText>{details?.description}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <DownloadIcon
                                onClick={() => {
                                    const downloadUrl = details?.handbookUpload?.[0]?.url;
                                    const fileName = details?.handbookUpload?.[0]?.fileName;

                                    if (downloadUrl && fileName) {
                                        handleDownload(downloadUrl, fileName);
                                    }
                                }}
                            />
                        </RowContainer>
                    </BasicContent>
                </Details>
                <PdfDetails>
                    <PDFPreview url={details?.handbookUpload?.[0]?.url} pdfTitle={details?.title} />
                </PdfDetails>
            </MainContainer>
        </div>
    );
};

export default HandbookDetails;
