import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ChevronIcon } from '../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { UploadFile } from '../../../components';
import { Button, SecondaryButton } from '../../../components/buttons';
import { AllFilter, SingleFilter } from '../../../components/filter-select';
import AddNewCourseForm from '../../../components/forms/add-new-course';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import ListEmptyContent from '../../../components/list-empty';
import { Text } from '../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../containers/ScreenContainers';
import { courseDetailsData } from '../../../data/courseDetails';

const fields = [
    {
        key: 'serial',
        label: 'S/N',
    },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Course Title',
        key: 'courseTitle',
    },
    {
        label: 'Units',
        key: 'unit',
    },
    {
        label: 'Status',
        key: 'status',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const CourseList = () => {
    const navigate = useNavigate();
    const [isLoading /*setIsLoading*/] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(courseDetailsData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <div>
            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No available courses yet" subtitle="Create Semester Courses">
                        <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                            <Button bgColor="#10B981" color="#fff" onClick={() => setShowForm(true)}>
                                <Circularplus className="mr-[1.15rem]" />
                                Create New
                            </Button>
                        </CenteredContainer>
                    </ListEmptyContent>
                </CenteredContainer>
            ) : (
                <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <FlexDiv>
                            <FlexCentredRow className="mr-[5rem]">
                                <Text color="#6366F1" weight="500">
                                    2019/2022
                                </Text>
                                <ChevronIcon className="ml-[0.9rem]" />
                            </FlexCentredRow>
                            <FlexCentredRow>
                                <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                                <SingleFilter name="Department" />
                                <SingleFilter name="Level" withRadius />
                            </FlexCentredRow>
                        </FlexDiv>

                        <FlexCentredRow>
                            <SecondaryButton>Reset</SecondaryButton>
                            <AllFilter />
                        </FlexCentredRow>
                    </FilterContainer>
                    <CDataTable
                        items={courseDetailsData || []}
                        fields={fields}
                        // striped
                        selectable
                        responsive
                        itemsPerPage={itemsPerPage}
                        activePage={currentPage || 1}
                        clickableRows
                        loading={isLoading}
                        onRowClick={(item) => navigate(`/allotted-courses/${item.id}`)}
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox />
                                </td>
                            ),
                            serial: (_, idx) => <td>{idx + 1}</td>,
                            status: (item) => (
                                <td>
                                    {item.status === 'Compulsory' ? (
                                        <StatusContainer>
                                            <Text color="#EF4444" size="1.2rem">
                                                {item.status}
                                            </Text>
                                        </StatusContainer>
                                    ) : (
                                        <StatusContainer bgColor="#F5F3FF">
                                            <Text color="#8B5CF6" size="1.2rem">
                                                {item.status}
                                            </Text>
                                        </StatusContainer>
                                    )}
                                </td>
                            ),
                        }}
                    />
                    <ItemsPerPageCont>
                        <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                        <CPagination
                            activePage={currentPage || 1}
                            onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                            pages={totalPages || 1}
                            align="end"
                        />
                    </ItemsPerPageCont>
                </div>
            )}
            {showForm && <AddNewCourseForm show={showForm} setShow={setShowForm} />}
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default CourseList;
