import React, { useState } from 'react';

//import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import CreateSchedule from '../../components/forms/create-a-schedule';
import ModalOverlay from '../../components/popups/create-schedule';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../containers/ScreenContainers';

const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
`;
// before:focus:bg-[#6366F1]
const Card = tw.div`
    h-auto
    bg-[#C7D2FE]
    p-[1.6rem]
    rounded-lg
    relative
    hover:
    before:content-['']
    before:w-[8px]
    before:h-[8px]
    before:bg-[#E5E7EB]
    before:absolute
    before:rounded-full
    before:left-[-4.3rem]
    before:top-[50%]
    before:-translate-y-1/2
    hover:before:bg-[#6366F1]
    focus:before:bg-[#6366F1]
`;
const InputDetails = tw.div`
  flex
  flex-col
  gap-y-[8px]
  border-l
  border-[#E5E7EB]
  pl-[3.9rem]
  ml-[18.3rem]
  mb-[2.3rem]
`;
const TimelineContainer = tw.div`


`;

const CalenderDoc = ({
    mondayTimetable,
    tuesdayTimetable,
    wednesdayTimetable,
    thursdayTimetable,
    fridayTimetable,
    heading1,
    heading2,
    heading3,
    heading4,
    heading5,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => setOpenModal(false);

    return (
        <>
            <MainContainer>
                <TimelineContainer>
                    <PageTitle className="ml-[4rem]" align="left" size="2.8rem" bottom="1.2rem" top="2.5rem">
                        {heading1 || 'Monday'}
                    </PageTitle>
                    {mondayTimetable?.map((timetable) => (
                        <InputDetails>
                            <Card>
                                <Text
                                    className="absolute -left-[18rem] top-[50%] -translate-y-[40%]"
                                    align="left"
                                    lineHeight="2.4rem"
                                >
                                    {` ${new Date(timetable.startTime).toTimeString().slice(0, 5)} - ${new Date(
                                        timetable.endTime,
                                    )
                                        .toTimeString()
                                        .slice(0, 5)}`}
                                </Text>
                                <FlexRowSpaceBetween>
                                    <Text className="font-semibold text-[1.4rem]">
                                        {timetable.course} -{' '}
                                        <span className="font-normal text-[1.4rem]">{timetable.courseTitle}</span>
                                    </Text>
                                    <Text>{timetable.venue}</Text>
                                </FlexRowSpaceBetween>
                            </Card>
                        </InputDetails>
                    ))}
                </TimelineContainer>
                <TimelineContainer>
                    <PageTitle className="ml-[4rem]" align="left" size="2.8rem" bottom="1.2rem" top="2.5rem">
                        {heading2 || 'Tuesday'}
                    </PageTitle>
                    {tuesdayTimetable?.map((timetable) => (
                        <InputDetails>
                            <Card>
                                <Text
                                    className="absolute -left-[18rem] top-[50%] -translate-y-[40%]"
                                    align="left"
                                    lineHeight="2.4rem"
                                >
                                    {` ${new Date(timetable.startTime).toTimeString().slice(0, 5)} - ${new Date(
                                        timetable.endTime,
                                    )
                                        .toTimeString()
                                        .slice(0, 5)}`}
                                </Text>
                                <FlexRowSpaceBetween>
                                    <Text className="font-semibold text-[1.4rem]">
                                        {timetable.course} -{' '}
                                        <span className="font-normal text-[1.4rem]">{timetable.courseTitle}</span>
                                    </Text>
                                    <Text>{timetable.venue}</Text>
                                </FlexRowSpaceBetween>
                            </Card>
                        </InputDetails>
                    ))}
                </TimelineContainer>
                <TimelineContainer>
                    <PageTitle className="ml-[4rem]" align="left" size="2.8rem" bottom="1.2rem" top="2.5rem">
                        {heading3 || 'Wednesday'}
                    </PageTitle>
                    {wednesdayTimetable?.map((timetable) => (
                        <InputDetails>
                            <Card>
                                <Text
                                    className="absolute -left-[18rem] top-[50%] -translate-y-[40%]"
                                    align="left"
                                    lineHeight="2.4rem"
                                >
                                    {` ${new Date(timetable.startTime).toTimeString().slice(0, 5)} - ${new Date(
                                        timetable.endTime,
                                    )
                                        .toTimeString()
                                        .slice(0, 5)}`}
                                </Text>
                                <FlexRowSpaceBetween>
                                    <Text className="font-semibold text-[1.4rem]">
                                        {timetable.course} -{' '}
                                        <span className="font-normal text-[1.4rem]">{timetable.courseTitle}</span>
                                    </Text>
                                    <Text>{timetable.venue}</Text>
                                </FlexRowSpaceBetween>
                            </Card>
                        </InputDetails>
                    ))}
                </TimelineContainer>
                <TimelineContainer>
                    <PageTitle className="ml-[4rem]" align="left" size="2.8rem" bottom="1.2rem" top="2.5rem">
                        {heading4 || 'Thursday'}
                    </PageTitle>
                    {thursdayTimetable?.map((timetable) => (
                        <InputDetails>
                            <Card>
                                <Text
                                    className="absolute -left-[18rem] top-[50%] -translate-y-[40%]"
                                    align="left"
                                    lineHeight="2.4rem"
                                >
                                    {` ${new Date(timetable.startTime).toTimeString().slice(0, 5)} - ${new Date(
                                        timetable.endTime,
                                    )
                                        .toTimeString()
                                        .slice(0, 5)}`}
                                </Text>
                                <FlexRowSpaceBetween>
                                    <Text className="font-semibold text-[1.4rem]">
                                        {timetable.course} -{' '}
                                        <span className="font-normal text-[1.4rem]">{timetable.courseTitle}</span>
                                    </Text>
                                    <Text>{timetable.venue}</Text>
                                </FlexRowSpaceBetween>
                            </Card>
                        </InputDetails>
                    ))}
                </TimelineContainer>
                <TimelineContainer>
                    <PageTitle className="ml-[4rem]" align="left" size="2.8rem" bottom="1.2rem" top="2.5rem">
                        {heading5 || 'Friday'}
                    </PageTitle>
                    {fridayTimetable?.map((timetable) => (
                        <InputDetails>
                            <Card>
                                <Text
                                    className="absolute -left-[18rem] top-[50%] -translate-y-[40%]"
                                    align="left"
                                    lineHeight="2.4rem"
                                >
                                    {` ${new Date(timetable.startTime).toTimeString().slice(0, 5)} - ${new Date(
                                        timetable.endTime,
                                    )
                                        .toTimeString()
                                        .slice(0, 5)}`}
                                </Text>
                                <FlexRowSpaceBetween>
                                    <Text className="font-semibold text-[1.4rem]">
                                        {timetable.course} -{' '}
                                        <span className="font-normal text-[1.4rem]">{timetable.courseTitle}</span>
                                    </Text>
                                    <Text>{timetable.venue}</Text>
                                </FlexRowSpaceBetween>
                            </Card>
                        </InputDetails>
                    ))}
                </TimelineContainer>
            </MainContainer>
            <ModalOverlay openModal={openModal} onClose={closeModal}>
                <CreateSchedule onClose={closeModal} />
            </ModalOverlay>
        </>
    );
};

export default CalenderDoc;
