import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as ExternalLink } from '../../../assets/icons/external-Link.svg';
import { ReactComponent as ThreeDots } from '../../../assets/icons/three-dots.svg';
import { ReactComponent as UploadedFile } from '../../../assets/icons/uploaded-file.svg';
import { ActionsPopup } from '../../../components';
import { Button, LoadingButton } from '../../../components/buttons';
import { Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { handleImagePreview } from '../../../utils';

const GridContainer = tw.div`
    grid
    grid-cols-2
    mt-[2.8rem]
`;

const ImageDetails = tw.div`
    mr-[2.8rem]
    mb-[2.8rem]
`;

const cardItems = [
    {
        header: 'Recent Passport*',
        key: 'passport',
    },
    {
        header: 'Letter of Appointment*',
        key: 'appointment',
    },
    {
        header: 'Certificate of Origin*',
        key: 'origin',
    },
    {
        header: 'First Degree Certificate',
        key: 'firstDegree',
    },
    {
        header: 'Attestation Letter*',
        key: 'attestation',
    },
    {
        header: 'Second Degree Certificate',
        key: 'secondDegree',
    },
    {
        header: "O'Level Result*",
        key: 'olevel',
    },
    {
        header: 'Medical Certificate',
        key: 'medical',
    },
];

const UploadsPreview = ({ items, onBtnClick }) => {
    const { isLoading } = useSelector((store) => store.staff);

    const [selectedKey, setSelectedKey] = useState('');

    return (
        <>
            <GridContainer>
                {cardItems?.map((item) => {
                    const currentFileObj = items[item.key];
                    const fileName = currentFileObj?.name || currentFileObj?.fileName;

                    return (
                        <ImageDetails key={item.key}>
                            <FlexCentredRow className="bg-white py-10 pl-10 text-center border-b-2">
                                <Text weight="600" size="1.6rem" lineHeight="2.8rem">
                                    {item.header}
                                </Text>
                            </FlexCentredRow>
                            <FlexRowSpaceBetween className="bg-white p-10 items-center">
                                <FlexCentredRow>
                                    <UploadedFile className="mr-[2.308rem]" />

                                    <FlexCentredCol>
                                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                            {fileName?.length > 10 ? fileName.substring(0, 10) + '...' : fileName}
                                        </Text>
                                        <Text
                                            weight="500"
                                            align="left"
                                            color="#6B7280"
                                            size="1.4rem"
                                            lineHeight="2.4rem"
                                        >
                                            Size - {currentFileObj.size}kb
                                        </Text>
                                    </FlexCentredCol>
                                </FlexCentredRow>

                                <RelativeContainer>
                                    <ThreeDots className="cursor-pointer" onClick={() => setSelectedKey(item.key)} />
                                    <ActionsPopup
                                        open={selectedKey === item.key}
                                        close={() => setSelectedKey('')}
                                        items={[
                                            {
                                                icon: <ExternalLink fill="#1F2937" />,
                                                name: 'Preview',
                                                click: () => {
                                                    // With fileName assumes that it's an already uploaded file
                                                    // The else is a new file with 'name'
                                                    if (currentFileObj?.fileName) {
                                                        handleImagePreview(currentFileObj?.url);
                                                    } else {
                                                        const url = URL.createObjectURL(currentFileObj);
                                                        handleImagePreview(url);
                                                    }
                                                },
                                            },
                                        ]}
                                    />
                                </RelativeContainer>
                            </FlexRowSpaceBetween>
                        </ImageDetails>
                    );
                })}
            </GridContainer>

            <div className="flow-root my-[3rem]">
                <FlexDiv className="float-right gap-[1.45rem]">
                    <Button type="button" onClick={onBtnClick}>
                        Back
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        Submit
                    </LoadingButton>
                </FlexDiv>
            </div>
        </>
    );
};

export default UploadsPreview;
