// import React, { useState } from "react";

// const SubmitDialogue= () => {
//   const [selectedOption, setSelectedOption] = useState("");

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <div>
//       <label>
//         <input
//           type="radio"
//           name="option"
//           value="option1"
//           checked={selectedOption === "option1"}
//           onChange={handleOptionChange}
//           style={{ backgroundColor: selectedOption === "option1" ? "blue" : "white" }}
//         />
//         All Results
//       </label>


//       <label>
//         <input
//           type="radio"
//           name="option"
//           value="option2"
//           checked={selectedOption === "option2"}
//           onChange={handleOptionChange}
//           style={{ backgroundColor: selectedOption === "option2" ? "blue" : "white" }}
//         />
//         Only Selected
//       </label>
//     </div>
//   );
// }

// export default SubmitDialogue;

import React, {useState} from 'react';

import { CModal } from '@coreui/react';
//import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { /*PageTitle*/ Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredRow } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import SuccessPopup from '../success';

//import { ValidationSchema } from './ValidationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;


const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const Texxt = styled.p`
  font-size: ${({ size }) => size || "1.4rem"};
  font-weight: ${({ weight }) => weight || "400"};
  color: ${({ color }) => color || "#1f2937"};
  line-height: ${({ lineHeight }) => lineHeight || "2.8rem"};
  text-align: ${({ align }) => align || ""};
  margin-top: ${({ top }) => top || null};
  margin-bottom: ${({ bottom }) => bottom || 0};
  margin-left: ${({ left }) => left || 0};
  margin-right: ${({ right }) => right || 0};
  text-transform: ${({ transform }) => transform || "none"};
`;


const SubmitDialogue = ({ show, close, id, onFinish }) => {
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const [openSuccess, setShowOpenSuccess] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledClose onClick={close} />
            <Texxt bottom="1.6rem" size="1.8rem" weight="700">
            Submit Results
            </Texxt>
             <Texxt bottom="2.4rem" size="1.6rem" lineHeight="2.4rem" weight="400">
             Select your preference
            </Texxt>

       <FlexCentredRow className="mb-[2.7em]">
            <Texxt>
        <input
           type="radio"
           name="option"
           value="option1"
           checked={selectedOption === "option1"}
           onChange={handleOptionChange}
           style={{ backgroundColor: selectedOption === "option1" ? "blue" : "white" }}
        />
        <span style={{ display: 'inline-block', marginLeft: '5px' }}>All Results</span>
      </Texxt>


      <Texxt left="6.6rem">
        <input
           type="radio"
           name="option"
           value="option2"
           checked={selectedOption === "option2"}
          onChange={handleOptionChange}
         style={{ backgroundColor: selectedOption === "option2" ? "blue" : "white" }}
        />
        <span style={{ display: 'inline-block', marginLeft: '5px' }}>Only Selected</span>
       </Texxt>
       </FlexCentredRow>

       <FlexCentredRow className="mb-[2.7em]" >
       <Text>Session:<span style={{  fontWeight: 900, marginLeft: '3px' }}>2021/2202</span>
       </Text>
       <Text left="1.6rem">Semester: 
       <span style={{  fontWeight: 900, marginLeft: '3px' }}>1st</span>
       </Text>
       <Text  left="1.6rem">Version:
        <span style={{  fontWeight: 900, marginLeft: '3px' }}>1.0</span>
        </Text>
       </FlexCentredRow>

       <FlexRowEnd className="gap-x-[16px]">
                                <Button onClick={close} border="0.5px solid #D1D5DB">
                                    Cancel
                                </Button>
                                <Button
                                onClick={() => setShowOpenSuccess(true)}
                                    bgColor="#6366F1"
                                    color="#fff"
                                >
                                    Submit Results
                                </Button>
                            </FlexRowEnd>

            <SuccessPopup
                show={openSuccess}
                btnAction={() => navigate('/allotted-courses')}
                title="Done!"
                subtitle="Result has been Submitted"
                btnText="Close"
            />
        </StyledModal>     
    );
};

export default SubmitDialogue;



