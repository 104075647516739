import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as ChevronIcon } from '../../assets/icons/arrow-down-blue.svg';
import { SecondaryButton } from '../../components/buttons';
import { AllFilter, SingleFilter } from '../../components/filter-select';
import Checkbox from '../../components/inputs/checkbox';
import SearchInput from '../../components/inputs/search-input';
import ItemsPerPage from '../../components/items-per-page';
import ListEmptyContent from '../../components/list-empty';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../containers/ScreenContainers';
import { allotedCoursesData } from '../../data/allotedCourses';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        key: 'serial',
        label: 'S/N',
    },
    {
        label: 'Course Title',
        key: 'title',
    },
    {
        label: 'Course Code',
        key: 'code',
    },
    {
        label: 'Units',
        key: 'units',
    },
    {
        label: 'Registered Students',
        key: 'students',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const LearningResources = () => {
    const navigate = useNavigate();
    const [isLoading /*setIsLoading*/] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(allotedCoursesData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <div>
            <FlexRowSpaceBetween>
                <PageTitle>Learning Resources</PageTitle>
            </FlexRowSpaceBetween>

            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No resource(s) yet" subtitle="Upload resource(s)"></ListEmptyContent>
                </CenteredContainer>
            ) : (
                <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <FlexDiv>
                            <FlexCentredRow className="mr-[5rem]">
                                <Text color="#6366F1" weight="500">
                                    2019/2022
                                </Text>
                                <ChevronIcon className="ml-[0.9rem]" />
                            </FlexCentredRow>
                            <FlexCentredRow>
                                <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                                <SingleFilter name="Level" color="#1F2937" border="#F3F4F6" withRadius />
                            </FlexCentredRow>
                        </FlexDiv>

                        <FlexCentredRow>
                            <SecondaryButton>Reset</SecondaryButton>
                            <AllFilter />
                        </FlexCentredRow>
                    </FilterContainer>
                    <CDataTable
                        items={allotedCoursesData || []}
                        fields={fields}
                        // striped
                        selectable
                        responsive
                        itemsPerPage={itemsPerPage}
                        activePage={currentPage || 1}
                        clickableRows
                        loading={isLoading}
                        onRowClick={(item) => navigate(`/resources/${item.id}`)}
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox />
                                </td>
                            ),
                            serial: (_, idx) => <td>{idx + 1}</td>,
                            title: (item) => <td style={{ color: '#2563EB' }}>{item.title}</td>,
                        }}
                    />
                    <ItemsPerPageCont>
                        <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                        <CPagination
                            activePage={currentPage || 1}
                            onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                            pages={totalPages || 1}
                            align="end"
                        />
                    </ItemsPerPageCont>
                </div>
            )}
        </div>
    );
};

export default LearningResources;
