import { styled } from 'twin.macro';

export const PageTitle = styled.h1`
    font-size: ${({ size }) => size || '2.3rem'};
    font-weight: ${({ weight }) => weight || '600'};
    color: ${({ color }) => color || '#1f2937'};
    line-height: ${({ lineHeight }) => lineHeight || '3.4rem'};
    text-align: ${({ align }) => align || 'center'};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
`;

export const Text = styled.p`
    font-size: ${({ size }) => size || '1.4rem'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#1f2937'};
    line-height: ${({ lineHeight }) => lineHeight || '2.8rem'};
    text-align: ${({ align }) => align || 'center'};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
    margin-left: ${({ left }) => left || 0};
    text-transform: ${({ transform }) => transform || 'none'};
    text-wrap: ${({ wrap }) => wrap || null};
`;
