import React, { useEffect, useState } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { allotMark } from '../../../redux/assignment/actions';
import { Button } from '../../buttons';
import Select from '../../inputs/new-select';

import { ValidationSchema } from './ValidationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;

const GridRows = tw.div`
    grid
    grid-cols-1
    grid-rows-2
`;

const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const AllocateMark = ({ show, close, id, onFinish, studentId, maxNumber }) => {
    //eslint-disable-next-line
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [marks, setMarks] = useState([]);

    const renderOptions = () => {
        const options = [];
        for (let i = 0; i <= maxNumber; i++) {
            options.push({ name: i });
            setMarks(options);
        }
        return options;
    };

    useEffect(() => {
        renderOptions();
        //eslint-disable-next-line
    }, []);

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledClose onClick={close} />
            <PageTitle bottom="1.6rem" size="2.8rem" weight="500">
                Allocate Mark
            </PageTitle>
            <Text bottom="2.4rem" size="1.6rem" lineHeight="2.4rem">
                Choose the mark for this assignment
            </Text>
            <Formik
                initialValues={{
                    mark: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(allotMark(studentId, id, values));
                    if (res) {
                        toast.success('Success!');
                        onFinish?.();
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <GridRows className="gap-y-6">
                                <Select
                                    name="mark"
                                    objProp="name"
                                    data={marks}
                                    placeholder="Allot Mark"
                                    onChange={(selected) => {
                                        setFieldValue('mark', parseInt(selected[0].name));
                                    }}
                                    errors={errors.mark}
                                />
                            </GridRows>
                            <FlexRowEnd className="gap-x-[16px]">
                                <Button onClick={close} border="0.5px solid #D1D5DB">
                                    Cancel
                                </Button>
                                <Button type="submit" bgColor="#6366F1" color="#fff">
                                    Done
                                </Button>
                            </FlexRowEnd>
                        </Form>
                    );
                }}
            </Formik>
        </StyledModal>
    );
};

export default AllocateMark;
