import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as AttachmentAsh } from '../../../../assets/icons/attachment-ash.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-blue.svg';
import { ReactComponent as Mark } from '../../../../assets/icons/mark-sm.svg';
import { ActionsPopup, PDFPreview } from '../../../../components';
import { Button } from '../../../../components/buttons';
import Acknowledge from '../../../../components/forms/acknowledge';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { getLecturerMail, getMail } from '../../../../redux/communications/actions';

const MainContainer = tw.div`
h-auto
bg-white
mx-auto
py-[2.4rem]
px-[3.2rem]
rounded-lg
`;

const MailPreview = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showAcknowledge, setShowAcknowledge] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [mail, setMail] = useState([]);
    const { isLoading } = useSelector((store) => store.communications);
    const url = window.location.href;

    const fecthMail = async () => {
        const res = await dispatch(getMail(id));
        setMail(res);
    };

    const fetchLecturersMail = async () => {
        const res = await dispatch(getLecturerMail(id));
        setMail(res);
    };
    useEffect(() => {
        if (url.includes('sent')) {
            fetchLecturersMail();
        } else {
            fecthMail();
        }
        //eslint-disable-next-line
    }, [url]);

    const handleActionClick = (route) => {
        navigate(route);
        setDropdownOpen(false);
    };

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.2rem]">
                <BackArrow className="mr-[8px] " />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Received
                </PageTitle>
            </FlexCentredRow>

            <MainContainer>
                <FlexRowSpaceBetween>
                    <PageTitle>Mail</PageTitle>

                    <FlexCentredRow className="gap-[1.6rem]">
                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                Forward as
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={dropdownOpen}
                                items={[
                                    {
                                        name: 'Internal Memo',
                                        click: () => handleActionClick('internal-memo'),
                                    },
                                    {
                                        name: 'External Memo',
                                        click: () => handleActionClick('external-memo'),
                                    },
                                    {
                                        name: 'Bulletin',
                                        click: () => handleActionClick('bulletin'),
                                    },
                                    {
                                        name: 'Notices',
                                        click: () => handleActionClick('notice'),
                                    },
                                    {
                                        name: 'Mail',
                                        click: () => handleActionClick('mail'),
                                    },
                                ]}
                            />
                        </RelativeContainer>
                        <Button
                            border="1px solid #6366F1"
                            color="#6366F1"
                            bgColor="#fff"
                            onClick={() => setShowAcknowledge(true)}
                        >
                            <Mark className="mr-[13.15px]" />
                            Acknowledge
                        </Button>
                        <DownloadIcon />
                        <Close onClick={() => navigate('/bursary/communications/received')} />
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween className="mt-[2.8rem]">
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TYPE:
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            Mail
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <AttachmentAsh />
                        <Text color="#9CA3AF">Received. {new Date(mail?.createdAt).toLocaleString()}</Text>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexCentredCol>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            FROM (OFFICE):
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            {mail.sender}
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TO:{' '}
                        </Text>
                        <FlexDiv className="flex-wrap">
                            {mail.visibleTo?.map((receiver, i) => (
                                <Text key={i} weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                                    {receiver.unitName
                                        ? receiver.unitName
                                        : `${receiver.firstName} ${receiver.lastName}`}
                                </Text>
                            ))}
                        </FlexDiv>
                    </FlexCentredRow>
                </FlexCentredCol>
                <FlexCentredCol className=" gap-y-[3.2rem] my-[3.2rem]">
                    <Text align="left">{mail.title}</Text>
                    <Text align="left">{mail.content}</Text>
                    <Text align="left">Best Regards, {mail.sender}</Text>
                </FlexCentredCol>
                <div className="flex flex-wrap gap-[1rem] items-center">
                    {mail.attachment?.length > 0 ? (
                        <>
                            {mail.attachment?.map((file) => (
                                <PDFPreview url={file.url} pageNumber={1} />
                            ))}
                        </>
                    ) : null}
                    {mail.image?.length > 0 ? (
                        <>
                            {mail.image?.map((img, i) => (
                                <img key={i} src={img.url} alt={img.fileName} className="h-[100px] w-[200px]" />
                            ))}
                        </>
                    ) : null}
                </div>
            </MainContainer>

            <Acknowledge show={showAcknowledge} onClose={() => setShowAcknowledge(false)}></Acknowledge>
        </div>
    );
};

export default MailPreview;
