export const studentsData = [
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
    {
        name: 'Ned Stark',
        faculty: 'Engineering Technology',
        matric: 'MEE12345',
        department: 'MEE',
        level: '200',
        year: '2019/2020',
    },
];
