import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning2.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const PromoteVersion = ({ show, close, confirmAction, isLoading, versions, setValue }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween className="mb-[16px]">
                <div className="flex gap-[1rem]">
                    <WarningIcon />
                    <Text as="h3" size="1.9rem" weight="600" align="left">
                        Promote Version to Latest
                    </Text>
                </div>
                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>

            <Select
                label="Version to Promote to Latest"
                name="version"
                objProp="version"
                data={versions}
                placeholder="Select Version"
                onChange={(selected) => {
                    setValue(selected[0]);
                }}
            />

            <Text align="left" size="1.6rem" color="#374151" top="1.6rem">
                Kindly note that you will need to manually “Send to CA” to update this version on the CA sheet
            </Text>

            <Text align="left" size="1.6rem" color="#F59E0B" top="1.6rem">
                Note that versions after the selected version will be deleted automatically.
            </Text>

            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton loading={isLoading} disabled={isLoading} onClick={confirmAction}>
                    Continue
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default PromoteVersion;
