import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: {
        department: '',
        faculty: '',
        fullName: '',
        id: '',
        organizationId: '',
        organizationLogo: '',
        organizationName: '',
        staffEmail: '',
        staffId: '',
        staffRole: [],
        staffStatus: '',
        yearOfEmployment: '',
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        SAVE_USER: (state, action) => {
            state.userData = action.payload;
        },
        USER_LOGOUT: (state) => {
            state.userData = {
                department: '',
                faculty: '',
                fullName: '',
                id: '',
                organizationId: '',
                staffEmail: '',
                staffId: '',
                staffRole: [],
                staffStatus: '',
                yearOfEmployment: '',
            };
        },
    },
});

export const { SAVE_USER, USER_LOGOUT } = userSlice.actions;

export default userSlice.reducer;
