import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import arrowDownIcon from '../assets/icons/arrow-down.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/exit.svg';
import { ActionsPopup } from '../components';
import Avatar from '../components/avatar/Avatar';
import ConfirmActionDialogue from '../components/popups/confirm-action';
import { Text } from '../containers/MesssageContainers';
import { logoutUser } from '../redux/user/actions';
import { capitalizeFirstLetter } from '../utils';

const Header = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.userData);

    const [showConfirmActionDialogue, setShowConfirmActionDialogue] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    return (
        <>
            <header className="w-full top-0 z-20 right-0 h-[6.5rem] pl-[5rem] flex justify-between sticky items-center pr-[3rem] bg-white shadow-sm">
                <Link to="/dashboard">
                    <div className={`${!open && 'justify-center'} flex items-center`}>
                        {user?.organizationLogo ? (
                            <img
                                src={user?.organizationLogo.url}
                                alt="logo"
                                className={` ${open && ''} h-[4.8rem] w-[4.8rem] rounded-full img-cover`}
                            />
                        ) : null}

                        <Text size="1.8rem" color="#4F46E5" weight="600" lineHeight="normal">
                            {user?.organizationName}
                        </Text>
                    </div>
                </Link>

                <div className="ml-auto flex justify-center items-center">
                    <div className="relative">
                        <button
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="w-max h-[3.9312rem] rounded-[3.2rem] px-3 bg-light-ash flex items-center justify-start"
                        >
                            <Avatar imageUrl={user?.staffPicture?.url} fullName={user?.fullName} />
                            <h4 className=" ml-2 mr-[1.367rem] mb-0 leading-[2.1rem] text-[#2E4457] font-medium text-[1.4rem]">
                                {capitalizeFirstLetter(user?.firstName)} {capitalizeFirstLetter(user?.surname)}
                            </h4>
                            <img src={arrowDownIcon} alt="dropdown" />
                        </button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <LogoutIcon />,
                                    name: 'Logout',
                                    click: () => {
                                        setShowConfirmActionDialogue(true);
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </header>

            <ConfirmActionDialogue
                show={showConfirmActionDialogue}
                title="Logging Out"
                subtitle="Are you sure you want to log out?"
                close={() => setShowConfirmActionDialogue(false)}
                confirmAction={() => dispatch(logoutUser(navigate))}
                btn2Text="Logout"
            />
        </>
    );
};

export default Header;
