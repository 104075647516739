import React, { useState } from 'react';

// import { differenceInDays, format } from 'date-fns';
import { CDataTable, CPagination } from '@coreui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as AssignmentIcon } from '../../../../assets/icons/assignment.svg';
import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
// import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
// import { ReactComponent as ThreeDotsIcon } from '../../../../assets/icons/three-dots.svg';
// import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
// import ExtendDeadline from '../../../../components/forms/extend-deadline';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
// import ConfirmActionDialogue from '../../../../components/popups/confirm-action';
// import { Text } from '../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    // FlexDiv,
    // FlexCentredCol,
    // FlexColumn,
    ItemsPerPageCont,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
// import { deleteAssignment } from '../../../../redux/assignment/actions';

const fields = [
    {
        label: <Checkbox />,
        key: 'action',
    },
    {
        label: 'Assignment Title',
        key: 'title',
    },
    {
        label: 'Total Marks',
        key: 'totalMarks',
    },
    {
        label: 'Registered Students',
        key: 'numberOfStudents',
    },
    {
        label: 'Total Scores Entered',
        key: 'numberOfSubmissions',
    },
    {
        label: 'Continous Assessment',
        key: 'testOption',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

// const Card = tw.div`
//     py-[19px]
//     pl-4
//     pr-9
//     bg-white
//     rounded-lg
//     flex
//     justify-between
// `;

const Assignments = ({ assignments, handleCreate, getAssignments, course, setSelectedItem, selectedItem, session }) => {
    const { isLoading } = useSelector((state) => state.assignment);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(assignments?.length / itemsPerPage);

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    return (
        <div>
            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>
                </FilterContainer>

                <CDataTable
                    items={assignments || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => handleSelect(item)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox checked={item?._id === selectedItem?._id} />
                            </td>
                        ),
                        title: (item) => (
                            <td style={{ color: '#2563EB' }}>
                                <Link
                                    to={`/assignments/spreadsheet/${item?._id}`}
                                    state={{ course, session, assignment: item }}
                                >
                                    {item.title}
                                </Link>
                            </td>
                        ),
                    }}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Assignments yet" subtitle="Create a new assignment">
                                <Button onClick={handleCreate} bgColor="#10B981" color="#fff">
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            </ListEmptyContent>
                        </CenteredContainer>
                    }
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            {/* //Former Ui */}
            {/* {!assignments?.length > 0 ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No Assignments yet" subtitle="Create a new assignment">
                        <Button onClick={handleCreate} bgColor="#10B981" color="#fff">
                            <Circularplus className="mr-[1.15rem]" />
                            Create New
                        </Button>
                    </ListEmptyContent>
                </CenteredContainer>
            ) : ( */}
            {/* <FlexColumn className="gap-1">
                {assignments?.map((assignment) => {
                    const isOpen = openActions && selected._id === assignment._id;
                    return (
                        <Card key={assignment._id}>
                            <FlexCentredRow>
                                <AssignmentIcon className="mr-[8px]" />
                                <FlexCentredCol>
                                    <FlexDiv className="gap-2">
                                        <Text size="1.6rem" lineHeight="2.4rem" weight="500">
                                            {assignment.title}
                                        </Text>
                                    </FlexDiv>
                                    <FlexDiv className="gap-x-[0.8rem]">
                                        <Text color="#9CA3AF" size="1.2rem" lineHeight="1.7rem"> */}
            {/* Posted {format(new Date(assignment.updatedAt), 'cccc, PP')} - */}
            {/* </Text>
                                        <Text color="#F87171" size="1.2rem" lineHeight="1.7rem"> */}
            {/* {differenceInDays(new Date(assignment.deadlineDate), new Date())} days */}
            {/* to go
                                        </Text>
                                    </FlexDiv>
                                </FlexCentredCol>
                            </FlexCentredRow>

                            <FlexCentredRow className="gap-[1.6rem]">
                                <Edit
                                    className="cursor-pointer"
                                    onClick={() => navigate(`/assignments/submissions/update/${assignment._id}`)}
                                />
                                <Button
                                    color="#6366F1"
                                    border=".5px solid #6366F1" */}
            {/* // onClick={() => navigate(`/assignments/submissions/${assignment._id}`)} */}
            {/* //     onClick={() => */}
            {/* //         navigate(`/assignments/spreadsheet/${assignment._id}`, { */}
            {/* //             state: { course },
                                //         })
                                //     }
                                // > */}
            {/* Submissions */}
            {/* View Spreadsheet
                                </Button>
                                <div className="relative">
                                    <ThreeDotsIcon
                                        className="ml-[1rem] relative cursor-pointer"
                                        onClick={() => {
                                            if (isOpen) {
                                                setOpenActions(false);
                                            } else {
                                                setSelected(assignment);
                                                setOpenActions(true);
                                            }
                                        }}
                                    />
                                    <ActionsPopup
                                        open={isOpen}
                                        close={() => setOpenActions(false)}
                                        items={[
                                            {
                                                name: 'Extend Deadline',
                                                click: () => {
                                                    setOpenActions(false);
                                                    setExtendDeadline(true);
                                                },
                                            },
                                            {
                                                name: 'Delete',
                                                click: () => {
                                                    setShowDeleteItem(true);
                                                    setOpenActions(false);
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </FlexCentredRow>
                        </Card>
                    );
                })} */}

            {/* <ConfirmActionDialogue
                title="Delete Assignment"
                subtitle="Are you sure you want to delete this permanently?"
                show={showDeleteItem}
                close={() => setShowDeleteItem(false)}
                confirmAction={async () => {
                    const res = await dispatch(deleteAssignment(selectedItem._id));
                    if (res) {
                        getAssignments();
                    }
                }}
                btn2Text="Confirm"
            /> */}

            {/* <ExtendDeadline
                    show={extendDeadline}
                    close={() => setExtendDeadline(false)}
                    assignment={selected}
                    onFinish={getAssignments}
                /> */}
            {/* </FlexColumn> */}
            {/* )} */}
        </div>
    );
};

export default Assignments;
