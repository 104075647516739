import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import SelectInput from '../../inputs/select-input';

const MainContainer = tw.div`
    w-[50.4rem]
    h-auto
    bg-white
    mx-auto
    p-[3.2rem]
    rounded-lg
`;
const FlowRoot = tw.div`
flow-root
`;
const GridRows = tw.div`
grid
grid-cols-1
grid-rows-3
`;
const Time = tw.div`
grid
grid-cols-3
grid-rows-1
justify-center
items-center
my-[1.5rem]
`;
// const TimeInput = tw(input)`
//     border-[#E5E7EB]
//     h-[3rem]
//     lg:h-[4rem]
//     rounded
//     outline-none
//     focus:ring-[#6366F1]
//     focus:ring-2
//     text-[1.4rem]
//     text-[#6B7280]
//     leading-6
// `;

const CreateSchedule = ({ onClose }) => {
    return (
        <>
            <MainContainer>
                <FlowRoot>
                    <CloseIcon className="float-right mt-[2.9rem] cursor-pointer" onClick={onClose} />
                </FlowRoot>
                <FlexCentredRow className="gap-x-[0.715rem] mt-[1.53rem] mb-[3.2rem]">
                    <PeopleIcon />
                    <PageTitle size="1.6rem" lineHeight="2.4rem">
                        Set Lecture Schedule
                    </PageTitle>
                </FlexCentredRow>
                <GridRows className="gap-y-6">
                    <SelectInput>
                        <option value="">Session</option>
                    </SelectInput>
                    <SelectInput>
                        <option value="">Semester</option>
                    </SelectInput>
                    <SelectInput>
                        <option value="">Courses</option>
                    </SelectInput>
                </GridRows>
                <Time>
                    <input
                        type="time"
                        className=" border-[1px] border-[#E5E7EB] h-[3rem] lg:h-[4rem] rounded p-3 none focus:ring-[#6366F1] focus:ring-2 text-[1.4rem] text-[#6B7280] leading-6"
                    />
                    <Text size="1.4rem" weight="600" lineHeight="2.4rem" color="#6B7280">
                        to
                    </Text>
                    <input
                        type="time"
                        className=" border-[1px] border-[#E5E7EB] h-[3rem] lg:h-[4rem] rounded outline-none focus:ring-[#6366F1] focus:ring-2 p-3 text-[1.4rem] text-[#6B7280] leading-6"
                    />
                </Time>
                <SelectInput>
                    <option value="">Venue</option>
                </SelectInput>

                <FlowRoot>
                    <FlexDiv className="float-right gap-[2.4rem] mb-[3.6rem]">
                        <Button
                            bgColor="#fff"
                            color="#D1D5DB"
                            border="1px"
                            // type="submit"
                            // disabled={isSubmitting}
                            // onClick={() => navigate('/')}
                            className="py-2 px-4 mt-12  mb-[1.9rem] cursor-pointer border-[#E5E7EB] "
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            // disabled={isSubmitting}
                            bgColor="#6366F1"
                            color="#fff"
                            className="py-2 px-4 mt-12 hover:bg-[#6366F1] focus:bg-[#6366F1] focus:text-white mb-[1.9rem] cursor-pointer"
                        >
                            Update
                        </Button>
                    </FlexDiv>
                </FlowRoot>
            </MainContainer>
        </>
    );
};

export default CreateSchedule;
