import React, { useState, useMemo } from 'react';

import { Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { createStaffPublication } from '../../../redux/staff/actions';

import PublicationsPreview from './Preview';
import Publication from './Publication';

const MainContainer = tw.div`
flex
flex-col
gap-[.2rem]
lg:p-[2.4rem]
md:p-[5.5rem]
rounded-lg
`;
const SubContainer = tw.div`
flex
flex-col
px-[2rem]
py-[
2rem]
gap-[1rem]
rounded-b-lg
bg-white
`;

const Header = tw.div`
lg:p-[2.4rem]
md:p-[5.5rem]
p-[2rem]
bg-white
flex
items-center
my-[auto]
rounded-t-lg
border-[#E5E7EB]
border-b
h-[7.6rem]
`;

const AddButton = tw.div`
bg-white
w-full
h-[4rem]
flex
justify-center
items-center
gap-[.5rem]
border-[1px]
border-[#3B82F6]
rounded-[4px]
bg-[#FFFFFF]
shadow-[0px 1px 2px rgba(6, 25, 56, 0.05)]
py-[.8rem]
px-[1.6rem]
cursor-pointer
`;

const FlexContainer = tw.div`
bg-white
hidden
md:flex
md:flex-row
flex-col
justify-between
gap-[1.5rem]
md:gap-[2rem]
w-[100%]
`;

const PublicationForm = ({ handleStep }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { staffPub } = useSelector((store) => store.staff);

    const modifiedPublications = useMemo(
        () =>
            staffPub?.map((item) => ({
                ...item,
                publication: item?.files?.[0] || item?.publication,
            })),
        [staffPub],
    );

    const [viewDetails, setViewDetails] = useState(false);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    publicationInfo:
                        staffPub?.length > 0
                            ? modifiedPublications
                            : [
                                  {
                                      publisher: '',
                                      publicationType: '',
                                      publicationTitle: '',
                                      author: '',
                                      year: '',
                                      publicationLink: '',
                                      publication: '',
                                  },
                              ],
                }}
                onSubmit={async (values) => {
                    const payload = values;
                    const formData = new FormData();

                    for (let i = 0; i < payload.publicationInfo?.length; i++) {
                        const currentItem = payload.publicationInfo[i];

                        for (const objKey in currentItem) {
                            if (currentItem[objKey] !== undefined && objKey !== '_id' && objKey !== 'files') {
                                formData.append(`uploadPublication[${i}][${objKey}]`, currentItem[objKey]);
                            }
                        }
                    }

                    const res = await dispatch(createStaffPublication(formData));
                    if (res) {
                        handleStep();
                    }
                }}
            >
                {({ errors, handleChange, touched, values, setFieldValue }) => (
                    <Form>
                        {!viewDetails ? (
                            <>
                                <MainContainer>
                                    <Header>
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Publications
                                        </PageTitle>
                                    </Header>

                                    <SubContainer>
                                        <FieldArray
                                            name="publicationInfo"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem]">
                                                    {values?.publicationInfo?.map((publication, index) => (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                    bottom="2rem"
                                                                    top="1rem"
                                                                >
                                                                    Publication {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="gap-[1rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                publisher: '',
                                                                                publicationType: '',
                                                                                publicationTitle: '',
                                                                                author: '',
                                                                                year: '',
                                                                                publication: '',
                                                                                publicationLink: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="ml-[2.315rem] cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexContainer>
                                                            <Publication
                                                                handleChange={handleChange}
                                                                touched={touched}
                                                                setFieldValue={setFieldValue}
                                                                // names
                                                                publisher={`publicationInfo.${index}.publisher`}
                                                                year={`publicationInfo.${index}.year`}
                                                                publisherType={`publicationInfo.${index}.publicationType`}
                                                                publisherTitle={`publicationInfo.${index}.publicationTitle`}
                                                                author={`publicationInfo.${index}.author`}
                                                                publicationLink={`publicationInfo.${index}.publicationLink`}
                                                                publication={`publicationInfo.${index}.publication`}
                                                                // values
                                                                publisherValue={publication.publisher}
                                                                publisherTitleValue={publication.publicationTitle}
                                                                publisherTypeValue={publication.publicationType}
                                                                authorValue={publication.author}
                                                                yearValue={publication.year}
                                                                publicationLinkValue={publication.publicationLink}
                                                                file={publication.publication}
                                                                //errors
                                                                publisherTypeErrors={
                                                                    errors.publicationInfo?.publicationType
                                                                }
                                                                publisherErrors={errors.publicationInfo?.publisher}
                                                                publisherTitleErrors={
                                                                    errors.publicationInfo?.publicationTitle
                                                                }
                                                                authorErrors={errors.publicationInfo?.author}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>
                                </MainContainer>

                                <div className="flow-root my-[3rem]">
                                    <FlexDiv className="float-right gap-[1.45rem]">
                                        <Button type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </Button>
                                        <div
                                            onClick={() => setViewDetails(true)}
                                            className="py-2 px-4 h-[4rem] cursor-pointer text-[#fff] bg-[#6366F1] flex items-center justify-center rounded-[4px] text-[1.4rem]"
                                        >
                                            Next
                                        </div>
                                    </FlexDiv>
                                </div>
                            </>
                        ) : (
                            <PublicationsPreview values={values} onBtnClick={() => setViewDetails(false)} />
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PublicationForm;
