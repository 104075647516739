import React, { useState } from 'react';

import { CDataTable } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ChevronIcon } from '../../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as People } from '../../../../assets/icons/people2.svg';
import { ReactComponent as ScrollIcon } from '../../../../assets/icons/scroll.svg';
import { ReactComponent as ProfilePicture } from '../../../../assets/images/profile-picture.svg';
import { Button } from '../../../../components/buttons';
import EditStudentRecordsForm from '../../../../components/forms/edit-student-records';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexDiv } from '../../../../containers/ScreenContainers';
import { courseDetailsData } from '../../../../data/courseDetails';

const MainContainer = tw.div`
    grid
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3
    grid-flow-row
    gap-6
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-3
    mt-8
    mb-[7.1rem]
`;

const RowContainer = tw.div`
    grid
    grid-rows-3
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    lg:col-span-2
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

const FullDetails = tw(Details)`
    col-span-3
    p-0
    py-6
`;

const SmallCard = tw.div`
    bg-white
    rounded-lg
    p-6
`;

const DisplayCard = tw.div`
    bg-[#EEF2FF]
    rounded-lg
    pt-[4rem]
    pb-[3.2rem]
    pl-[4.4rem]
    pr-[4.3rem]
    ml-[6rem]
    mr-[6.1rem]
    mb-[1.4rem]
`;

const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const fields = [
    // {
    //     label: 'S/N',
    //     key: 'serialNumber',
    // },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Course Title',
        key: 'courseTitle',
    },
    {
        label: 'Unit',
        key: 'unit',
    },
    {
        label: 'Status',
        key: 'status',
    },
];

const BasicInfo = () => {
    const [showForm, setShowForm] = useState(false);
    return (
        <div>
            <MainContainer>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Student Details
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexDiv className="justify-end gap-[3.2rem]">
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Active
                                </Text>
                            </Button>
                            <Button bgColor="#fff" onClick={() => setShowForm(true)}>
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Edit
                                </Text>
                            </Button>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <ProfilePicture />
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <DetailsCardTitleText>Full Name</DetailsCardTitleText>
                                <DetailsCardSubText>Emmanuel Olukunle Peter</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>School</DetailsCardTitleText>
                                <DetailsCardSubText>Engineering</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Graduate Level</DetailsCardTitleText>
                                <DetailsCardSubText>Undergraduate</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <DetailsCardTitleText align="right">Department</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Mechanical Engineering</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Level </DetailsCardTitleText>
                                <DetailsCardSubText align="right">300</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Admission Year</DetailsCardTitleText>
                                <DetailsCardSubText align="right">March, 2015</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>

                <SmallCard>
                    <PageTitle size="1.9rem" lineheight="2.8rem" bottom="1.534rem">
                        CGPA
                    </PageTitle>
                    <DisplayCard>
                        <PageTitle weight="500" size="6.9rem" lineHeight="8.4rem">
                            2.78
                        </PageTitle>
                    </DisplayCard>
                    <Text lineHeight="2.4rem" color="#6B7280">
                        Current Cummulative Grade
                    </Text>
                    <Text lineHeight="2.4rem" bottom="3.37rem" color="#6B7280">
                        Point Average
                    </Text>
                    <FlexCentredRow>
                        <Button className="border-solid border-[#6366F1] mx-auto" bottom="3.284rem">
                            <Text border="solid" weight="500" lineHeight="2.4rem" color="#6366F1">
                                View Result
                            </Text>
                        </Button>
                    </FlexCentredRow>
                </SmallCard>

                <FullDetails>
                    <FlexRowSpaceBetween className="mb-[1.5rem] pl-[1.8rem]">
                        <FlexCentredRow>
                            <ScrollIcon className="mr-[1.33rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Registered Courses
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexCentredRow className="mr-[5rem]">
                            <Text color="#6366F1" weight="500">
                                2019/2022
                            </Text>
                            <ChevronIcon className="ml-[0.9rem]" />
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <CDataTable
                        items={courseDetailsData || []}
                        className="pl-[5.8rem]"
                        fields={fields}
                        scopedSlots={{
                            status: (item) => (
                                <td>
                                    {item.status === 'Compulsory' ? (
                                        <StatusContainer>
                                            <Text color="#EF4444" size="1.2rem">
                                                {item.status}
                                            </Text>
                                        </StatusContainer>
                                    ) : (
                                        <StatusContainer bgColor="#F5F3FF">
                                            <Text color="#8B5CF6" size="1.2rem">
                                                {item.status}
                                            </Text>
                                        </StatusContainer>
                                    )}
                                </td>
                            ),
                        }}
                        // striped
                        selectable
                        responsive
                        sorter
                    />
                    <FlexRowSpaceBetween className="px-[1.8rem]">
                        <Text color="#9CA3AF" size="1.6rem">
                            Total Courses:
                            <Text as="span" weight="500">
                                0/10
                            </Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Total Units:
                            <Text as="span" weight="500">
                                0/24
                            </Text>
                        </Text>
                    </FlexRowSpaceBetween>
                </FullDetails>
            </MainContainer>
            {showForm && <EditStudentRecordsForm show={showForm} setShow={setShowForm} />}
        </div>
    );
};

export default BasicInfo;
