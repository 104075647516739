import React, { useState } from 'react';

//import { CDataTable } from '@coreui/react';
import tw, { styled } from 'twin.macro';

//import { ReactComponent as ChevronIcon } from '../../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as People } from '../../../../assets/icons/people2.svg';
//import { ReactComponent as ScrollIcon } from '../../../../assets/icons/scroll.svg';
//import { ReactComponent as ProfilePicture } from '../../../../assets/images/profile-picture.svg';
//import { Button } from '../../../../components/buttons';
import EditStudentRecordsForm from '../../../../components/forms/edit-student-records';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {  FlexCentredRow, } from '../../../../containers/ScreenContainers';
//import { courseDetailsData } from '../../../../data/courseDetails';

const MainContainer = tw.div`
grid
grid-cols-1
sm:grid-cols-2
lg:grid-cols-2
grid-flow-row
gap-6
mt-8
mb-[6.4rem]
`;


const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    mt-8
    mb-[7.1rem]
`;

const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    lg:col-span-1
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

// const FullDetails = tw(Details)`
//     col-span-3
//     p-0
//     py-6
// `;



const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

// const StatusContainer = styled.div`
//     padding: 2px 4px;
//     border-radius: 4px;
//     background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
//     width: fit-content;
// `;

// const fields = [
//     // {
//     //     label: 'S/N',
//     //     key: 'serialNumber',
//     // },
//     {
//         label: 'Course Code',
//         key: 'courseCode',
//     },
//     {
//         label: 'Course Title',
//         key: 'courseTitle',
//     },
//     {
//         label: 'Unit',
//         key: 'unit',
//     },
//     {
//         label: 'Status',
//         key: 'status',
//     },
// ];

const Personal = () => {
    const [showForm, setShowForm] = useState(false);
    return (
        <div>
            <MainContainer>
                <Details>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Student Details
                            </PageTitle>
                        </FlexCentredRow>

                    <BasicContent>
                        {/* <ProfilePicture /> */}
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <DetailsCardTitleText>Full Name</DetailsCardTitleText>
                                <DetailsCardSubText>Emmanuel Olukunle Peter</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Email Address</DetailsCardTitleText>
                                <DetailsCardSubText>emmanuelolukunle@gmail.com</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Matric Number</DetailsCardTitleText>
                                <DetailsCardSubText>MEE058633</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Phone Number</DetailsCardTitleText>
                                <DetailsCardSubText>+2347041250726</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <DetailsCardTitleText align="right">Gender</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Male</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Date of Birth </DetailsCardTitleText>
                                <DetailsCardSubText align="right">24th March, 2001</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Admission Mode</DetailsCardTitleText>
                                <DetailsCardSubText align="right">UTME</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Marital Status</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Single</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>


                <Details>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                            Other Information
                            </PageTitle>
                        </FlexCentredRow>

                    <BasicContent>
                        {/* <ProfilePicture /> */}
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <DetailsCardTitleText>Nationality</DetailsCardTitleText>
                                <DetailsCardSubText>Nigerian</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Hometown</DetailsCardTitleText>
                                <DetailsCardSubText>Okitipupa</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Place of Birth</DetailsCardTitleText>
                                <DetailsCardSubText>Borno</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <DetailsCardTitleText align="right">State of Origin</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Ondo</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">LGA </DetailsCardTitleText>
                                <DetailsCardSubText align="right">Okitipupa</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Permanent Home Address</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Okitipupa</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>
            </MainContainer>

            <MainContainer>
                <Details>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                            Next of Kin
                            </PageTitle>
                        </FlexCentredRow>

                    <BasicContent>
                        {/* <ProfilePicture /> */}
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <DetailsCardTitleText>Next of Kin</DetailsCardTitleText>
                                <DetailsCardSubText>Samuel Tolamise</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Phone Number</DetailsCardTitleText>
                                <DetailsCardSubText>+234814977588</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Father’s Phone Number</DetailsCardTitleText>
                                <DetailsCardSubText>+234814977588</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Father’s Occupation</DetailsCardTitleText>
                                <DetailsCardSubText>Teacher</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Mother’s Phone Number</DetailsCardTitleText>
                                <DetailsCardSubText>+234814977588</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <DetailsCardTitleText align="right">Address</DetailsCardTitleText>
                                <DetailsCardSubText align="right">8, Ozumba Mbadiwe Lagos</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Father’s Name </DetailsCardTitleText>
                                <DetailsCardSubText align="right">Olukunle Gideon</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Father’s Address</DetailsCardTitleText>
                                <DetailsCardSubText align="right">8, Ozumba Mbadiwe Lagos</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Mother’s Name</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Savage Olukunle</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Mother’s Address</DetailsCardTitleText>
                                <DetailsCardSubText align="right">8, Ozumba Mbadiwe Lagos</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>


                <Details>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                            Department Information
                            </PageTitle>
                        </FlexCentredRow>

                    <BasicContent>
                        {/* <ProfilePicture /> */}
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <DetailsCardTitleText>Department</DetailsCardTitleText>
                                <DetailsCardSubText>Mechanical Engineering</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Level</DetailsCardTitleText>
                                <DetailsCardSubText>200</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <DetailsCardTitleText align="right">Faculty</DetailsCardTitleText>
                                <DetailsCardSubText align="right">School of Engineering</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Degree </DetailsCardTitleText>
                                <DetailsCardSubText align="right">Bachelor of Tech.</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>
            </MainContainer>
            {showForm && <EditStudentRecordsForm show={showForm} setShow={setShowForm} />}
        </div>
    );
};

export default Personal;
