import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Mark } from '../../../assets/icons/mark-black.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';
import SuccessPopup from '../../popups/success';

import { ValidationSchema } from './ValidationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;

const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const Acknowledge = ({ show, close }) => {
    const navigate = useNavigate();
    const [openSuccess, setShowOpenSuccess] = useState(false);

    return (
        <>
            <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
                <StyledClose onClick={close} />
                <FlexCentredRow className=" items-center  gap-[1.215rem]">
                    <Mark />
                    <PageTitle size="1.9rem" lineHeight="2.8rem" weight="600">
                        Acknowledge
                    </PageTitle>
                </FlexCentredRow>
                <Formik
                    initialValues={{
                        name: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        // const res = await dispatch(createFaculty(values));
                        // if (res) {
                        //     await dispatch(getFaculties());
                        //     actions.resetForm();
                        //     onClose();
                        //     setOpenSuccess(true);
                        // }
                    }}
                >
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <FlexCentredCol>
                                <Text align="left" weight="500" bottom="0.8rem" size="1.4rem" lineHeight="2.4rem">
                                    Name
                                </Text>
                                <TextInput
                                    type="text"
                                    placeholder="Input Name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </FlexCentredCol>
                            <div className="flow-root">
                                <FlexDiv className="float-right gap-[1.6rem]">
                                    <Button bgColor="#FFF" className="py-2 px-4 mt-12 cursor-pointer border-[#D1D5DB]">
                                        Cancel
                                    </Button>
                                    <Button
                                        bgColor="#EF4444"
                                        color="#FFF"
                                        onClick={() => setShowOpenSuccess(true)}
                                        className="py-2 px-4 mt-12 cursor-pointer"
                                    >
                                        Acknowledge
                                    </Button>
                                </FlexDiv>
                            </div>
                            <SuccessPopup
                                show={openSuccess}
                                btnAction={() => navigate(-1)}
                                title="Acknowledged"
                                btnText="Close"
                            />
                        </Form>
                    )}
                </Formik>
            </StyledModal>
        </>
    );
};

export default Acknowledge;
