import React from 'react';


import InputContainer from '../../../components/Container/inputcontainer';
import SignInReturningForm from '../../../components/forms/sign-in-returning';
import { PoweredBy } from '../../../components/poweredby';
// import { PageTitle } from '../../../containers/MesssageContainers';
import { Container } from '../sign-in-new';



const SignInReturning = () => {

    return (
        <Container>

            <InputContainer>

         
            <SignInReturningForm />
                <PoweredBy />

            

            </InputContainer>
        </Container>
    );
};
export default SignInReturning;
