import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    staff: [],
    staffBio: [],
    staffExp: [],
    staffPub: [],
    staffUploads: [],
};

export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_STAFF: (state, action) => {
            state.staff = action.payload;
        },
        GET_STAFF_BIO: (state, action) => {
            state.staffBio = action.payload;
        },
        GET_STAFF_EXP: (state, action) => {
            state.staffExp = action.payload;
        },
        GET_STAFF_PUB: (state, action) => {
            state.staffPub = action.payload;
        },
        GET_STAFF_UPLOADS: (state, action) => {
            state.staffUploads = action.payload;
        },
    },
});

export const { GET_STAFF, LOADING, STOP_LOADING, GET_STAFF_BIO, GET_STAFF_EXP, GET_STAFF_PUB, GET_STAFF_UPLOADS } =
    staffSlice.actions;

export default staffSlice.reducer;
