import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

export const getAttendance = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}attendance/get-all-attendance/${session}/${courseId}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAttendanceSpreadsheet = (session, courseId, attendanceId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}attendance/attendance-spreadsheet/${session}/${courseId}/${attendanceId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAttendanceScoresheet = (session, courseId, version) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}attendance/attendance-scoresheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createAttendance = (courseId, session, payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}attendance/create-attendance/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Attendance created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editAttendance = (session, courseId, attendanceId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}attendance/edit-attendance/${session}/${courseId}/${attendanceId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Attendance updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteAttendance = (attendanceId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}attendance/delete-attendance/${attendanceId}`);
        if (response.status === 200) {
            toast.success('Attendance deleted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const markStudentAttendance = (params) => async (dispatch, getState) => {
    const { courseId, studentId, attendanceId, session, version } = params;

    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}attendance/mark-student-attendance/${session}/${courseId}/${studentId}/${attendanceId}/${version}`,
        );
        if (response.status === 200) {
            toast.success('Attendance marked successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAttendanceVersions = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}attendance/attedance-scoresheet-versions-history/${courseId}/${session}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createNewAttendanceVersion = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}attendance/create-new-attendance-version/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('New version created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const promoteAttendanceVersion = (courseId, session, latestVersion, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}attendance/promote-attendance-scoresheet-version/${courseId}/${session}/${latestVersion}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Version promoted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendAttendanceToBroadsheet = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}attendance/send-attendance-scoresheet-to-broadsheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('Scoresheet was sent to CA successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
