import React, { useState } from 'react';

import { Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { Button } from '../../../components/buttons';
import TextAreaInput from '../../../components/inputs/text-area';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { createStaffExperience } from '../../../redux/staff/actions';

import Awards from './Awards';
import ExperiencePreview from './Preview';
import Primary from './Primary';
import WorkExperience from './WorkExperience';

const MainContainer = tw.div`
flex
flex-col
gap-[.2rem]
lg:p-[2.4rem]
md:p-[5.5rem]
rounded-lg
`;
const SubContainer = tw.div`
flex
flex-col
px-[2rem]
py-[
2rem]
gap-[1rem]
rounded-b-lg
bg-white
`;

const Header = tw.div`
lg:p-[2.4rem]
md:p-[5.5rem]
p-[2rem]
bg-white
flex
items-center
my-[auto]
rounded-t-lg
border-[#E5E7EB]
border-b
h-[7.6rem]
`;

const AddButton = tw.div`
bg-white
w-full
h-[4rem]
flex
justify-center
items-center
gap-[.5rem]
border-[1px]
border-[#3B82F6]
rounded-[4px]
bg-[#FFFFFF]
shadow-[0px 1px 2px rgba(6, 25, 56, 0.05)]
py-[.8rem]
px-[1.6rem]
cursor-pointer
`;

const FlexContainer = tw.div`
bg-white
hidden
md:flex
md:flex-row
flex-col
justify-between
gap-[1.5rem]
md:gap-[2rem]
w-[100%]
`;

const Secondarycert = ['WAEC', 'NECO', 'NABTEC'];
const Tertiarycert = [ 'B.A.','B.Eng.','B.Ed.','B.LIS.','B.MLS.','B.Pharm.','B.NSc.','B.Sc.','B.Tech.','DVM (Doctor of Veterinary Medicine)','HND','LL.B','MBBS (or MBChB)','NCE','ND','BPH'];
const PGcert = ['DBA', 'Ed.D.', 'LL.M.', 'M.A', 'M.B.A.', 'M.Ed.', 'M.Eng.', 'M.Phil.','M.Sc.', 'M.Tech', 'MPA', 'Ph.D.','PGD'];

const Experience = ({ handleStep }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { staffExp } = useSelector((store) => store.staff);

    const [viewDetails, setViewDetails] = useState(false);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    aboutInfo: {
                        backgroundProfile: staffExp?.aboutInfo?.backgroundProfile || '',
                        researchInterest: staffExp?.aboutInfo?.researchInterest || '',
                    },
                    WorkExperienceInfo:
                        staffExp?.WorkExperienceInfo?.length > 0
                            ? staffExp?.WorkExperienceInfo
                            : [
                                  {
                                      role: '',
                                      organization: '',
                                      startingYear: '',
                                      endYear: '',
                                      description: '',
                                  },
                              ],
                    secondarySchoolInfo:
                        staffExp?.secondarySchoolInfo?.length > 0
                            ? staffExp?.secondarySchoolInfo
                            : [
                                  {
                                      institutionName: '',
                                      startingYear: '',
                                      endYear: '',
                                      certificate: '',
                                  },
                              ],
                    tertiarySchoolInfo:
                        staffExp?.tertiarySchoolInfo?.length > 0
                            ? staffExp?.tertiarySchoolInfo
                            : [
                                  {
                                      institutionName: '',
                                      startingYear: '',
                                      endYear: '',
                                      certificate: '',
                                  },
                              ],
                    postGraduateInfo:
                        staffExp?.postGraduateInfo?.length > 0
                            ? staffExp?.postGraduateInfo
                            : [
                                  {
                                      institutionName: '',
                                      startingYear: '',
                                      endYear: '',
                                      certificate: '',
                                  },
                              ],
                    awardsInfo:
                        staffExp?.awardsInfo?.length > 0
                            ? staffExp?.awardsInfo
                            : [
                                  {
                                      date: '',
                                      name: '',
                                  },
                              ],
                }}
                onSubmit={async (values) => {
                    const res = await dispatch(createStaffExperience(values));
                    if (res) {
                        handleStep();
                    }
                }}
            >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                    <Form>
                        {!viewDetails ? (
                            <>
                                <MainContainer>
                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            About
                                        </PageTitle>
                                    </Header>
                                    <SubContainer>
                                        <TextAreaInput
                                            name="aboutInfo.backgroundProfile"
                                            value={values.aboutInfo?.backgroundProfile}
                                            errorMessage={errors.aboutInfo?.backgroundProfile}
                                            placeholder="Background Profile"
                                            height="8rem"
                                            onChange={handleChange}
                                        />
                                        <TextAreaInput
                                            name="aboutInfo.researchInterest"
                                            value={values.aboutInfo?.researchInterest}
                                            errorMessage={errors.aboutInfo?.researchInterest}
                                            placeholder="Research Interests"
                                            height="8rem"
                                            onChange={handleChange}
                                        />
                                    </SubContainer>
                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Work Experience
                                        </PageTitle>
                                    </Header>
                                    <SubContainer>
                                        <FieldArray
                                            name="WorkExperienceInfo"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem]">
                                                    {values?.WorkExperienceInfo?.map((exp, index) => (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                    bottom="2rem"
                                                                    top="1rem"
                                                                >
                                                                    Experience {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="gap-[1rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                startingYear: '',
                                                                                endYear: '',
                                                                                role: '',
                                                                                organization: '',
                                                                                description: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="ml-[2.315rem] cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexContainer>
                                                            <WorkExperience
                                                                role={`WorkExperienceInfo.${index}.role`}
                                                                organization={`WorkExperienceInfo.${index}.organization`}
                                                                desp={`WorkExperienceInfo.${index}.description`}
                                                                yearStart={`WorkExperienceInfo.${index}.startingYear`}
                                                                yearEnd={`WorkExperienceInfo.${index}.endYear`}
                                                                Delete={index > 0 ? () => remove(index) : null}
                                                                orgErrors={errors.WorkExperienceInfo?.organization}
                                                                roleErrors={errors.WorkExperienceInfo?.role}
                                                                despErrors={errors.WorkExperienceInfo?.description}
                                                                handleChange={handleChange}
                                                                touched={touched}
                                                                roleValue={exp.role}
                                                                orgValue={exp.organization}
                                                                despValue={exp.description}
                                                                fromvValue={exp.startingYear}
                                                                toValue={exp.endYear}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>
                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Education History
                                        </PageTitle>
                                    </Header>

                                    <SubContainer>
                                        <FieldArray
                                            name="secondarySchoolInfo"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem]">
                                                    {values?.secondarySchoolInfo?.map((secondary, index) => (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                    bottom="2rem"
                                                                    top="1rem"
                                                                >
                                                                    Secondary School {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="gap-[1rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                institutionName: '',
                                                                                startingYear: '',
                                                                                endYear: '',
                                                                                certificate: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="ml-[2.315rem] cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexContainer>
                                                            <Primary
                                                                schoolName={`secondarySchoolInfo.${index}.institutionName`}
                                                                yearStart={`secondarySchoolInfo.${index}.startingYear`}
                                                                yearEnd={`secondarySchoolInfo.${index}.endYear`}
                                                                certificate={`secondarySchoolInfo.${index}.certificate`}
                                                                Delete={index > 0 ? () => remove(index) : null}
                                                                certErrors={errors.secondarySchoolInfo?.certificate}
                                                                nameErrors={errors.secondarySchoolInfo?.institutionName}
                                                                handleChange={handleChange}
                                                                data={Secondarycert.map((item) => ({ name: item }))}
                                                                handleSelect={(selected) => {
                                                                    setFieldValue(
                                                                        `secondarySchoolInfo.${index}.certificate`,
                                                                        selected[0].name,
                                                                    );
                                                                }}
                                                                title="Secondary School"
                                                                placeholder="First Secondary School"
                                                                touched={touched}
                                                                nameValue={secondary.institutionName}
                                                                fromValue={secondary.startingYear}
                                                                toValue={secondary.endYear}
                                                                certValue={secondary.certificate}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>

                                    <SubContainer>
                                        <FieldArray
                                            name="tertiarySchoolInfo"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem]">
                                                    {values?.tertiarySchoolInfo?.map((tertiary, index) => (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                    bottom="2rem"
                                                                    top="1rem"
                                                                >
                                                                    Tertiary Education {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="gap-[1rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                institutionName: '',
                                                                                startingYear: '',
                                                                                endYear: '',
                                                                                certificate: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="ml-[2.315rem] cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexContainer>
                                                            <Primary
                                                                schoolName={`tertiarySchoolInfo.${index}.institutionName`}
                                                                yearStart={`tertiarySchoolInfo.${index}.startingYear`}
                                                                yearEnd={`tertiarySchoolInfo.${index}.endYear`}
                                                                certificate={`tertiarySchoolInfo.${index}.certificate`}
                                                                Delete={index > 0 ? () => remove(index) : null}
                                                                certErrors={errors.tertiarySchoolInfo?.certificate}
                                                                nameErrors={errors.tertiarySchoolInfo?.institutionName}
                                                                handleChange={handleChange}
                                                                data={Tertiarycert.map((item) => ({ name: item }))}
                                                                handleSelect={(selected) => {
                                                                    setFieldValue(
                                                                        `tertiarySchoolInfo.${index}.certificate`,
                                                                        selected[0].name,
                                                                    );
                                                                }}
                                                                title="Tertiary School"
                                                                placeholder="First Tertiary School"
                                                                touched={touched}
                                                                nameValue={tertiary.institutionName}
                                                                fromValue={tertiary.startingYear}
                                                                toValue={tertiary.endYear}
                                                                certValue={tertiary.certificate}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>

                                    <SubContainer>
                                        <FieldArray
                                            name="postGraduateInfo"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem]">
                                                    {values?.postGraduateInfo?.map((post, index) => (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                    bottom="2rem"
                                                                    top="1rem"
                                                                >
                                                                    Post-Graduate {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="gap-[1rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                institutionName: '',
                                                                                startingYear: '',
                                                                                endYear: '',
                                                                                certificate: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="ml-[2.315rem] cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexContainer>
                                                            <Primary
                                                                schoolName={`postGraduateInfo.${index}.institutionName`}
                                                                yearStart={`postGraduateInfo.${index}.startingYear`}
                                                                yearEnd={`postGraduateInfo.${index}.endYear`}
                                                                certificate={`postGraduateInfo.${index}.certificate`}
                                                                Delete={index > 0 ? () => remove(index) : null}
                                                                certErrors={errors.postGraduateInfo?.certificate}
                                                                nameErrors={errors.postGraduateInfo?.institutionName}
                                                                handleChange={handleChange}
                                                                data={PGcert.map((item) => ({ name: item }))}
                                                                handleSelect={(selected) => {
                                                                    setFieldValue(
                                                                        `postGraduateInfo.${index}.certificate`,
                                                                        selected[0].name,
                                                                    );
                                                                }}
                                                                touched={touched}
                                                                nameValue={post.institutionName}
                                                                fromValue={post.startingYear}
                                                                toValue={post.endYear}
                                                                certValue={post.certificate}
                                                                title="Post-Graduate "
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>
                                    <Header className="">
                                        <PageTitle size="1.6rem" lineHeight="2.8rem" align="left">
                                            Awards
                                        </PageTitle>
                                    </Header>
                                    <SubContainer className="pb-[5rem]">
                                        <FieldArray
                                            name="awardsInfo"
                                            render={({ insert, remove, push }) => (
                                                <div className="flex flex-column gap-[1rem] pb-[2rem]">
                                                    {values?.awardsInfo?.map((awards, index) => (
                                                        <div key={index}>
                                                            <FlexContainer>
                                                                <Text
                                                                    align="left"
                                                                    color="#374151"
                                                                    weight="500"
                                                                    lineHeight="2.4rem"
                                                                    bottom="2rem"
                                                                    top="1rem"
                                                                >
                                                                    Award {index + 1}
                                                                </Text>
                                                                <FlexCentredRow className="justify-center gap-[2rem]">
                                                                    <AddButton
                                                                        onClick={() =>
                                                                            push({
                                                                                date: '',
                                                                                name: '',
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusCircle />
                                                                        <Text
                                                                            weight="500"
                                                                            size="1.4rem"
                                                                            color="#3B82F6"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Add Another
                                                                        </Text>
                                                                    </AddButton>

                                                                    <DeleteIcon
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        className="mb-[1rem] cursor-pointer"
                                                                    />
                                                                </FlexCentredRow>
                                                            </FlexContainer>
                                                            <Awards
                                                                year={`awardsInfo.${index}.date`}
                                                                award={`awardsInfo.${index}.name`}
                                                                Delete={index > 0 ? () => remove(index) : null}
                                                                awardErrors={errors.awardsInfo?.name}
                                                                handleChange={handleChange}
                                                                touched={touched}
                                                                awardValue={awards.name}
                                                                yearValue={awards.date}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </SubContainer>
                                </MainContainer>

                                <div className="flow-root my-[3rem]">
                                    <FlexDiv className="float-right gap-[1.45rem]">
                                        <Button type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => setViewDetails(true)}
                                            color="#fff"
                                            bgColor="#6366F1"
                                        >
                                            Next
                                        </Button>
                                    </FlexDiv>
                                </div>
                            </>
                        ) : (
                            <ExperiencePreview item={values} onBtnClick={() => setViewDetails(false)} />
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Experience;
