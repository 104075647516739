import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
// import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
// import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as ScrollIcon } from '../../../assets/icons/scroll.svg';
import { AssignRole } from '../../../components';
// import { SecondaryButton } from '../../../components/buttons';
// import { AllFilter, SingleFilter } from '../../../components/filter-select';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import ListEmptyContent from '../../../components/list-empty';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../containers/ScreenContainers';
import { courseDetailsData } from '../../../data/courseDetails';
import { facultyStudentsData } from '../../../data/facultyStudentsData';
import { capitalizeFirstLetter } from '../../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'name',
    },
    {
        label: 'Matric No.',
        key: 'matric',
    },
];

const courseFields = [
    {
        label: 'S/N',
        key: 'serialNumber',
    },
    {
        label: 'Course Title',
        key: 'courseTitle',
    },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Unit',
        key: 'unit',
    },
    {
        label: 'Status',
        key: 'status',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

// const Details = tw.div`
//     lg:col-span-2
//     col-span-1
//     p-6
//     `;

const FullDetails = tw.div`
    bg-white
    rounded-lg
    // col-span-3
    h-full
    p-0
    // py-6
    mt-[2.4rem]
`;

const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const Students = () => {
    // const navigate = useNavigate();
    const [isLoading /*setIsLoading*/] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(facultyStudentsData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAssignRole, setShowAssignRole] = useState(false);

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <>
            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No Students yet">
                        <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                            {/* <Button bgColor="#10B981" color="#fff">
                                <Circularplus className="mr-[1.15rem]" />
                                Create New
                            </Button>
                            <Button bgColor="#6366F1" color="#fff">
                                <UploadIcon className="mr-[1.15rem]" />
                                Upload Sheet
                            </Button> */}
                        </CenteredContainer>
                    </ListEmptyContent>
                </CenteredContainer>
            ) : (
                <FlexDiv className="gap-4">
                    <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                        <FilterContainer>
                            <FlexDiv>
                                <FlexCentredRow className="mr-[5rem]">
                                    <Text color="#6366F1" weight="500">
                                        2019/2022
                                    </Text>
                                    <ChevronIcon className="ml-[0.9rem]" />
                                </FlexCentredRow>
                                <FlexCentredRow>
                                    <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                                    {/* <SingleFilter name="Department" />
                                    <SingleFilter name="Level" withRadius /> */}
                                </FlexCentredRow>
                            </FlexDiv>

                            {/* <FlexCentredRow>
                                <SecondaryButton>Reset</SecondaryButton>
                                <AllFilter />
                            </FlexCentredRow> */}
                        </FilterContainer>

                        <CDataTable
                            items={facultyStudentsData || []}
                            fields={fields}
                            // striped
                            selectable
                            responsive
                            itemsPerPage={itemsPerPage}
                            activePage={currentPage || 1}
                            clickableRows
                            // onRowClick={(item) => navigate(`/allotted-courses/student/${item.id}`)}
                            loading={isLoading}
                            scopedSlots={{
                                action: (item) => (
                                    <td>
                                        <Checkbox />
                                    </td>
                                ),
                                name: (item) => (
                                    <td style={{ color: '#2563EB' }}>{capitalizeFirstLetter(item.name)}</td>
                                ),
                            }}
                        />
                        <ItemsPerPageCont>
                            <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                            <CPagination
                                activePage={currentPage || 1}
                                onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                                pages={totalPages || 1}
                                align="end"
                            />
                        </ItemsPerPageCont>
                    </div>

                    <FullDetails>
                        <FlexRowSpaceBetween className="mb-[1.5rem] pl-[1.8rem]">
                            <FlexCentredRow>
                                <ScrollIcon className="mr-[1.33rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    Registered Courses
                                </PageTitle>
                            </FlexCentredRow>
                            <FlexCentredRow className="mr-[5rem]">
                                <Text color="#6366F1" weight="500">
                                    2019/2022
                                </Text>
                                <ChevronIcon className="ml-[0.9rem]" />
                            </FlexCentredRow>
                        </FlexRowSpaceBetween>

                        <CDataTable
                            items={courseDetailsData || []}
                            className="pl-[5.8rem]"
                            fields={courseFields}
                            scopedSlots={{
                                status: (item) => (
                                    <td>
                                        {item.status === 'Compulsory' ? (
                                            <StatusContainer>
                                                <Text color="#EF4444" size="1.2rem">
                                                    {item.status}
                                                </Text>
                                            </StatusContainer>
                                        ) : (
                                            <StatusContainer bgColor="#F5F3FF">
                                                <Text color="#8B5CF6" size="1.2rem">
                                                    {item.status}
                                                </Text>
                                            </StatusContainer>
                                        )}
                                    </td>
                                ),
                            }}
                            // striped
                            selectable
                            responsive
                            sorter
                        />
                        <FlexRowSpaceBetween className="px-[1.8rem]">
                            <Text color="#9CA3AF" size="1.6rem">
                                Total Courses:
                                <Text as="span" weight="500">
                                    0/10
                                </Text>
                            </Text>
                            <Text color="#9CA3AF" size="1.6rem">
                                Total Units:
                                <Text as="span" weight="500">
                                    0/10
                                </Text>
                            </Text>
                        </FlexRowSpaceBetween>
                    </FullDetails>
                </FlexDiv>
            )}
            <AssignRole
                show={showAssignRole}
                close={() => setShowAssignRole(false)}
                // assignAction={}
            />
        </>
    );
};

export default Students;
