import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as AssignmentIcon } from '../../../assets/icons/assignment.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import { ActionsPopup } from '../../../components';
import AssignmentAnswer from '../../../components/assignment-answer';
import { Button } from '../../../components/buttons';
import AllocateMark from '../../../components/forms/allocate-mark';
import { GoBack } from '../../../components/go-back';
import SearchInput from '../../../components/inputs/search-input';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexColumn,
    FlexDiv,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { getAssignmentById, getAssignmentSubmissions } from '../../../redux/assignment/actions';

const Card = tw.div`
`;

const ItemBox = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
    border
`;

const Header = tw.div`
    rounded-t-lg
    bg-[white]
    p-[1.6rem]
    mb-[0.2rem]
`;

const ContainerRight = tw.div`
    w-1/2
`;

const AssignmentSubmissions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: assignmentId } = useParams();
    const [searchValue, setSearchValue] = useState('');
    const [openActions, setOpenActions] = useState(false);
    const [showAllocateMark, setShowAllocateMark] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [submissions, setSubmissions] = useState([]);
    const [studentId, setStudentId] = useState('');
    const { assignment, isLoading } = useSelector((store) => store.assignment);
    const [assignmentDetails, setAssignmentDetails] = useState({});

    useEffect(() => {
        if (!assignmentId) return;
        dispatch(getAssignmentById(assignmentId));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, assignmentId]);

    useEffect(() => {
        if (!studentId) return;
        const res = submissions.find((item) => item.studentId === studentId);
        setAssignmentDetails(res);
        //eslint-disable-next-line
    }, [studentId]);

    const getSubmissions = async () => {
        const res = await dispatch(getAssignmentSubmissions(assignmentId));
        if (res) {
            setSubmissions(res);
        }
    };

    useEffect(() => {
        getSubmissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentId, dispatch]);

    if (isLoading) return <Loader />;

    return (
        <>
            <FlexRowSpaceBetween>
                <GoBack title={`${assignment?.courseCode} Assignments`}>
                    <Text size="1.6rem">/ Submissions</Text>
                </GoBack>
                <Button
                    onClick={() => navigate(`/assignments/spreadsheet/${assignmentId}`)}
                    bgColor="#6366F1"
                    color="#FFF"
                >
                    View Spreadsheet
                </Button>
            </FlexRowSpaceBetween>
            <PageTitle align="left" top="2rem" bottom="3rem">
                {assignment?.courseTitle}
            </PageTitle>
            <FlexDiv className="gap-[1rem]">
                <Card className={showPreview ? 'w-1/2' : 'w-full'}>
                    <Header>
                        <FlexRowSpaceBetween>
                            <FlexDiv>
                                <FlexCentredRow className="mr-[5rem]">
                                    <Text weight="600" size="1.9rem" lineHeight="2.8rem">
                                        Submissions
                                    </Text>
                                </FlexCentredRow>
                                <FlexCentredRow>
                                    <SearchInput
                                        withRadius
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        value={searchValue}
                                    />
                                </FlexCentredRow>
                            </FlexDiv>
                            <Text color="#6B7280" lineHeight="2.4rem">
                                50 out of 100 graded
                            </Text>
                        </FlexRowSpaceBetween>
                    </Header>
                    <FlexColumn>
                        {submissions?.map((item) => (
                            <ItemBox key={item.studentId} onClick={() => setStudentId(item.studentId)}>
                                <FlexRowSpaceBetween>
                                    <FlexCentredRow>
                                        <AssignmentIcon className="mr-[8px]" />
                                        <FlexCentredCol>
                                            <FlexDiv className="gap-2" onClick={() => setShowPreview(true)}>
                                                <Text size="1.6rem" lineHeight="2.4rem">
                                                    {item.studentName}
                                                </Text>
                                                <Text size="1.6rem" lineHeight="2.4rem" weight="500">
                                                    - {item.studentMatricNumber}
                                                </Text>
                                            </FlexDiv>
                                            <Text
                                                color="#9CA3AF"
                                                align="left"
                                                size="1.2rem"
                                                weight="500"
                                                lineHeight="2.4rem"
                                            >
                                                {item.assignmentTitle}
                                            </Text>
                                        </FlexCentredCol>
                                    </FlexCentredRow>
                                    <FlexCentredRow className="gap-[1.6rem]">
                                        <FlexCentredCol>
                                            <Text
                                                color="#B91C1C"
                                                size="1.2rem"
                                                weight="500"
                                                lineHeight="2.4rem"
                                                align="right"
                                            >
                                                {item.score} Marks
                                            </Text>

                                            <Text color="#9CA3AF" size="1.2rem" weight="500" lineHeight="2.4rem">
                                                posted {new Date(item.createdAt).toDateString()}
                                            </Text>
                                        </FlexCentredCol>
                                        <RelativeContainer>
                                            <ThreeDotsIcon
                                                className="ml-[1rem] cursor-pointer"
                                                onClick={() => {
                                                    setStudentId(item.studentId);
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions && studentId === item.studentId}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    // {
                                                    //     name: 'Download',
                                                    //     click: () => {
                                                    //         // setShowUploadFile(true);
                                                    //         setOpenActions(false);
                                                    //     },
                                                    // },
                                                    // {
                                                    //     name: 'View',
                                                    //     click: () => {
                                                    //         setShowPreview(true);
                                                    //         setOpenActions(false);
                                                    //     },
                                                    // },
                                                    {
                                                        name: 'Allocate Marks',
                                                        click: () => {
                                                            setShowAllocateMark(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </RelativeContainer>
                                    </FlexCentredRow>
                                </FlexRowSpaceBetween>
                            </ItemBox>
                        ))}
                    </FlexColumn>
                </Card>
                {showPreview && (
                    <ContainerRight>
                        <AssignmentAnswer
                            handleClose={() => setShowPreview(false)}
                            handleExpand={() => navigate('/assignments/view')}
                            assignment={assignmentDetails}
                            showIcons
                        />
                    </ContainerRight>
                )}
            </FlexDiv>
            <AllocateMark
                show={showAllocateMark}
                id={assignmentId}
                studentId={studentId}
                onFinish={() => {
                    getSubmissions();
                    setShowAllocateMark(false);
                }}
                maxNumber={assignment?.totalMarks}
                close={() => setShowAllocateMark(false)}
            />
        </>
    );
};

export default AssignmentSubmissions;
