import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown2 } from '../../assets/icons/arrow-down2.svg';
import { ActionsPopup } from '../../components';
import { Button } from '../../components/buttons';
import Checkbox from '../../components/inputs/checkbox';
import SearchInput from '../../components/inputs/search-input';
import ItemsPerPage from '../../components/items-per-page';
import ListEmptyContent from '../../components/list-empty';
import { PageTitle } from '../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../containers/ScreenContainers';
import { getHandbook } from '../../redux/handbook/action';
import { capitalizeFirstLetter } from '../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Name',
        key: 'title',
    },
    {
        label: 'Description',
        key: 'description',
    },
    {
        label: 'Last Updated',
        key: 'updatedAt',
    },
    {
        label: 'File Size',
        key: 'size',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Handbook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, handbooks } = useSelector((state) => state.handbook);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(handbooks?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [openDropdown, setOpenDropdown] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const { activeSession, sessions } = useSelector((store) => store.admission);
    const [session, setSession] = useState(activeSession || '');

    const sessionItems = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    useEffect(() => {
        if (session && session !== '') {
            dispatch(getHandbook(session));
        }
    }, [dispatch, session]);

    return (
        <>
            <FlexDiv className="mb-6">
                <PageTitle>Handbook</PageTitle>
            </FlexDiv>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow className="gap-6">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown2 className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'sessions'}
                                close={() => setOpenDropdown(null)}
                                items={sessionItems}
                            />
                        </RelativeContainer>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>

                <CDataTable
                    items={handbooks || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Handbook ">
                                <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col"></CenteredContainer>
                            </ListEmptyContent>
                        </CenteredContainer>
                    }
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) =>
                        navigate(`${item?._id}`, {
                            state: {
                                session,
                                details: item,
                            },
                        })
                    }
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox
                                    onChange={() => handleSelect(item)}
                                    checked={selectedItem?._id === item._id}
                                />
                            </td>
                        ),
                        title: (item) => <td style={{ color: '#2563EB' }}>{capitalizeFirstLetter(item?.title)}</td>,
                        size: (item) => <td>{item?.handbookUpload?.size}</td>,

                        updatedAt: (item) => {
                            const dateTimeString = item?.updatedAt;
                            const [datePart, timePart] = dateTimeString.split('T');
                            const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

                            return <td>{formattedDateTime}</td>;
                        },
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </>
    );
};

export default Handbook;
