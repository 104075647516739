import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import pdfSvg from '../../../assets/icons/pdf.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/three-dots.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload5.svg';
import { ActionsPopup, UploadFile, Share, DownloadFile } from '../../../components';
import { Button } from '../../../components/buttons';
import SearchInput from '../../../components/inputs/search-input';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexRowEnd,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
  w-full
  border
  bg-white
`;
const Container = tw.div`
grid
grid-cols-2
`;
const ContainerLeft = tw.div`
    pl-[2.4rem]
    pr-[5.9rem]
    pt-[4.2rem]
    pb-[4.6rem]
    bg-white
    flex
    flex-col
    gap-y-[2.8rem]
`;
const ContainerRight = tw.div`
flex
flex-col
h-full
`;

const HeaderRight = tw.div`
rounded-t-lg
bg-[white]
p-[1.6rem]
mb-[0.2rem]

`;
const Footer = tw.div`
rounded-b-lg
bg-[white]
p-[1.6rem]
bottom-0
w-full

`;
const Details = tw.div`
mb-[0.2rem]
`;
const CardRight = tw.div`
pl-[1.6rem]
pr-[1.9rem]
pb-[0.1rem]
bg-white
mb-[0.2rem]


`;
const Card = tw.div`
    flex
    rounded-[8px]
    pl-4
    pr-3
    border
    items-center
    border-[#C7D2FE]
    border-[.5px]
`;

const Preview = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [openActions, setOpenActions] = useState(false);
    const [showDownloadFile, setShowDownloadFile] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[2rem] ">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px] " />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Learning Resources
                    </PageTitle>
                    <Text size="1.6rem" left="1.4rem">
                        / MEE 405
                    </Text>
                </FlexCentredRow>
                <Button bgColor="#6366F1" color="#fff" onClick={() => setShowUploadFile(true)}>
                    <UploadIcon className="mr-[1.15rem]" />
                    Upload
                </Button>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] rounded-t-lg  pb-[1.8rem]">
                <FilterContainer>
                    <SearchInput
                        containerWidth="100%"
                        width={'100%'}
                        withRadius
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                </FilterContainer>
                <Container>
                    <ContainerLeft>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((_, idx) => (
                            <FlexColumn>
                                <Card>
                                    <img src={pdfSvg} alt="" />
                                    <FlexColumn className="ml-[2rem]">
                                        <Text align="left" weight="500" size="1.6rem">
                                            Mechanical Engineering.pdf{' '}
                                        </Text>
                                        <Text align="left" weight="500" lineHeight="2.4rem" color="#6B7280">
                                            Size - 156.56 MB
                                        </Text>
                                    </FlexColumn>
                                    <RelativeContainer className="ml-auto mr-0">
                                        <MenuIcon
                                            onClick={() => {
                                                setSelectedId(idx);
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions && selectedId === idx}
                                            items={[
                                                {
                                                    icon: <DownloadIcon />,
                                                    name: 'Download',
                                                    click: () => {
                                                        setShowDownloadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    icon: <SendIcon />,
                                                    name: 'Preview',
                                                    click: () => {
                                                        setOpenActions(false);
                                                        navigate('/resources/preview');
                                                    },
                                                },
                                                {
                                                    icon: <ShareIcon />,
                                                    name: 'Share',
                                                    click: () => {
                                                        setShowShare(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    icon: <DeleteIcon />,
                                                    name: 'Delete',
                                                    click: () => {
                                                        setShowDelete(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </RelativeContainer>
                                </Card>
                                <FlexRowSpaceBetween>
                                    <Text weight="500" color="#9CA3AF">
                                        Uploaded on 24 April 2022.
                                    </Text>
                                    <Text weight="500" color="#9CA3AF">
                                        13:00
                                    </Text>
                                </FlexRowSpaceBetween>
                            </FlexColumn>
                        ))}
                    </ContainerLeft>
                    <ContainerRight>
                        <HeaderRight>
                            <FlexCentredRow>
                                <FlexCentredRow>
                                    <DocumentIcon className="mr-[1.033rem]" />
                                    <PageTitle>Advanced Engineering Drawing.pptx</PageTitle>
                                </FlexCentredRow>
                                <CloseIcon
                                    className="m-[2.23rem] cursor-pointer"
                                    //  onClick={onClose}
                                />
                            </FlexCentredRow>
                        </HeaderRight>
                        <CardRight>
                            <Text align="left" weight="500" color="#9CA3AF">
                                Posted Friday, August 19
                            </Text>
                            <Details>
                                <Text weight="600" lineHeight="2.8rem" align="left" size="1.6rem">
                                    Design Assignment
                                </Text>
                                <Text lineHeight="2.8rem" align="left" size="1.6rem">
                                    The goal of this assignment is to exercise your user interface design abilities.
                                    Creativity in balancing usability with the constraints of the interface will be
                                    essential to successfully completing this assignment.
                                    <Text lineHeight="2.8rem" align="left" size="1.6rem" className="mt-[4rem]">
                                        You should design your interface for a circular touchscreen that is 1.5" in
                                        diameter. The resolution of the screen is not defined, but you can assume that
                                        it is high. There are 4 physical buttons on the case at the diagonals. The
                                        screen itself is a button. To make things interesting, there is a physical dial
                                        knob around the screen with full range of motion (think of the wheel on the
                                        original iPod).
                                    </Text>
                                </Text>
                                <Text weight="600" lineHeight="2.8rem" align="left" size="1.6rem">
                                    Grading:
                                </Text>
                                <Text lineHeight="2.8rem" align="left" size="1.6rem">
                                    This assignment is 2% of your overall grade, which has been broken down into 10
                                    points for grading. The breakdown of points is as follows: The screen itself is a
                                    button. To make things interesting, there is a physical dial knob around the screen
                                    with full range of motion (think of the wheel on the original iPod). This assignment
                                    is 2% of your overall grade, which has been broken down into 10 points for grading.
                                    The breakdown of points is as follows: The screen itself is a button.
                                </Text>
                            </Details>
                        </CardRight>
                        <Footer>
                            <FlexRowEnd>
                                <Button
                                    bgColor="#6366F1"
                                    color="white"
                                    onClick={() => navigate('/resources/open-preview')}
                                >
                                    Open
                                </Button>
                            </FlexRowEnd>
                        </Footer>
                    </ContainerRight>
                </Container>
            </div>

            <DownloadFile show={showDownloadFile} setShow={setShowDownloadFile} />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
            <Share show={showShare} setShow={setShowShare} />
            <ConfirmActionDialogue
                show={showDelete}
                title="Dele"
                subtitle="Are you sure you?"
                close={() => setShowDelete(false)}
                btn2Text="Confirm"
            />
        </div>
    );
};

export default Preview;
