import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/circular-plus-black.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const CreateVersion = ({ show, close, confirmAction, isLoading }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowEnd>
                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowEnd>

            <FlexCentredRow className="gap-[1.215rem] mt-[10px]">
                <PlusIcon />
                <Text as="h3" size="1.6rem" weight="600">
                    Create Version
                </Text>
            </FlexCentredRow>

            <Text align="left" size="1.6rem" bottom="2.4rem" color="#374151" top="1.6rem">
                You're about to create a new version from the current version, do you want to proceed?
            </Text>
            <Text align="left" size="1.6rem" color="#374151">
                The new version needs to be sent to CA before it can be promoted to the latest version.
            </Text>

            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <CancelButton onClick={close} disabled={isLoading}>
                    Cancel
                </CancelButton>
                <LoadingButton loading={isLoading} disabled={isLoading} onClick={confirmAction}>
                    Send
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default CreateVersion;
