import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const examsSlice = createSlice({
    name: 'exams',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const { LOADING, STOP_LOADING } = examsSlice.actions;

export default examsSlice.reducer;
