import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../assets/icons/arrow-down2.svg';
import { ActionsPopup } from '../../components';
import { Button } from '../../components/buttons';
import ItemsPerPage from '../../components/items-per-page';
import ListEmptyContent from '../../components/list-empty';
import { PageTitle } from '../../containers/MesssageContainers';
import {
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    FlexCentredRow,
    RelativeContainer,
    CenteredContainer,
} from '../../containers/ScreenContainers';
import { getAllotedCourses, getAllottedSessions } from '../../redux/alloted-courses/actions';

const fields = [
    {
        label: 'Course Title',
        key: 'courseTitle',
    },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Units',
        key: 'courseUnit',
    },
    {
        label: 'Registered Students',
        key: 'students',
    },
];

const SEMESTER_LOOKUP = {
    'First Semester': 'first',
    'Second Semester': 'second',
};

const AllotedCoursesList = ({ pageTitle = 'Allotted Courses' }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { courses, isLoading, allottedSessions = [] } = useSelector((state) => state.allotedCourses);
    const { activeSemester } = useSelector((store) => store.admission);

    const [session, setSession] = useState(allottedSessions[0]?.session || '');
    const [semester, setSemester] = useState(activeSemester || 'First Semester');
    const [openDropdown, setOpenDropdown] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(courses?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = allottedSessions.map((item) => ({
        name: item.session,
        click: () => {
            setSession(item.session);
            setOpenDropdown(null);
        },
    }));

    const actionItemSemester = ['First Semester', 'Second Semester'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenDropdown(null);
        },
    }));

    useEffect(() => {
        if (!session || !semester) return;
        const semesterParam = SEMESTER_LOOKUP[semester];

        dispatch(getAllotedCourses(session, semesterParam));
    }, [dispatch, session, semester]);

    useEffect(() => {
        dispatch(getAllottedSessions());
    }, [dispatch]);

    return (
        <div>
            <FlexRowSpaceBetween>
                <PageTitle>{pageTitle}</PageTitle>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] p-[1.8rem]">
                <FlexCentredRow className="gap-3 pb-4">
                    <RelativeContainer>
                        <Button
                            color="#6B7280"
                            border="1px solid #9CA3AF"
                            onClick={() => handleOpenDropdown('sessions')}
                        >
                            {session || 'Session'}
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'sessions'}
                            close={() => setOpenDropdown(null)}
                            items={actionItemSessions}
                        />
                    </RelativeContainer>
                    <RelativeContainer>
                        <Button
                            color="#6B7280"
                            border="1px solid #9CA3AF"
                            onClick={() => handleOpenDropdown('semester')}
                        >
                            {`${semester}` || 'Semester'}
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'semester'}
                            close={() => setOpenDropdown(null)}
                            items={actionItemSemester}
                        />
                    </RelativeContainer>
                </FlexCentredRow>

                <CDataTable
                    items={courses || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => navigate(`${item._id}`, { state: { course: item, session } })}
                    scopedSlots={{
                        courseTitle: (item) => <td style={{ color: '#2563EB' }}>{item.courseTitle}</td>,
                    }}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Course(s) yet"></ListEmptyContent>
                        </CenteredContainer>
                    }
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </div>
    );
};

export default AllotedCoursesList;
