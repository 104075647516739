/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import { getAllSent, getSentMail, getSentNotice } from '../../../redux/communications/actions';

import SentAll from './tabs/SentAll';
import SentLetters from './tabs/SentLetters';
import SentMail from './tabs/SentMail';
import SentNotice from './tabs/SentNotice';

const Sent = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSentMail());
        dispatch(getSentNotice());
        dispatch(getAllSent());
    }, [dispatch]);

    const handleActionClick = (route) => {
        navigate(route);
        setDropdownOpen(false);
    };

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Sent</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown className="ml-[13.15px]" />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        items={[
                            {
                                name: 'Letters',
                                click: () => handleActionClick('letter'),
                            },
                            {
                                name: 'Notices',
                                click: () => handleActionClick('notice'),
                            },
                            {
                                name: 'Mail',
                                click: () => handleActionClick('mail'),
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>All</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Letters</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Notices</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Mail</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <SentAll />
                    </CTabPane>
                    <CTabPane>
                        <SentLetters />
                    </CTabPane>
                    <CTabPane>
                        <SentNotice />
                    </CTabPane>
                    <CTabPane>
                        <SentMail />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Sent;
