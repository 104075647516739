import React from 'react';

import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { ReactComponent as Link } from '../../../assets/icons/link-blue.svg';
import TextInput from '../../inputs/text-input-with-formik';
import FormCard from '../form-card';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddNewLink = ({ show, setShow }) => {
    const onClose = () => setShow(false);

    return (
        <div
            className="absolute bottom-[5rem] left-0 z-[1000] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]"
            headerIcon={<Link />}
            close={onClose}
            show={show}
            title={'Add New Link'}
        >
            <FormCard>
                <Formik
                    initialValues={{
                        email: 'red',
                        text: '',
                        confirmtext: '',
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={(values, actions) => {
                        console.log('first');
                    }}
                >
                    {({
                        errors,
                        // handleChange,
                        // handleBlur,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div className="gap-[3.2rem] flex flex-col">
                                <TextInput
                                    name="display text"
                                    type="text"
                                    placeholder="Text to display"
                                    errors={errors}
                                />
                                <TextInput name="link" type="text" placeholder="Enter link URL" errors={errors} />
                            </div>
                            <FlexContainer>
                                <CancelButton onClick={onClose}>Cancel</CancelButton>
                                <SubmitButton type="submit">Ok</SubmitButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormCard>
        </div>
    );
};

export default AddNewLink;
