import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import DateInput from '../../../components/inputs/date-input';
import Select from '../../../components/inputs/new-select';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editAssignment } from '../../../redux/assignment/actions';
import { formatTime } from '../../../utils';

import { ValidationSchema } from './ValidationSchema';

const FirstStepBox = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]

`;
const GridRows = tw.div`
    grid
    grid-cols-2
    gap-x-[32px]
    gap-y-[24px]
    mb-10
`;

const assignmentTypes = [
    { name: 'Score only (Assignments graded outside the platform)', value: 'Score Only' },
    // { name: 'Text and Upload', value: 'Text and upload' },
    // { name: 'Text Only', value: 'Text only' },
    // { name: 'Upload Only', value: 'Upload only' },
];

const EditAssignment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { state } = useLocation();
    const { assignment } = state || {};

    const { isLoading } = useSelector((state) => state.assignment);

    return (
        <>
            <GoBack title={`Assignments`} subTitle={`/ ${assignment?.courseCode}(${assignment?.courseTitle})`} />
            <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                Edit Assignment
            </PageTitle>

            <Formik
                initialValues={{
                    // change this to a select option later when needed
                    assignmentType: 'Text only',
                    assignmentCategory: assignment?.assignmentCategory || '',
                    title: assignment?.title || '',
                    deadlineDate: assignment?.deadlineDate || '',
                    deadlineTime: assignment.deadlineTime || '',
                    totalMarks: assignment.totalMarks || '',
                    testOption: assignment.testOption || '',
                    // optionalAttachment: [],
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const formatedDate = values.deadlineDate.slice(0, 10);
                    const formatedTime = formatTime(values.deadlineTime);

                    const payload = {
                        ...values,
                        deadlineDate: formatedDate,
                        deadlineTime: formatedTime,
                    };

                    const res = await dispatch(editAssignment(id, payload));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldError, setFieldValue }) => (
                    <Form>
                        <FirstStepBox>
                            <FlexCentredRow className="gap-2 mb-8">
                                <PaperIcon />
                                <Text weight="600" size="1.6rem">
                                    Edit Assignment
                                </Text>
                            </FlexCentredRow>

                            <GridRows>
                                <Select
                                    name="assignmentCategory"
                                    objProp="name"
                                    label="Assignment Type"
                                    error={errors.assignmentCategory}
                                    placeholder="Type"
                                    data={assignmentTypes}
                                    useComponentState={false}
                                    passedSelectedItems={
                                        values?.assignmentCategory ? [{ name: values?.assignmentCategory }] : null
                                    }
                                    onChange={(selected) => {
                                        setFieldValue('assignmentCategory', selected[0].value);
                                    }}
                                />
                                <TextInput
                                    label="Title"
                                    name="title"
                                    type="text"
                                    placeholder="Title"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.title}
                                />
                                <DateInput
                                    label="Deadline Date"
                                    name="deadlineDate"
                                    onChange={handleChange}
                                    errors={errors}
                                    value={values.deadlineDate.slice(0, 10)}
                                />
                                <TextInput
                                    label="Deadline Time"
                                    name="deadlineTime"
                                    type="time"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.deadlineTime}
                                />
                                <Select
                                    name="testOption"
                                    label="Continous Assessment"
                                    objProp="name"
                                    passedSelectedItems={values.testOption}
                                    placeholder="Continous Assessment"
                                    data={['Yes', 'No'].map((item) => ({ name: item }))}
                                    onChange={(selected) => {
                                        setFieldValue('testOption', selected[0].name);
                                    }}
                                    error={errors.testOption}
                                />
                                <TextInput
                                    label="Total Marks"
                                    name="totalMarks"
                                    type="number"
                                    placeholder="Total Marks"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.totalMarks}
                                />
                            </GridRows>
                            <FlexRowEnd className="gap-6">
                                <Button
                                    onClick={() => navigate(-1)}
                                    border="1px solid #D1D5DB"
                                    className=" border-[#D1D5DB]"
                                    type="button"
                                >
                                    Cancel
                                </Button>

                                <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                    Edit Assignment
                                </LoadingButton>
                            </FlexRowEnd>
                        </FirstStepBox>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditAssignment;
