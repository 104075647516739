export const courseDetailsData = [
  {
    serialNumber: 1,
    courseTitle: "Introduction To Engineering Drawing",
    courseCode: "MEE145643",
    unit: 4,
    total: null,
    percentage: 42.5,
    grade: 'B',
    status: 'Compulsory'
  },
  {
    serialNumber: 2,
    courseTitle: "Introduction To Engineering Drawing",
    courseCode: "MEE145643",
    unit: 4,
    total: null,
    percentage: 42.5,
    grade: 'B',
    status: 'Compulsory'
  },
  {
    serialNumber: 3,
    courseTitle: "Introduction To Engineering Drawing",
    courseCode: "MEE145643",
    unit: 4,
    total: null,
    percentage: 42.5,
    grade: 'B',
    status: 'Required'
  },
  {
    serialNumber: 4,
    courseTitle: "Introduction To Engineering Drawing",
    courseCode: "MEE145643",
    unit: 4,
    total: null,
    percentage: 42.5,
    grade: 'B',
    status: 'Required'
  },
  {
    serialNumber: 5,
    courseTitle: "Introduction To Engineering Drawing",
    courseCode: "MEE145643",
    unit: 4,
    total: null,
    percentage: 42.5,
    grade: 'B',
    status: 'Compulsory'
  },
];