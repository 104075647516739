import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import InputContainer from '../../../components/Container/inputcontainer';
import ResetPasswordForm from '../../../components/forms/reset-password';
import SuccessPopup from '../../../components/popups/success';
import { PoweredBy } from '../../../components/poweredby';
import { Container } from '../sign-in-new';


const ResetPassword = () => {
    const navigate = useNavigate();
    const [openSuccess, setOpenSuccess] = useState(false);

    return (
        <Container>

            <InputContainer>

            <ResetPasswordForm setOpenSuccess={setOpenSuccess} />
           
                <PoweredBy />

                <SuccessPopup
                show={openSuccess}
                title="Awesome"
                onClose={() => navigate('/sign-in')}
                subtitle="Your password has been changed successfully, login to continue"
                btnText="Login"
            />

            </InputContainer>
        </Container>
    );
};
export default ResetPassword;
