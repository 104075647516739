import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as LeftImage } from '../../../assets/icons/oblique.svg';
import { Button } from '../../../components/buttons';
import InputContainer from '../../../components/Container/inputcontainer';
import { Text } from '../../../containers/MesssageContainers';
import { Container } from '../sign-in-new';


const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
const AcceptStaff = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const token = params.get('token');
    const institution = params.get('institution');
    const username = params.get('username');


    return (
        <Container>
            <InputContainer>
            <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                <LeftImage className="absolute left-0 z-0" />
                <Text weight="600" align="left" lineHeight="3.4rem" bottom="8.1rem" color="#3730A3" size="2.3rem">
                    You have been added to {capitalizeFirstLetter(institution)}!
                </Text>
                    <Text weight="600" align="left" size="3.3rem" bottom="2.4rem">
                    Hi {capitalizeFirstLetter(username)} 👋🏼
                </Text>

                    <Text size="2.3rem" align="left" bottom="4.8rem">
                    {institution} has created a dashboard for you on the Lecturer’s portal. Click the button below to
                    verify your email and create a new password.
                </Text>
                    <Button
                    onClick={() => navigate(`/reset-password/${token}`)}
                    bgColor="#6366F1"
                    color="white"
                    className="py-2 px-4 cursor-pointer"
                >
                    Reset Password
                </Button>
                </div>
            </InputContainer>
        </Container>
    );
};

export default AcceptStaff;
