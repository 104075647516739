import { styled } from 'twin.macro';

export const StatusContainer = styled.div`
    background-color: ${({ bgColor }) => bgColor || '#ECFDF5'};
    color: ${({ color }) => color || '#059669'};
    padding: 2px 4px;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 4px;
    line-height: 1.7rem;
    width: fit-content;
`;

export const StatusText = styled.span`
    font-size: ${({ size }) => size || '1.2rem'};
    font-weight: ${({ weight }) => weight || '500'};
    color: inherit;
    text-transform: capitalize;
`;

export const getStatusStyles = {
    open: {
        color: '#059669',
        backgroundColor: '#ECFDF5',
    },
    closed: {
        color: '#DC2626',
        backgroundColor: '#FEF2F2',
    },
    suspended: {
        color: '#F59E0B',
        backgroundColor: '#FFFBEB',
    },
    in_active: {
        color: '#F59E0B',
        backgroundColor: '#FFFBEB',
    },
    pending: {
        color: '#F59E0B',
        backgroundColor: '#FFFBEB',
    },
    accepted: {
        color: '#059669',
        backgroundColor: '#ECFDF5',
    },
    active: {
        color: '#059669',
        backgroundColor: '#ECFDF5',
    },
    expelled: {
        color: '#DC2626',
        backgroundColor: '#FEF2F2',
    },

    rejected: {
        color: '#DC2626',
        backgroundColor: '#FEF2F2',
    },
    deactivated: {
        color: '#DC2626',
        backgroundColor: '#FEF2F2',
    },
    sent: {
        color: '#F59E0B',
        backgroundColor: '#FFFBEB',
    },
    'not sent': {
        color: '#1F2937',
        backgroundColor: '#F3F4F6',
    },
    compulsory: {
        color: '#2563EB',
        backgroundColor: '#EEF2FF',
    },
    elective: {
        color: '#BE185D',
        backgroundColor: '#FFFBEB',
    },
    required: {
        color: '#4F46E5',
        backgroundColor: '#EFF6FF',
    },
};
