import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING, SAVE_ASSIGNMENTS, GET_ASSIGNMENT, GET_COURSE_ASSIGNMENTS } from './slice';

export const getAllAssignments = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/all-assignment`);
        if (response.status === 200) {
            dispatch(SAVE_ASSIGNMENTS(response.data.assignments));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignments = (courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/get-assignment/${courseId}`);
        if (response.status === 200) {
            dispatch(SAVE_ASSIGNMENTS(response.data.assignments));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCourseAssignments = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/get-assignment-course/${session}/${courseId}`,
        );
        if (response.status === 200) {
            dispatch(GET_COURSE_ASSIGNMENTS(response.data.data));
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCourseDrafts = (courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/draft/course/${courseId}`);
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentSubmissions = (assignmentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/assignment-answer/${assignmentId}`);
        if (response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteAssignment = (assignmentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}assignment/delete-assignment/${assignmentId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
        return false;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDraftAssignment = (draft) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const { _id: assignmentId, courseId } = draft;
        const response = await axiosInstance.delete(`${currentAPI}assignment/draft/${courseId}/${assignmentId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
        return false;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createAssignment = (courseId, session, payload, draftId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            // `${currentAPI}assignment/create-assignment/${session}/${courseId}?draftID=${draftId}`,
            `${currentAPI}assignment/create-assignment/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Assignment created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editAssignment = (assignmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}assignment/edit-assignment/${assignmentId}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDraftAssignment = (courseId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}assignment/draft/${courseId}`, payload);
        if (response.status === 200 || response.status === 201) {
            toast.success('Draft assignment created successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDraftAssignment = (assignmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}assignment/draft/${assignmentId}`, payload);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const extendDeadline = (assignmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}assignment/edit-deadline/${assignmentId}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const allotMark = (studentId, assignmentId, payload, version) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/allot-mark/${studentId}/${assignmentId}/${version}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Assignment marked successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentById = (id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/get-assignment/${id}`);
        if (response.status === 200) {
            dispatch(GET_ASSIGNMENT(response.data.assignment));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDraftById = (id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/draft/${id}`);
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentSpreadsheet = (session, courseId, assignmentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/assignment-spreadsheet/${session}/${courseId}/${assignmentId}`,
        );
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentScoresheet = (session, courseId, version) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/assignment-scoresheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createNewAssignmentVersion = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/create-new-assignment-version/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('New version created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const promoteAssignmentVersion = (courseId, session, latestVersion, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/promote-assignment-to-latest-version/${courseId}/${session}/${latestVersion}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Version promoted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendAssignmentToBroadsheet = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/send-assignment-scoresheet-to-broadsheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('Scoresheet was sent to CA successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentVersions = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/assignment-version-history/${courseId}/${session}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
