import React, { useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
// import { ReactComponent as UploadIcon } from '../../../assets/icons/upload4.svg';

import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down3.svg';
import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload4.svg';
import { ActionsPopup, ChooseColumnsToExport, ExportSheetDialogue, UploadFile } from '../../components';
import { Button, SecondaryButton } from '../../components/buttons';
import { AllFilter, SingleFilter } from '../../components/filter-select';
import Checkbox from '../../components/inputs/checkbox';
import SearchInput from '../../components/inputs/search-input';
import ItemsPerPage from '../../components/items-per-page';
import ListEmptyContent from '../../components/list-empty';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'title',
    },
    {
        label: 'Matric No.',
        key: 'code',
    },
    {
        label: 'GPA',
        key: 'department',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const DropdownBox = tw(FlexCentredRow)`
    rounded-full
    h-[4rem]
    gap-[8px]
    px-[24px]
    py-[8px]
    border-[0.5px]
    border-[#D1D5DB]
`;

const Results = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);
    const totalPages = Math.ceil([]?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[2rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <PageTitle>Results</PageTitle>
                </FlexCentredRow>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Actions
                        <Dropdown className="ml-[13.15px]" />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        close={() => setDropdownOpen(false)}
                        items={[
                            {
                                icon: <UploadIcon />,
                                name: 'Upload Sheet',
                                click: () => {
                                    setShowUploadFile(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                icon: <ExportIcon />,
                                name: 'Export Sheet',
                                click: () => {
                                    setShowExportSheetDialogue(true);
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                        <SingleFilter name="Department" />
                        <SingleFilter name="Admission Year" />
                        <SingleFilter name="Level" withRadius />
                    </FlexCentredRow>

                    <FlexCentredRow>
                        <SecondaryButton>Reset</SecondaryButton>
                        <AllFilter />
                    </FlexCentredRow>
                </FilterContainer>
                <FlexCentredRow className="pl-[3.2rem] mb-[1.6rem] gap-[4.2rem]">
                    <DropdownBox>
                        <Text>1st Semester - 2019/2022 Session</Text>
                        <ArrowDownIcon />
                    </DropdownBox>
                    <DropdownBox>
                        <Text>300 Level</Text>
                        <ArrowDownIcon />
                    </DropdownBox>
                </FlexCentredRow>
                <CDataTable
                    items={[]}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent title="No results yet"></ListEmptyContent>
                        </CenteredContainer>
                    }
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        title: (item) => <td style={{ color: '#2563EB' }}>{capitalizeFirstLetter(item.title)}</td>,
                        department: (item) => <td style={{ color: '#2563EB' }}>{item.department}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default Results;
