import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';

import AcadCalender from './tabs/AcadCalender';
import ExamTimetable from './tabs/Exam';
import LectureTimetable from './tabs/Lecture';

const Schedule = () => {
    const [, /*tab*/ setTab] = useState(0);

    return (
        <div>
            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Time-table</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Exam-Time-table</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(2)}>Academic Calendar</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <LectureTimetable />
                    </CTabPane>
                    <CTabPane>
                        <ExamTimetable />
                    </CTabPane>
                    <CTabPane>
                        <AcadCalender />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Schedule;
