import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../assets/icons/arrow-down2.svg';
import { Button } from '../../../components/buttons';
import Calender from '../../../components/Calender';
import { getLectureTimetable } from '../../../redux/schedule/actions';
import { generateWeeklyEvents } from '../../../utils';
import CalenderDoc from '../calenderDoc';
import CalenderToggle from '../toggleCalender';

const MainContainer = tw.div`
    relative
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    mt-[2.4rem]
    rounded-lg
`;

const LectureTimetable = () => {
    const dispatch = useDispatch();
    const {
        lectureTimetable,
        mondayTimetable,
        tuesdayTimetable,
        wednesdayTimetable,
        thursdayTimetable,
        fridayTimetable,
    } = useSelector((store) => store.schedule);
    const [viewActions, setViewActions] = useState(false);
    const [documentView, setDocumentView] = useState(false);
    let allEvents = [];

    useEffect(() => {
        dispatch(getLectureTimetable());
    }, [dispatch]);

    const handleDocClick = () => {
        setDocumentView(true);
        setViewActions(false);
    };

    const handleCalClick = () => {
        setDocumentView(false);
        setViewActions(false);
    };

    lectureTimetable?.forEach((event) => {
        const events = generateWeeklyEvents(event);
        allEvents = [...allEvents, ...events];
    });

    return (
        <>
            <MainContainer>
                <Button
                    className="ml-auto"
                    color="#6B7280"
                    border="1px solid #9CA3AF"
                    onClick={() => setViewActions(!viewActions)}
                >
                    View
                    <Dropdown className="ml-[13.15px]" />
                </Button>
                {viewActions && <CalenderToggle onDocumentClick={handleDocClick} onCalenderClick={handleCalClick} />}

                {documentView ? (
                    <CalenderDoc
                        mondayTimetable={mondayTimetable}
                        tuesdayTimetable={tuesdayTimetable}
                        wednesdayTimetable={wednesdayTimetable}
                        thursdayTimetable={thursdayTimetable}
                        fridayTimetable={fridayTimetable}
                    />
                ) : (
                    <Calender eventsList={allEvents} />
                )}
            </MainContainer>
        </>
    );
};

export default LectureTimetable;
