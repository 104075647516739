import React, { useState } from 'react';

import { CDataTable } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ChevronIcon } from '../../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as People } from '../../../../assets/icons/people2.svg';
import { ReactComponent as ScrollIcon } from '../../../../assets/icons/scroll.svg';
import { ReactComponent as ProfilePicture } from '../../../../assets/images/profile-picture.svg';
import { Button } from '../../../../components/buttons';
import EditStudentRecordsForm from '../../../../components/forms/edit-student-records';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexDiv } from '../../../../containers/ScreenContainers';
import { courseDetailsData } from '../../../../data/courseDetails';

const MainContainer = tw.div`
    flex-col
    flex
    gap-6
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-3
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-3
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const FullDetails = tw(Details)`
    p-0
    py-6
`;

const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const fields = [
    {
        label: 'S/N',
        key: 'serialNumber',
    },
    {
        label: 'Course Title',
        key: 'courseTitle',
    },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Unit',
        key: 'unit',
    },
    {
        label: 'Status',
        key: 'status',
    },
];

const BasicInfo = () => {
    const [showForm, setShowForm] = useState(false);
    return (
        <div>
            <MainContainer>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Lecturer Details
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexDiv className="justify-end gap-[3.2rem]">
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Active
                                </Text>
                            </Button>
                            <Button bgColor="#fff" onClick={() => setShowForm(true)}>
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Edit
                                </Text>
                            </Button>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <ProfilePicture />
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <DetailsCardTitleText>Full Name</DetailsCardTitleText>
                                <DetailsCardSubText>Emmanuel Olukunle Peter</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>School</DetailsCardTitleText>
                                <DetailsCardSubText>Engineering</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText>Email</DetailsCardTitleText>
                                <DetailsCardSubText>olukunleemmanuel@gmail.com</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <DetailsCardTitleText align="right">Department</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Mechanical Engineering</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Registration</DetailsCardTitleText>
                                <DetailsCardSubText align="right">38011955FB</DetailsCardSubText>
                            </FlexCol>
                            <FlexCol>
                                <DetailsCardTitleText align="right">Admission Mode</DetailsCardTitleText>
                                <DetailsCardSubText align="right">Direct Entry</DetailsCardSubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>

                <FullDetails>
                    <FlexRowSpaceBetween className="mb-[1.5rem] pl-[1.8rem]">
                        <FlexCentredRow>
                            <ScrollIcon className="mr-[1.33rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Allotted Courses
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexCentredRow className="mr-[5rem]">
                            <Text color="#6366F1" weight="500">
                                2019/2022
                            </Text>
                            <ChevronIcon className="ml-[0.9rem]" />
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <CDataTable
                        items={courseDetailsData || []}
                        className="pl-[5.8rem]"
                        fields={fields}
                        scopedSlots={{
                            status: (item) => (
                                <td>
                                    {item.status === 'Compulsory' ? (
                                        <StatusContainer>
                                            <Text color="#EF4444" size="1.2rem">
                                                {item.status}
                                            </Text>
                                        </StatusContainer>
                                    ) : (
                                        <StatusContainer bgColor="#F5F3FF">
                                            <Text color="#8B5CF6" size="1.2rem">
                                                {item.status}
                                            </Text>
                                        </StatusContainer>
                                    )}
                                </td>
                            ),
                        }}
                        // striped
                        selectable
                        responsive
                        sorter
                    />
                    <FlexRowSpaceBetween className="px-[1.8rem]">
                        <Text color="#9CA3AF" size="1.6rem">
                            Total Courses:
                            <Text as="span" weight="500">
                                0/10
                            </Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Total Units:
                            <Text as="span" weight="500">
                                0/10
                            </Text>
                        </Text>
                    </FlexRowSpaceBetween>
                </FullDetails>
            </MainContainer>
            {showForm && <EditStudentRecordsForm show={showForm} setShow={setShowForm} />}
        </div>
    );
};

export default BasicInfo;
