import tw from 'twin.macro';

import DatePicker from '../../../components/inputs/date-picker';
import Select from '../../../components/inputs/new-select';
import TextInput from '../../../components/inputs/text-input';
import { Text } from '../../../containers/MesssageContainers';

const GridContainer = tw.div`
bg-white
grid 
md:grid-cols-2  
justify-between
items-center
gap-[2rem] 
md:gap-[2rem] 
w-[100%]
`;

const Primary = ({
    schoolName,
    title,
    yearStart,
    yearEnd,
    certificate,
    certErrors,
    nameErrors,
    handleChange,
    handleSelect,
    nameValue,
    certValue,
    touched,
    data,
    fromValue,
    toValue,
}) => {
    return (
        <div>
            <Text align="left" color="#374151" weight="500" lineHeight="2.4rem" className="md:hidden">
                {title}
            </Text>
            <GridContainer>
                <DatePicker
                    useYear
                    name={yearStart}
                    placeholder="Starting Year"
                    label="Starting Year"
                    touched={touched}
                    initialValue={fromValue ? new Date(fromValue) : null}
                />
                <DatePicker
                    useYear
                    name={yearEnd}
                    placeholder="End Year"
                    label="End Year"
                    touched={touched}
                    initialValue={toValue ? new Date(toValue) : null}
                />

                <Select
                    name={certificate}
                    objProp="name"
                    data={data}
                    onChange={handleSelect}
                    placeholder="Certificate"
                    label="Certificate"
                    errors={certErrors}
                    passedSelectedItems={certValue}
                />
                <TextInput
                    name={schoolName}
                    type="text"
                    placeholder="Institution"
                    label="Institution"
                    onChange={handleChange}
                    errors={nameErrors}
                    value={nameValue}
                />
            </GridContainer>
        </div>
    );
};

export default Primary;
