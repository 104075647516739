import React from "react";

import tw, { styled } from "twin.macro";

import { CentredBox } from "../../containers/ScreenContainers";

const ItemsPerPageSelect = styled.select(() => [
  `border: 1px solid #E5E7EB;
    border-radius: 4px;
    height: 32px;
    width: 68px;
    padding: 4px 12px;
    color: #1F2937;
    font-size: 14px;
    `,
  tw`
    outline-none
    `,
]);

const ItemsText = styled.p`
  color: #9ca3af;
  font-size: 1.6rem;
  margin-right: 2.3rem;
`;

const ItemsPerPage = ({ options, setItemsPerPage }) => {
  return (
    <CentredBox>
      <ItemsText>Items per page:</ItemsText>
      <ItemsPerPageSelect onChange={(e) => setItemsPerPage(+e.target.value)}>
        {options.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </ItemsPerPageSelect>
    </CentredBox>
  );
};

export default ItemsPerPage;
