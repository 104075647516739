import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    title: Yup.string().nullable().required('Required'),
    // assignmentType: Yup.string().nullable().required('Required'),
    assignmentCategory: Yup.string().nullable().required('Required'),
    // question: Yup.string().nullable().required('Required'),
    deadlineDate: Yup.string().nullable().required('Required'),
    deadlineTime: Yup.string().nullable().required('Required'),
    totalMarks: Yup.string().nullable().required('Required'),
    testOption: Yup.string().nullable().required('Required'),
    // optionalAttachment: Yup.string().nullable().required('Required'),
});
