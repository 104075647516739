import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import preset from '../../../assets/icons/arrow-back-blue.svg';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { resetPassword } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './validationSchema';

const ResetPasswordForm = ({ setOpenSuccess }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();

    const isLoading = useSelector((state) => state.auth.isLoading);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(resetPassword(token, values));
                    if (res) {
                        actions.resetForm();
                        setOpenSuccess(true);
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <div className="">
                                <img src={preset} alt="preset" onClick={() => navigate('/sign-in')} className="mb-10" />
                                <div className="">
                                    <h3 className="font-semibold text-[#1F2937] text-[19px] mb-2">Choose New Password</h3>

                                    <p  className="font- text-[#9CA3AF] text-[12px] mb-10">
                                       Choose a new password
                                    </p>
                                </div>

                                <div className="grid grid-row-6 gap-6">
                                    <TextInput
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="New Password"
                                        errors={errors}
                                        inputType="password"
                                        onChange={handleChange}
                                        togglePassword={() => setShowPassword((prevState) => !prevState)}
                                    />
                                    <TextInput
                                        name="confirmPassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder="Confirm New Password"
                                        errors={errors}
                                        inputType="password"
                                        onChange={handleChange}
                                        togglePassword={() => setShowConfirmPassword((prevState) => !prevState)}
                                    />
                                </div>
                            </div>

                            <FlexRowEnd className="mt-12 mb-[1.9rem]">
                                <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                    Reset Password
                                </LoadingButton> 
                            </FlexRowEnd>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ResetPasswordForm;
