import React from 'react';

// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import tw from 'twin.macro';

import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-sm.svg';
import { Text, PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexColumn, FlexCentredCol } from '../../../containers/ScreenContainers';

const MainContainer = tw.div`
    bg-white
    p-[2rem]
    mb-[2rem]
    rounded-[2rem]
    max-w-[1020px]
`;

const ListItems = [
    {
        title: 'Score Distribution',
        subtitle: 'Set Score Distribution for each course',
        link: 'score-distribution',
    },
];

const BroadsheetSettings = () => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    return (
        <>
            <FlexColumn className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle align="start">Settings</PageTitle>
            </FlexColumn>
            {ListItems.map((item, i) => (
                <MainContainer key={i} className="hover:cursor-pointer" onClick={() => navigate(item.link)}>
                    <FlexRowSpaceBetween>
                        <FlexCentredCol>
                            <Text align="left" weight="700" size="1.8rem">
                                {item.title}
                            </Text>
                            <Text as="span" align="left" size="1.4rem" weight="400">
                                {item.subtitle}
                            </Text>
                        </FlexCentredCol>

                        <ArrowRight className="mr-[8px]" />
                    </FlexRowSpaceBetween>
                </MainContainer>
            ))}
        </>
    );
};

export default BroadsheetSettings;
