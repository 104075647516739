import React, { useState, useRef, Fragment, useMemo } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as SelectIcon } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search2.svg';
import useSearch from '../../../hooks/useSearch';
import Checkbox from '../checkbox';
const SearchInput = tw.input`
    w-full
    px-4
    py-2
    relative
    focus:outline-none
    outline-none
    rounded
    border
    placeholder:text-[1.4rem]
    placeholder:text-[#6B7280]
    border-[#e5e7eb]
    focus:ring-[#6366F1]
    focus:ring-2
    cursor-pointer
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 20px;
`;

const Select = ({
    placeholder = '',
    name = 'select',
    data,
    objProp = '',
    multiSelect,
    passedSelectedItems,
    onChange,
    useComponentState = true,
    error,
    label,
    isLoading = false,
    containerClass = '',
    borderRadius = 'rounded',
    noBorder,
    disabled,
    searchable = false,
    searchPlaceholder = 'Search',
}) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const results = useSearch(searchValue, data, [objProp]);

    const initialValue = useMemo(() => {
        const value = passedSelectedItems
            ? Array.isArray(passedSelectedItems)
                ? [...passedSelectedItems]
                : [passedSelectedItems]
            : [];
        return value;
    }, [passedSelectedItems]);

    const [selectedItems, setSelectedItems] = useState(initialValue);
    const itemsToUse = useComponentState ? selectedItems : passedSelectedItems;

    const getItem = (option) => {
        if (typeof option === 'object' && option !== null) {
            return option[objProp];
        }
        return option;
    };

    const getDisplayLabel = (option) => getItem(option);
    const getDisplayValue = (options) => options?.map((option) => getItem(option)).join(', ');
    const isSelected = (option) => !!itemsToUse?.find((selectedItem) => getItem(selectedItem) === getItem(option));

    const handleSelect = (clickedItem) => {
        if (multiSelect) {
            let selectedItemsUpdated = [];
            if (!isSelected(clickedItem)) {
                selectedItemsUpdated = [...itemsToUse, clickedItem];
            } else {
                selectedItemsUpdated = itemsToUse.filter((item) => getItem(item) !== getItem(clickedItem));
            }
            useComponentState && setSelectedItems(selectedItemsUpdated);
            onChange?.(selectedItemsUpdated, getDisplayValue(itemsToUse));
            setIsOpen(true);
        } else {
            useComponentState && setSelectedItems([clickedItem]);
            onChange?.([clickedItem], getDisplayValue(itemsToUse));
            setIsOpen(false);
        }
    };

    const eventListener = (event) => {
        const target = event.target;
        const selectBox = ref.current;
        if (selectBox && selectBox !== target && !selectBox.contains(target)) {
            setIsOpen(false);
        }
    };

    document.addEventListener('click', eventListener);

    return (
        <div className={containerClass}>
            {label && <label className="font-medium text-[1.4rem] text-[#1F2937]">{label}</label>}
            <Listbox
                value={itemsToUse}
                onChange={(clickedItem) => {
                    const option = clickedItem;
                    handleSelect(option);
                }}
                as="div"
            >
                {() => (
                    <div className="relative mt-1" ref={ref}>
                        <Listbox.Button
                            onClick={!disabled ? () => setIsOpen(!isOpen) : null}
                            className={`relative w-full cursor-default ${
                                disabled && 'bg-[#D1D5DB]'
                            } ${borderRadius} h-[4rem] ${
                                noBorder ? '' : 'border'
                            } px-3 text-left focus:outline-none text-[1.4rem] font-normal`}
                        >
                            <div className="flex items-center">
                                {!itemsToUse?.length > 0 && (
                                    <div className="text-[1.4rem] font-normal text-[#6B7280]">{placeholder}</div>
                                )}

                                <div className="flex items-center flex-wrap">
                                    <span className="truncate">{getDisplayValue(itemsToUse)}</span>
                                </div>
                            </div>

                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <SelectIcon />
                            </div>
                        </Listbox.Button>

                        <Transition
                            show={isOpen}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 px-[8px] py-3 max-h-[130px] w-full text-[#1F2937] overflow-auto bg-white space-y-1 text-[1.6rem] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {searchable && (
                                    <div className="py-2 relative">
                                        <SearchInput
                                            value={searchValue}
                                            placeholder={searchPlaceholder}
                                            type="text"
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                        />
                                        <IconWrapper>
                                            <SearchIcon />
                                        </IconWrapper>
                                    </div>
                                )}
                                {!results?.length || isLoading ? (
                                    <p className="p-2">{isLoading ? 'Loading...' : 'No Options'}</p>
                                ) : (
                                    results?.map((option) => {
                                        const selected = isSelected(option);

                                        return (
                                            <Listbox.Option
                                                key={`${getDisplayLabel(option)}`}
                                                value={option}
                                                className={({ active }) =>
                                                    `${
                                                        selected || active ? 'bg-[#F3F4F6]' : ''
                                                    } flex items-center cursor-pointer select-none rounded-lg py-2 mb-2 pl-2 pr-9 capitalize`
                                                }
                                            >
                                                <>
                                                    {multiSelect ? (
                                                        <Checkbox
                                                            checked={selected}
                                                            className="mr-0"
                                                            aria-hidden="true"
                                                        />
                                                    ) : null}
                                                    <div className="flex items-center">
                                                        <span className={` text-[#1F2937] truncate`}>
                                                            {getDisplayLabel(option)}
                                                        </span>
                                                    </div>
                                                </>
                                            </Listbox.Option>
                                        );
                                    })
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                )}
            </Listbox>
            {error && <span className="text-[#EF4444] text-[1.2rem]">{error}</span>}
        </div>
    );
};

export default Select;