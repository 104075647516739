import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    title: Yup.string().nullable().required('Required'),
    attendanceType: Yup.string().nullable().required('Required'),
    continousAssessmentOption: Yup.string().nullable().required('Required'),
    date: Yup.string().nullable().required('Required'),
    startTime: Yup.string().nullable().required('Required'),
    endTime: Yup.string().nullable().required('Required'),
});
