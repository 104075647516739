import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate} from 'react-router-dom';


import preset from '../../../assets/icons/arrow-back-blue.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { forgetPassword } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';


const ForgotPasswordForm = ({ setOpenSuccess}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.auth);


    return (
        <>
            <Formik
                initialValues={{
                    staffEmail: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(forgetPassword(values));
                    if (res) {
                        actions.resetForm();
                        setOpenSuccess(true); // Navigate to success page
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <div>
                                <img src={preset} onClick={() => navigate('/sign-in')} alt="preset" className="mb-10" />
                                <div className="">
                                    <h3 className="font-semibold text-[#1F2937] text-[19px] mb-2">Forgot Password?</h3>

                                    <p className="font- text-[#9CA3AF] text-[12px] mb-10">
                                        Enter the email associated with your account
                                    </p>
                                </div>

                                <div className=" gap-6">
                                    <TextInput
                                        name="staffEmail"
                                        type="text"
                                        onChange={handleChange}
                                        placeholder="Email Address"
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <FlexRowSpaceBetween className="mt-5">
                                <Text lineHeight="2.4rem">
                                    <FlexCentredRow className="gap-2">
                                        <Text>Remember password?</Text>
                                        <Text
                                            color="#3B82F6"
                                            lineHeight="2.4rem"
                                            className="cursor-pointer"
                                        >
                                            <Link to="/sign-in">Sign In</Link>
                                        </Text>

                                    </FlexCentredRow>
                                </Text>

                                <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                    Send Link
                                </LoadingButton>
                            </FlexRowSpaceBetween>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ForgotPasswordForm;
