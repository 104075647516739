import React from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';

import BasicInfo from './tabs/BasicInfo';

const LecturerDetails = () => {
    const navigate = useNavigate();

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="mb-[3.2rem] hover:cursor-pointer">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Lecturers
                </PageTitle>
                <Text size="1.6rem" left="1.4rem">
                    / Olunkunle Emmanuel
                </Text>
            </FlexCentredRow>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>Basic Info</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <BasicInfo />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default LecturerDetails;
