import React, { useState, /*useEffect*/ } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
 import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown2.svg';
// import { ReactComponent as ExportIcon } from '../../../../assets/icons/export.svg';
// import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload4.svg';
// import { ReactComponent as UploadIcon2 } from '../../../../assets/icons/upload5.svg';
import { /*ActionsPopup,*/ ChooseColumnsToExport, ExportSheetDialogue, UploadFile } from '../../../../components';
import { /*Button,*/ SecondaryButton } from '../../../../components/buttons';
import { AllFilter, SingleFilter } from '../../../../components/filter-select';
import AddNewStudentForm from '../../../../components/forms/add-new-student';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
// import ListEmptyContent from '../../../../components/list-empty';
 import { /*PageTitle,*/ Text } from '../../../../containers/MesssageContainers';
import {
    //CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    FlexColumn
} from '../../../../containers/ScreenContainers';
import { courseDetailsData } from '../../../../data/courseDetails';
import { studentsData } from '../../../../data/students';
//import { Result } from 'postcss';

const fields = [
    // {
    //     key: 'action',
    //     label: <Checkbox />,
    // },
    {
        label: 'Course Title',
        key: 'courseTitle',
    },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Unit',
        key: 'unit',
    },
    {
        label: 'Percentage(%)',
        key: 'percentage',
    },
    {
        label: 'Grade',
        key: 'grade',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

// const RelativeContainer = tw.div`
//   relative
//   mr-12
// `;

const Results = () => {
    const navigate = useNavigate();
    //const [dropdownOpen, setdropdownOpen] = useState(false);
    const [isLoading /*setIsLoading*/] = useState(false);
    //const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(studentsData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);

    // useEffect(() => {
    //     const id = setTimeout(() => {
    //         setIsListEmpty(false);
    //     }, 1500);

    //     return () => clearTimeout(id);
    // }, []);

    return (
        <div>
            {/* <FlexRowSpaceBetween>
                <PageTitle>Students</PageTitle>
                <FlexCentredRow className="gap-x-4">
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setdropdownOpen(!dropdownOpen)}>
                            Actions
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={dropdownOpen}
                            items={[
                                {
                                    icon: <UploadIcon />,
                                    name: 'Upload Sheet',
                                    click: () => {
                                        setShowUploadFile(true);
                                        setdropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <ExportIcon />,
                                    name: 'Export Sheet',
                                    click: () => {
                                        setShowExportSheetDialogue(true);
                                        setdropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexCentredRow>
            </FlexRowSpaceBetween>

            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No Students yet" subtitle="Add new students">
                        <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                            <Button bgColor="#10B981" color="#fff" onClick={() => setShowForm(true)}>
                                <Circularplus className="mr-[1.15rem]" />
                                Create New
                            </Button>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => setShowUploadFile(true)}>
                                <UploadIcon2 className="mr-[1.15rem]" />
                                Upload Sheet
                            </Button>
                        </CenteredContainer>
                    </ListEmptyContent>
                </CenteredContainer>
            ) : ( */}
            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                        <SingleFilter name="Admission Year" />
                        <SingleFilter name="Level" withRadius />
                    </FlexCentredRow>

                    <FlexCentredRow>
                        <SecondaryButton>Reset</SecondaryButton>
                        <AllFilter />
                    </FlexCentredRow>
                </FilterContainer>

                <FlexCentredRow gap="1">
                    <SecondaryButton border="0.5px solid #D1D5DB" left="0.7rem">
                        1st Semester - 2019/2022 Session
                        <Dropdown />
                    </SecondaryButton>
                    {/* <econdaryButton border="0.5px solid #D1D5DB" left="0.7.4rem" >
                            Semester
                            <Dropdown />
                        </econdaryButton> */}
                    <FlexCentredRow className="ml-[7.4rem]">
                        <FlexColumn>
                            <Text>GPA</Text>
                            <Text size="2.4rem">2.75</Text>
                        </FlexColumn>
                        <FlexColumn className="ml-[3.4rem]">
                            <Text>CGPA</Text>
                            <Text size="2.4rem">3.78</Text>
                        </FlexColumn>
                    </FlexCentredRow>
                </FlexCentredRow>

                <CDataTable
                    items={courseDetailsData || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => navigate(`/students/${item.id}`)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => <td style={{ color: '#2563EB' }}>{item.name}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
            {/* )} */}

            <AddNewStudentForm show={showForm} setShow={setShowForm} />
            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default Results;
