import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    assignments: [],
    assignment: {},
    currentAssignment: {},
    courseAssignments: [],
};

export const assignmentsSlice = createSlice({
    name: 'assignments',
    initialState,
    reducers: {
        SAVE_ASSIGNMENTS: (state, action) => {
            state.assignments = action.payload;
        },
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        SAVE_CURRENT_ASSIGNMENT: (state, action) => {
            state.currentAssignment = action.payload;
        },
        GET_ASSIGNMENT: (state, action) => {
            state.assignment = action.payload;
        },
        GET_COURSE_ASSIGNMENTS: (state, action) => {
            state.courseAssignments = action.payload;
        },
    },
});

export const {
    SAVE_ASSIGNMENTS,
    LOADING,
    STOP_LOADING,
    GET_ASSIGNMENT,
    SAVE_CURRENT_ASSIGNMENT,
    GET_COURSE_ASSIGNMENTS,
} = assignmentsSlice.actions;

export default assignmentsSlice.reducer;
