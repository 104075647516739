import React, { useRef, useState } from 'react';

import tw from 'twin.macro';

import { ReactComponent as Upload } from '../../assets/icons/upload6.svg';
import { Text } from '../../containers/MesssageContainers';
import { Button } from '../buttons';

const Container = tw.div`
    flex
    flex-col
    bg-white
    w-full
`;

const InputBox = tw.div`
    flex
    flex-row
    flex-wrap
    border
    border-dashed
    border-[#D1D5DB]
    rounded-[8px]
    items-center
    p-[.5rem]
    gap-[.3rem]
`;

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const UploadFile3 = ({ name, handleChange, fileName, btnText, label, placeholder, accept = '.jpg,.jpeg,.png' }) => {
    // const inputRef = useRef([]);
    const inputRef = useRef(null);

    const [file, setFile] = useState(null);

    const handleClick = (key) => {
        // inputRef.current[key].click();
        inputRef.current.click();
    };

    return (
        <Container>
            {label && <Label htmlFor={name}>{label}</Label>}
            <InputBox>
                <Button
                    type="Button"
                    // onClick={() => handleClick(item.key)}
                    onClick={handleClick}
                    className="px-[1.6rem] py-[0.8rem] border border-[#E5E7EB] mr-2 shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                >
                    <Upload className="pr-[1.016rem] h-[30px] w-[30px]" />
                    {btnText || 'Upload Files'}
                </Button>
                {/* <Text color="#6B7280">{values[item.key] ? values[item.key].name : 'Select file'}</Text> */}
                <Text color="#6B7280">{file?.name || fileName || placeholder || 'Select file'}</Text>

                <input
                    type="file"
                    name={name}
                    accept={accept}
                    // ref={(el) => (inputRef.current[item.key] = el)}
                    ref={inputRef}
                    style={{ display: 'none' }}
                    // onChange={(e) => handleFileChange(e, setFieldValue, item.key, values)}
                    onChange={(e) => {
                        const files = e.target.files;
                        if (files && files[0]) {
                            setFile(files[0]);
                            handleChange(e);
                        }
                    }}
                />
            </InputBox>
            {/* <Text>Please upload a copy of your {item.subtitle}</Text>
            <Text>in PNG,JPEG format, no larger than 3mb in size</Text> */}
        </Container>
    );
};

export default UploadFile3;
