import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import { GET_STUDENTS, LOADING, STOP_LOADING } from './slice';

export const getStudents = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}student/all-students?${queries}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_STUDENTS(data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}student/student-info/${id}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentCourses = (id, session, semseter) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}student/registered-courses/${id}/${session}/${semseter}`,
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentResult = (id, session, semseter) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}student/student-result/${id}/${session}/${semseter}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
