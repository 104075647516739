import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allNotices: [],
    allMails: [],
    receivedNotice: [],
    receivedInternalMemo: [],
    internalMemo: {},
    notice: {},
    mail: {},
    sentNotice: [],
    sentMail: [],
    allReceived: [],
};

export const communicationsSlice = createSlice({
    name: 'communications',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_NOTICES: (state, action) => {
            state.allNotices = action.payload;
        },
        GET_ALL_MAILS: (state, action) => {
            state.allMails = action.payload;
        },
        GET_RECEIVED_NOTICE: (state, action) => {
            state.receivedNotice = action.payload;
        },
        GET_NOTICE: (state, action) => {
            state.notice = action.payload;
        },
        GET_MAIL: (state, action) => {
            state.mail = action.payload;
        },
        GET_SENT_NOTICE: (state, action) => {
            state.sentNotice = action.payload;
        },
        GET_SENT_MAIL: (state, action) => {
            state.sentMail = action.payload;
        },
        GET_RECEIVED_INTERNAL_MEMO: (state, action) => {
            state.receivedInternalMemo = action.payload;
        },
        GET_INTERNAL_MEMO: (state, action) => {
            state.internalMemo = action.payload;
        },
        GET_ALL_RECEIVED: (state, action) => {
            state.allReceived = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_ALL_MAILS,
    GET_ALL_NOTICES,
    GET_RECEIVED_NOTICE,
    GET_NOTICE,
    GET_MAIL,
    GET_SENT_NOTICE,
    GET_SENT_MAIL,
    GET_RECEIVED_INTERNAL_MEMO,
    GET_INTERNAL_MEMO,
    GET_ALL_RECEIVED,
} = communicationsSlice.actions;

export default communicationsSlice.reducer;
