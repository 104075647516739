import { SelectInput, TextInput } from '@belrald_hq/belrald-ui';
import tw from 'twin.macro';

import DatePicker from '../../../components/inputs/date-picker';
import UploadFile3 from '../../../components/upload-file3';

const GridContainer = tw.div`
    grid
    md:grid-cols-2
    justify-between
    items-center
    gap-[2rem]
    md:gap-[2rem]
    w-[100%]
`;

const PUBLISHING_TYPES = [
    'Journal',
    'Book',
    'Dissertation',
    'Encyclopedia',
    'Handbook',
    'Research Report',
    'Conference Proceedings',
    'Official publications',
];

const Publication = ({
    publisher,
    publisherTitle,
    author,
    year,
    publisherTitleErrors,
    authorErrors,
    publisherErrors,
    handleChange,
    publisherValue,
    publisherTitleValue,
    authorValue,
    touched,
    publisherType,
    publisherTypeValue,
    publisherTypeErrors,
    handleSelect,
    file,
    setFieldValue,
    publicationLink,
    publicationLinkValue,
    publication,
    yearValue,
}) => {
    return (
        <div className="grid gap-[2rem]">
            <GridContainer>
                <TextInput
                    className="h-40px"
                    name={publisher}
                    type="text"
                    label="Publisher"
                    placeholder="Publisher"
                    onChange={handleChange}
                    errors={publisherErrors}
                    value={publisherValue}
                />
                <SelectInput
                    name={publisherType}
                    objProp="name"
                    data={PUBLISHING_TYPES.map((itm) => ({ name: itm }))}
                    onChange={(selected) => setFieldValue(publisherType, selected[0].name)}
                    label="Publication Type"
                    placeholder="Publication Type"
                    error={publisherTypeErrors}
                    passedSelectedItems={publisherTypeValue ? [{ name: publisherTypeValue }] : null}
                />
                <TextInput
                    className="h-40px"
                    name={publisherTitle}
                    type="text"
                    label="Publication Title"
                    placeholder="Publication Title"
                    onChange={handleChange}
                    errors={publisherTitleErrors}
                    value={publisherTitleValue}
                />
                <TextInput
                    className="h-40px"
                    name={author}
                    type="text"
                    label="Author(s)"
                    placeholder="Author(s)"
                    onChange={handleChange}
                    errors={authorErrors}
                    value={authorValue}
                />
                <UploadFile3
                    label="Upload Publication"
                    btnText="Upload Publication"
                    placeholder="Click here to select files"
                    handleChange={({ target: { files } }) => {
                        if (files && files[0]) {
                            setFieldValue(publication, files[0]);
                        }
                    }}
                    fileName={file?.name || file?.fileName}
                    accept={'.pdf'}
                />

                <DatePicker
                    useYear
                    label="Year"
                    name={year}
                    btnText="Year"
                    placeholder="Select Year"
                    touched={touched}
                    initialValue={yearValue ? new Date(yearValue) : null}
                />
                <TextInput
                    name={publicationLink}
                    type="text"
                    label="Link to Publication"
                    placeholder="Link to Publication"
                    onChange={handleChange}
                    // errors={authorErrors}
                    value={publicationLinkValue}
                />
            </GridContainer>
        </div>
    );
};

export default Publication;
