import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as EmptyState } from '../../../assets/icons/empty-state.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import ListEmptyContent from '../../../components/list-empty';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexRowSpaceBetween,
    FlexRowCentered,
    CenteredContainer,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { getAcademicCalendar } from '../../../redux/academic-calendar/actions';
import { getAllottedSessions } from '../../../redux/alloted-courses/actions';

import { semesters } from './utils';

const MainContainer = tw.div`
    relative
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    mt-[2.4rem]
    rounded-lg
`;

const CalendarDetails = tw.div`
   pb-[5rem]
`;

const AcadCalender = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.userData);
    const { activeSemester } = useSelector((store) => store.admission);
    const { allottedSessions = [] } = useSelector((state) => state.allotedCourses);

    const [academicCalender, setAcademicCalender] = useState();
    const [openDropdown, setOpenDropdown] = useState(null);
    const [session, setSession] = useState(allottedSessions[0]?.session || '');
    const [semester, setSemester] = useState(activeSemester || 'First Semester');

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = allottedSessions.map((item) => ({
        name: item.session,
        click: () => {
            setSession(item.session);
            setOpenDropdown(null);
        },
    }));

    const getActivities = async () => {
        const res = await dispatch(getAcademicCalendar(session, semester.toLowerCase()));
        if (res) setAcademicCalender(res);
    };

    useEffect(() => {
        dispatch(getAllottedSessions());
    }, [dispatch]);

    useEffect(() => {
        if (session && semester) {
            getActivities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, session, semester]);

    return (
        <MainContainer>
            <FlexRowSpaceBetween className="mb-[5rem]">
                <FlexRowCentered className="gap-[.5rem]">
                    <RelativeContainer>
                        <Button
                            color="#6B7280"
                            border="1px solid #9CA3AF"
                            onClick={() => handleOpenDropdown('sessions')}
                        >
                            {session || 'Session'}
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'sessions'}
                            close={() => setOpenDropdown(null)}
                            items={actionItemSessions}
                        />
                    </RelativeContainer>

                    <RelativeContainer>
                        <Button
                            color="#6B7280"
                            border="1px solid #9CA3AF"
                            onClick={() => handleOpenDropdown('semester')}
                        >
                            {`${semester}` || 'Semester'}
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'semester'}
                            close={() => setOpenDropdown(null)}
                            items={semesters?.map((item) => ({
                                name: item,
                                click: () => {
                                    setSemester(item);
                                    setOpenDropdown(null);
                                },
                            }))}
                        />
                    </RelativeContainer>
                </FlexRowCentered>
            </FlexRowSpaceBetween>

            {academicCalender?.length <= 0 ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent
                        title="No Academic Calender has been created"
                        subtitle="No academic calender set"
                        source={EmptyState}
                    ></ListEmptyContent>
                </CenteredContainer>
            ) : (
                <>
                    <CalendarDetails>
                        <>
                            <PageTitle as="h2" size="2.8rem" align="left" bottom="5rem" top="6rem">
                                {user?.institutionName}
                            </PageTitle>
                            <Text align="left" lineHeight="2.4rem" weight="600" bottom="2.4rem">
                                Academic Calendar for {session} Session
                            </Text>
                            <Text align="left" lineHeight="2.4rem" weight="600" bottom="3.2rem" transform="uppercase">
                                {academicCalender?.[0]?.studentType}
                            </Text>
                            <Text align="left" lineHeight="2.4rem" weight="600" bottom="3.2rem" transform="capitalize">
                                {semester} Semester
                            </Text>
                        </>
                        {academicCalender?.map((item) => (
                            <ol type="a">
                                {item?.activities?.map((activity) => (
                                    <li>
                                        <FlexRowSpaceBetween key={activity._id}>
                                            <Text align="left" lineHeight="2.4rem" weight="600" color="#000000">
                                                {activity.activity}
                                            </Text>
                                            <FlexCentredRow className="gap-[0.5rem]">
                                                <Text color="#000000" align="right" lineHeight="2.4rem" weight="500">
                                                    {format(new Date(activity.from), 'cccc PP')} -
                                                </Text>
                                                <Text color="#000000" align="right" lineHeight="2.4rem" weight="500">
                                                    {format(new Date(activity.to), 'cccc PP')}
                                                </Text>
                                            </FlexCentredRow>
                                        </FlexRowSpaceBetween>
                                    </li>
                                ))}
                            </ol>
                        ))}
                    </CalendarDetails>
                </>
            )}
        </MainContainer>
    );
};

export default AcadCalender;
