import React, { useState } from 'react';

import { Stepper, Step } from 'react-form-stepper';
import { useLocation } from 'react-router-dom';

import BioData from './bio-data';
import Experience from './experience';
import PublicationForm from './publication';
import UploadsForm from './uploads';

const StaffProfile = () => {
    const { state: urlState } = useLocation();

    const [steps, setSteps] = useState(urlState?.tab || 0);

    return (
        <div>
            <Stepper
                activeStep={steps}
                styleConfig={{
                    activeBgColor: '#6366F1',
                    activeTextColor: '#fff',
                    inactiveBgColor: '#fff',
                    inactiveTextColor: '#CCC',
                    completedBgColor: '#fff',
                    completedTextColor: '#9CA3AF',
                    labelFontSize: '1.6rem',
                    circleFontSize: '1.6rem',
                    fontWeight: '500',
                    size: '2rem',
                }}
            >
                <Step onClick={() => setSteps(0)} label="Biodata" />
                <Step onClick={() => setSteps(1)} label="Experience" />
                <Step onClick={() => setSteps(2)} label="Publication" />
                <Step onClick={() => setSteps(3)} label="Uploads" />
            </Stepper>
            {steps === 0 && (
                <div>
                    <BioData handleStep={() => setSteps(1)} />
                </div>
            )}
            {steps === 1 && (
                <div>
                    <Experience handleStep={() => setSteps(2)} />
                </div>
            )}
            {steps === 2 && (
                <div>
                    <PublicationForm handleStep={() => setSteps(3)} />
                </div>
            )}
            {steps === 3 && (
                <div>
                    <UploadsForm handleStep={() => setSteps(0)} />
                </div>
            )}
        </div>
    );
};

export default StaffProfile;
