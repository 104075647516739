import React, { useEffect, useState } from 'react';

import { CDataTable } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import ModalSelect from '../../../../components/modal-select';
import { getStatusStyles, StatusContainer } from '../../../../components/shared';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { getStudentCourses } from '../../../../redux/students/actions';

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mt-[1.6rem]
    flex
    gap-[10px]
`;

const fields = [
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Title',
        key: 'courseTitle',
    },
    {
        label: 'Unit',
        key: 'courseUnit',
    },
    {
        label: 'Status',
        key: 'courseClass',
    },
];

const RegisteredCourses = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { activeSession, activeSemester, sessions } = useSelector((store) => store.admission);
    const { isLoading } = useSelector((state) => state.students);

    const [semester, setSemester] = useState(activeSemester || 'First Semester' || '');
    const [session, setSession] = useState(activeSession || '');
    const [openModal, setOpenModal] = useState('');
    const [coursesData, setCoursesData] = useState(null);

    const { data: courses, numberOfCourses, totalUnits } = coursesData || {};

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenModal(null);
        },
    }));

    const actionItemSemester = ['First Semester', 'Second Semester'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenModal(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openModal === dropdown) {
            setOpenModal(null);
        } else {
            setOpenModal(dropdown);
        }
    };

    useEffect(() => {
        if (id && session && semester) {
            const semesterParam = semester === 'Second Semester' ? 'second' : 'first';

            const getCoursesData = async () => {
                const res = await dispatch(getStudentCourses(id, session, semesterParam));
                if (res) {
                    return setCoursesData(res);
                }
                return setCoursesData({});
            };

            getCoursesData();
        }
    }, [dispatch, id, session, semester]);

    return (
        <div>
            <Details>
                <ModalSelect
                    placeholder="Session"
                    value={session}
                    onClick={() => handleOpenDropdown('sessions')}
                    open={openModal === 'sessions'}
                    close={() => setOpenModal(null)}
                    items={actionItemSessions}
                />
                <ModalSelect
                    placeholder="Semester"
                    value={semester ? `${semester}` : ''}
                    onClick={() => handleOpenDropdown('semester')}
                    open={openModal === 'semester'}
                    close={() => setOpenModal(null)}
                    items={actionItemSemester}
                />
            </Details>

            <div className="shadow-lg rounded-t-lg bg-[white] px-6 mt-[0.2rem]">
                <CDataTable
                    items={courses || []}
                    fields={fields}
                    selectable
                    responsive
                    loading={isLoading}
                    sorter
                    scopedSlots={{
                        courseClass: (item) => (
                            <td>
                                <StatusContainer style={getStatusStyles[item?.courseClass?.toLowerCase()]}>
                                    <span>{item?.courseClass}</span>
                                </StatusContainer>
                            </td>
                        ),
                    }}
                />
                <FlexRowSpaceBetween className="px-[1rem]">
                    <Text color="#9CA3AF" size="1.6rem">
                        Total Courses:
                        <Text as="span" weight="500" left="2rem">
                            {numberOfCourses}/12
                        </Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Total Units:
                        <Text as="span" weight="500" left="2rem">
                            {totalUnits}/24
                        </Text>
                    </Text>
                </FlexRowSpaceBetween>
            </div>
        </div>
    );
};

export default RegisteredCourses;
