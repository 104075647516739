import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const attendanceSlice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const { LOADING, STOP_LOADING } = attendanceSlice.actions;

export default attendanceSlice.reducer;
