import React, { useEffect, useMemo, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

// import { AllFilter } from '../../../components/filter-select';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import TextInput from '../../../components/inputs/text-input';
import ItemsPerPage from '../../../components/items-per-page';
import { Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    // RelativeContainer,
} from '../../../containers/ScreenContainers';
import { getAssignmentSpreadsheet, allotMark, getAssignmentVersions } from '../../../redux/assignment/actions';
import { capitalizeFirstLetter, getDegreeInfo } from '../../../utils';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const overviewItems = [
    {
        title: 'Assignment Type',
        key: 'assignmentCategory',
    },
    {
        title: 'Assignment Title',
        key: 'title',
    },
    {
        title: 'Continuous Assessment',
        key: 'continousAssessmentOption',
    },
    {
        title: 'Total Marks',
        key: 'totalMarks',
    },
    {
        title: 'Description',
        key: 'description',
    },
];

const AssignmentSpreadsheet = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { session, course, assignment } = state || {};

    const { _id: courseId, courseCode, courseTitle } = course || {};

    const { isLoading } = useSelector((store) => store.assignment);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [spreadSheet, setSpreadSheet] = useState([]);
    const [versions, setVersions] = useState([]);
    const latestVersion = versions[versions?.length - 1];
    const totalPages = Math.ceil(spreadSheet?.length / itemsPerPage);

    const [inputValues, setInputValues] = useState({});
    const [prevValue, setPrevValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const handleChange = (studentId, value) => {
        setInputValues((prevState) => ({
            ...prevState,
            [studentId]: value,
        }));
    };

    const handleRecord = async (item = {}) => {
        const { studentId } = item;
        const mark = inputValues?.[studentId];

        if (mark !== prevValue) {
            const payload = { mark: Number(mark) };

            const res = await dispatch(allotMark(studentId, assignment._id, payload, latestVersion?.version));
            if (res) {
                setToggleRefetch((prevState) => !prevState);
                setInputValues({});
            }
        }
    };

    const fields = [
        {
            label: <Checkbox />,
            key: 'action',
        },
        {
            label: 'Full Name',
            key: 'studentName',
        },
        {
            label: 'Matric No.',
            key: 'matricNumber',
        },
        {
            label: firstDegreeOptionsStr,
            key: 'firstDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: secondDegreeOptionsStr,
            key: 'secondDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: `Input ${assignment?.title} Score`,
            key: 'score',
        },
    ];

    const modifiedSpreadSheet = useMemo(() => {
        return spreadSheet?.map((stud) => {
            const studStructures = getDegreeInfo(stud);
            const { firstDegree, secondDegree, thirdDegree } = studStructures || {};

            return {
                firstDegree,
                secondDegree,
                thirdDegree,
                ...stud,
            };
        });
    }, [spreadSheet]);

    useEffect(() => {
        if (!session) return;
        const fetchVersions = async () => {
            const versionData = await dispatch(getAssignmentVersions(session, courseId));
            setVersions(versionData || []);
        };

        fetchVersions();
    }, [courseId, dispatch, session]);

    useEffect(() => {
        if (!session) return;

        const fetchSpreadsheet = async () => {
            const res = await dispatch(getAssignmentSpreadsheet(session, course?._id, assignment?._id));
            if (res) {
                setSpreadSheet(res);
                return;
            }
            setSpreadSheet([]);
        };
        fetchSpreadsheet();
    }, [course?._id, dispatch, session, assignment?._id, toggleRefetch]);

    return (
        <>
            <FlexRowSpaceBetween className="gap-[1rem">
                <GoBack title={`Assignments / ${courseCode} (${courseTitle})`} subTitle="/ Spreadsheet" />
            </FlexRowSpaceBetween>

            <div className="bg-white w-full p-[24px] mt-12 rounded-lg">
                <Text weight="600" size="1.9rem" align="left">
                    Overview
                </Text>

                <div className="grid grid-cols-4 gap-y-8 mt-8">
                    {overviewItems.map((item) => (
                        <FlexColumn className="gap-1">
                            <Text weight="500" align="left" size="1.2rem" color="#9CA3AF">
                                {item.title}
                            </Text>
                            <Text weight="500" align="left" size="1.4rem" color="#4B5563">
                                {item.key === 'continousAssessmentOption'
                                    ? assignment?.[item.key]
                                        ? 'Yes'
                                        : 'No'
                                    : assignment?.[item.key]}
                            </Text>
                        </FlexColumn>
                    ))}
                </div>
            </div>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>

                    {/* <RelativeContainer> */}
                    {/* <AllFilter
                        // showClear={currentFilterCategory}
                        // items={FILTER_OPTIONS.map((item) => ({
                        //     name: item,
                        //     click: () => {
                        //         handleSelectCategories(item);
                        //         setCurrentFilterCategory(item);
                        //         setOpenModal('options-popup-main');
                        //     },
                        // }))}
                        // handleReset={handleReset}
                        /> */}
                    {/* <FilterPopup
                            open={openModal === 'options-popup-main'}
                            isLoading={facultyLoading || collegeLoading}
                            close={() => setOpenModal(null)}
                            items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                        /> */}
                    {/* </RelativeContainer> */}
                </FilterContainer>

                <CDataTable
                    items={modifiedSpreadSheet || []}
                    fields={fields}
                    // // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    // clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => <td style={{ color: '#2563EB' }}>{capitalizeFirstLetter(item.name)}</td>,
                        score: (item) => (
                            <td>
                                <TextInput
                                    type="number"
                                    placeholder="Input Score"
                                    name="name"
                                    width="90px"
                                    value={
                                        inputValues?.[item.studentId] !== undefined
                                            ? inputValues?.[item.studentId]
                                            : item.score
                                    }
                                    onChange={(e) => handleChange(item.studentId, e.target.value || '')}
                                    onBlur={() => handleRecord(item)}
                                    onClick={() => setPrevValue(item.score)}
                                />
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </>
    );
};

export default AssignmentSpreadsheet;
